<template>
  <div class="card">
    <div class="card-content"><span v-html="content" /></div>
  </div>
</template>

<script>
import { Component, Vue, Watch } from 'vue-property-decorator';
import { apiStore, generalStore } from '@/store/store-accessor';
import { GetPrivacyPolicy } from '@/api/requests';

@Component({ name: 'Privacy' })
export default class Privacy extends Vue {
  url = {
    en: '/static/privacy_policy/en.html',
    nl: '/static/privacy_policy/nl.html'
  };
  content = '';

  get language() {
    return generalStore.language;
  }

  get localUrl() {
    return this.url[this.language] ? this.url[this.language] : this.url.en;
  }

  get client() {
    return apiStore.client;
  }
  @Watch('language')
  getPolicy() {
    this.client.request(new GetPrivacyPolicy({ language: this.language })).then(result => {
      this.content = result ?? '';
    });
  }

  mounted() {
    this.getPolicy();
  }
}
</script>

<style scoped></style>
