import { render, staticRenderFns } from "./ProjectInfoBox.vue?vue&type=template&id=ccbe96a6&scoped=true&"
import script from "./ProjectInfoBox.vue?vue&type=script&lang=ts&"
export * from "./ProjectInfoBox.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ccbe96a6",
  null
  
)

export default component.exports