









































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Scenario, UUID } from '../types';
import FlowContainer from './FlowContainer.vue';
import { flowStore, flowUIStore, flowVisualizationStore } from '../store/store-accessor';
import { buildFlowUrl, sortByKeys, getClassFromStatus } from '../utils';
import ProjectInfoBox from './info_box/ProjectInfoBox.vue';
import ScenarioInfoBox from './info_box/ScenarioInfoBox.vue';
import { MoviciError } from '@movici-flow-common/errors';

@Component({
  name: 'FlowScenario',
  components: {
    FlowContainer,
    ProjectInfoBox,
    ScenarioInfoBox
  }
})
export default class FlowScenario extends Vue {
  @Prop({ type: String }) readonly currentProjectName?: string;
  @Prop({ type: String }) readonly currentScenarioName?: string;
  initialRawData!: string;

  get hasProjectsCapabilities() {
    return flowStore.hasProjectsCapabilities;
  }

  get currentProject() {
    return flowStore.project;
  }

  get scenarios() {
    return flowStore.scenarios;
  }

  get currentScenarioUUID() {
    return flowStore.scenario?.uuid;
  }

  get currentScenario(): Scenario | null {
    return flowStore.scenario;
  }

  get formattedRawData() {
    return JSON.stringify(this.currentScenario, null, 2);
  }

  get scenarioModels() {
    return this.currentScenario?.models?.sort(sortByKeys(['+name'])) ?? [];
  }

  get scenarioDatasets() {
    return this.currentScenario?.datasets?.sort(sortByKeys(['+name'])) ?? [];
  }

  get isDisabled() {
    return true;
  }
  /**
   * Watcher for current scenario, receives a scenario object, sets it in the store,
   * requests a detailed version of it and updates main Flow component.
   * If necessary, updates the route with its name as the scenario query parameter
   */
  async setScenarioUUID(currentScenarioUUID: UUID) {
    flowUIStore.setLoading({ value: true, msg: 'Loading scenario details...' });
    const fullScenario = await flowStore.getScenario(currentScenarioUUID);

    if (fullScenario) {
      await flowStore.setCurrentFlowScenario(fullScenario);
      await flowVisualizationStore?.getViewsByScenario(fullScenario.uuid);
    }

    // this replaces the query string with project
    if (this.currentScenarioName !== this.currentScenario?.name) {
      await this.$router.push(
        buildFlowUrl('FlowScenario', {
          project: this.currentProject?.name,
          scenario: this.currentScenario?.name
        })
      );
      flowVisualizationStore.updateCurrentView(null);
    }

    flowUIStore.setLoading({ value: false });
  }

  getClassFromStatus = getClassFromStatus;

  /**
   * Checks whether there are props for project and scenario.
   * If there is a project, we set in the component, which triggers the watcher
   * Else, redirect to beggining of Flow.
   *
   * If there's also a prop for scenario, set that scenario in the component
   */
  async mounted() {
    flowUIStore.setLoading({ value: true, msg: 'Loading scenarios...' });

    try {
      await flowStore.setupFlowStore({
        config: {
          currentProjectName: this.currentProjectName,
          needProject: true,
          currentScenarioName: this.currentScenarioName,
          needScenario: false
        }
      });
      flowUIStore.setLoading({ value: false });
    } catch (error) {
      flowUIStore.setLoading({ value: false });
      if (error instanceof MoviciError) {
        await error.handleError({
          $t: this.$t.bind(this),
          $router: this.$router,
          query: {
            project: this.currentProjectName,
            scenario: this.currentScenarioName
          }
        });
      }
    }
  }
}
