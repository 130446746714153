







































































































import { Component, Mixins } from 'vue-property-decorator';
import { Organisation } from '@/types';
import { generalStore, organisationStore } from '@/store/store-accessor';
import ResourceListConsumer from '@/components/common/ResourceListConsumer';
import ResourceList from '@/components/common/ResourceList.vue';

@Component({
  name: 'Organisations',
  components: {
    ResourceList
  }
})
export default class Organisations extends Mixins<ResourceListConsumer<Organisation>>(
  ResourceListConsumer
) {
  editPage = 'OrganisationEdit';
  resourceTerm = 'organisations';
  defaultSort = 'name';
  filterKeys = ['name'];
  uuidKey = 'organisation_uuid';

  async getAll() {
    return await organisationStore.getOrganisations();
  }

  getDeleteItemRequests(itemList: Organisation[]) {
    return itemList.map(({ organisation_uuid }) =>
      organisationStore.deleteOrganisation({ organisation_uuid })
    );
  }

  setLoading(payload: { value: boolean; msg?: string }) {
    generalStore.setLoading(payload);
  }
}
