















































































import EditModal from '@/components/common/EditModal.vue';
import ResourceList from '@/components/common/ResourceList.vue';
import ResourceListConsumer from '@/components/common/ResourceListConsumer';
import { datasetSchemaStore, generalStore } from '@/store/store-accessor';
import EditModalConsumer from '@/components/common/EditModalConsumer';
import { Field, PropertyType } from '@movici-flow-common/types';
import { Component, Mixins } from 'vue-property-decorator';

@Component({
  name: 'PropertyTypes',
  components: { EditModal, ResourceList }
})
export default class PropertyTypes extends Mixins<
  ResourceListConsumer<PropertyType>,
  EditModalConsumer<PropertyType>
>(ResourceListConsumer, EditModalConsumer) {
  resourceTerm = 'property type';
  defaultSort = 'name';
  filterKeys = ['name'];
  fields: Field<PropertyType>[] = [
    {
      name: this.$t('properties.name') as string,
      boundVariable: 'name',
      type: 'text',
      required: true
    },
    {
      name: this.$t('properties.data_type') as string,
      boundVariable: 'data_type',
      type: 'text',
      required: true
    },
    {
      name: this.$t('properties.description') as string,
      boundVariable: 'description',
      type: 'text',
      required: false
    },
    {
      name: this.$t('properties.unit') as string,
      boundVariable: 'unit',
      type: 'text',
      required: false
    },
    {
      name: this.$t('properties.enum') as string,
      boundVariable: 'enum_name',
      type: 'text',
      required: false
    }
  ];

  get propertyTypes() {
    return datasetSchemaStore.propertyTypes;
  }

  async getAll() {
    return (await datasetSchemaStore.getPropertyTypes()) ?? [];
  }

  getEmptyItem(): PropertyType {
    return {
      name: '',
      component: null,
      data_type: ''
    };
  }

  async addItem(item: PropertyType) {
    if (await datasetSchemaStore.addPropertyType(item)) {
      await this.resetList();
      return true;
    }
    return false;
  }

  async updateItem(item: PropertyType) {
    if (await datasetSchemaStore.updatePropertyType(item as Required<PropertyType>)) {
      await this.resetList();
      return true;
    }
    return false;
  }

  getDeleteItemRequests(itemList: PropertyType[]) {
    return itemList
      .filter(element => element.uuid)
      .map(element => datasetSchemaStore.deletePropertyType(element as Required<PropertyType>));
  }
  setLoading(payload: { value: boolean; msg?: string }) {
    generalStore.setLoading(payload);
  }
}
