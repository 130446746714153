














































































import { Component, Mixins, Watch } from 'vue-property-decorator';
import { Project, ShortScenario, MovActionType } from '@movici-flow-common/types';
import cloneDeep from 'lodash/cloneDeep';
import { generalStore, projectStore, scenarioStore, viewStore } from '@/store/store-accessor';
import ResourceListConsumer from '@/components/common/ResourceListConsumer';
import ResourceList from '@/components/common/ResourceList.vue';
import { getClassFromStatus } from '@movici-flow-common/utils';
import DialogModal from '@/components/global-alt/DialogModal.vue';

@Component({
  name: 'Scenarios',
  components: {
    ResourceList
  }
})
export default class Scenarios extends Mixins<ResourceListConsumer<ShortScenario>>(
  ResourceListConsumer
) {
  editPage = 'ScenarioEdit';
  resourceTerm = 'scenario';
  defaultSort = 'display_name';
  filterKeys = ['display_name', 'status'];
  checkedRowActions = [
    MovActionType.PLAY,
    MovActionType.RESET,
    MovActionType.LOGS,
    MovActionType.EDIT,
    MovActionType.DELETE
  ];
  singleRowActions = [
    MovActionType.PLAY,
    MovActionType.RESET,
    MovActionType.LOGS,
    MovActionType.DUPLICATE,
    MovActionType.EDIT,
    MovActionType.DELETE
  ];

  getClassFromStatus = getClassFromStatus;

  get activeProject(): Project | null {
    return projectStore.activeProject;
  }

  async getAll() {
    return this.activeProject
      ? await scenarioStore.getScenariosWithSimulationInfo(this.activeProject.uuid)
      : [];
  }

  async onDuplicate(scenario: ShortScenario) {
    if (this.activeProject && scenario.uuid) {
      const scenarios = this.items as ShortScenario[],
        clonedScenario = cloneDeep(await scenarioStore.getScenario(scenario.uuid));

      let i = 0,
        name = '',
        display_name = '';

      do {
        i++;
        name = `${clonedScenario.name}_${i}`;
        display_name = `${clonedScenario.display_name} (${i})`;
      } while ((scenarios ?? []).filter(v => v.name === name).length);

      clonedScenario.name = name;
      clonedScenario.display_name = display_name;
      clonedScenario.version = 4;

      await scenarioStore.addScenario({
        projectUUID: this.activeProject?.uuid,
        scenario: clonedScenario
      });

      await this.resetList();
      this.$flow.snackbar.successMessage('Views successfully duplicated');
    }
  }

  onRunScenario(item: ShortScenario) {
    return scenarioStore
      .runScenario(item)
      .then(() => {
        this.$flow.snackbar.successMessage(`Simulation "${item.display_name}" started`);
      })
      .catch(() => {});
  }

  async runMultiple(itemList: ShortScenario[]) {
    let resetRequests: Promise<void>[] = [];
    itemList.forEach(element => {
      resetRequests.push(this.onRunScenario(element));
    });

    await Promise.all(resetRequests);
    await this.resetList();
  }

  confirmReset(resetItems: ShortScenario[]) {
    const resetSingle = {
        message: `Are you sure you want to <b>reset</b> "${resetItems[0].display_name}"?`,
        title: `Reset ${this.resourceTerm}`
      },
      reset = {
        message: `Are you sure you want to <b>reset</b> these ${this.resourceTerm}s?`,
        title: `Reset ${this.resourceTerm}(s)`
      },
      message = resetItems.length > 1 ? reset : resetSingle;
    this.$oruga.modal.open({
      parent: this,
      component: DialogModal,
      props: {
        canCancel: true,
        confirmText: 'Reset',
        variant: 'warning',
        hasIcon: true,
        ...message,
        onConfirm: () => {
          let promise: Promise<void>;
          switch (resetItems.length) {
            case 0:
              return;
            case 1:
              promise = this.reset([resetItems[0]]).then(() => {
                this.$flow.snackbar.successMessage(
                  `Scenario "${resetItems[0].display_name}" successfully reset`
                );
              });
              break;
            default:
              promise = this.reset(resetItems).then(() => {
                this.$flow.snackbar.successMessage('Scenarios successfully reset');
              });
              break;
          }
          promise.then(() => this.resetList());
        }
      },
      trapFocus: true
    });
  }

  async reset(itemList: ShortScenario[]) {
    let resetRequests: Promise<unknown>[] = [];
    itemList.forEach(item => {
      resetRequests.push(scenarioStore.clearScenario(item));
    });

    return await Promise.all(resetRequests);
  }

  async getDeleteMessage(deleteItems: ShortScenario[]) {
    const views = await viewStore.getViews(deleteItems[0].uuid),
      term = 'scenario',
      deleteOneMessage = views?.length
        ? `Are you sure you want to <b>delete</b> "${deleteItems[0].display_name}", its simulation results and <b>${views.length} associated views</b>? <br>This action cannot be undone.`
        : `Are you sure you want to <b>delete</b> "${deleteItems[0].display_name}" and its simulation results? This action cannot be undone.`,
      deleteMultipleMessage = `Are you sure you want to <b>delete</b> ${deleteItems.length} ${term}s and all of their simulation results and associated views? This action cannot be undone.`,
      deleteTitle = `Delete ${term}(s)`;

    return {
      message: deleteItems.length > 1 ? deleteMultipleMessage : deleteOneMessage,
      title: deleteTitle
    };
  }

  getDeleteItemRequests(itemList: ShortScenario[]) {
    return itemList.map(item => {
      return scenarioStore.deleteScenario(item);
    });
  }

  checkedRowEnabledActions(scenarios: ShortScenario[]) {
    if (!scenarios.length) {
      return [];
    }
    let commonActions = [];
    let candidates = this.checkedRowEnabledActionsRow(scenarios[0]);

    for (let i = candidates.length - 1; i >= 0; i--) {
      for (var j = scenarios.length - 1; j > 0; j--) {
        let actions = this.checkedRowEnabledActionsRow(scenarios[j]);

        if (actions.indexOf(candidates[i]) === -1) {
          break;
        }
      }

      if (j === 0) {
        commonActions.push(candidates[i]);
      }
    }
    if (scenarios.length > 1) {
      let editIndex = commonActions.indexOf(MovActionType.EDIT);
      if (editIndex > -1) {
        commonActions.splice(editIndex, 1);
      }
    }
    return commonActions;
  }

  checkedRowEnabledActionsRow(scenario: ShortScenario) {
    if (!scenario.status) {
      return [];
    }

    const alwaysAvailableActions = [
        MovActionType.EDIT,
        MovActionType.DUPLICATE,
        MovActionType.DELETE
      ],
      statusActions: Record<string, MovActionType[]> = {
        Unknown: [],
        Invalid: [],
        Canceled: [],
        Ready: [MovActionType.PLAY],
        Pending: [MovActionType.RESET],
        Running: [MovActionType.RESET],
        Succeeded: [MovActionType.RESET],
        Failed: [MovActionType.RESET]
      },
      status = scenario.status as keyof typeof statusActions,
      actions = statusActions[status];

    Array.prototype.push.apply(actions, alwaysAvailableActions);

    if (scenario.has_simulation) {
      actions.push(MovActionType.LOGS);
    }

    return actions;
  }

  downloadLogs(scenarios: ShortScenario[]) {
    const downloadRequests = scenarios.map(scenario => {
      return scenarioStore.downloadLogs(scenario).catch(err => console.error(err));
    });

    Promise.all(downloadRequests).then(() => {
      this.checkedRows = [];
    });
  }

  @Watch('activeProject')
  afterActiveProject() {
    if (this.activeProject) {
      this.resetList();
    }
  }

  setLoading(payload: { value: boolean; msg?: string }) {
    generalStore.setLoading(payload);
  }
}
