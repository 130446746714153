




































import { Component, Vue, Prop } from 'vue-property-decorator';
import { flowStore } from '@movici-flow-common/store/store-accessor';
import { getClassFromStatus } from '@movici-flow-common/utils';

@Component({ name: 'ScenarioInfoBox' })
export default class ScenarioInfoBox extends Vue {
  @Prop({ type: Boolean, default: false }) readonly edit!: boolean;

  get currentScenarioUUID() {
    return this.currentScenario ? this.currentScenario.uuid : null;
  }

  get currentScenario() {
    return flowStore.scenario;
  }

  get scenarios() {
    return flowStore.scenarios;
  }

  get to() {
    return {
      name: 'FlowScenario',
      query: { scenario: this.currentScenario?.name, project: this.currentScenario?.project_name }
    };
  }

  getClassFromStatus = getClassFromStatus;
}
