



















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ComponentProperty, FlowVisualizerType } from '@movici-flow-common/types';
import { isLines, isPoints, isPolygons, isGrid } from '@movici-flow-common/visualizers/geometry';

function validFlowVisualizerType(val: unknown): val is FlowVisualizerType {
  if (typeof val !== 'string') return false;
  return Object.values(FlowVisualizerType).indexOf(val as FlowVisualizerType) !== -1;
}

function validFlowVisualizerTypeOrNull(val: unknown): val is FlowVisualizerType | null {
  if (val === null) return true;
  return validFlowVisualizerType(val);
}

type GeometryChoicesProps = {
  enabled: boolean;
  icon: string;
  iconPack: string;
  geometry: FlowVisualizerType;
};

@Component({ name: 'GeometrySelector' })
export default class GeometrySelector extends Vue {
  @Prop({ type: String }) readonly showAs!: 'button' | 'radio';
  @Prop({ type: String }) readonly label!: string;
  @Prop({ type: Array, default: null }) readonly allowedGeometries!: FlowVisualizerType[] | null;
  @Prop({
    type: String,
    validator(val): boolean {
      return validFlowVisualizerTypeOrNull(val);
    }
  })
  readonly value!: FlowVisualizerType | null;
  @Prop({ type: Array, default: () => [] }) readonly properties!: ComponentProperty[];

  get errorMessage() {
    return this.properties.length && this.noChoice ? this.$t('flow.visualization.noGeometry') : '';
  }

  get choices(): GeometryChoicesProps[] {
    const geometries: Record<FlowVisualizerType, Omit<GeometryChoicesProps, 'geometry'>> = {
      [FlowVisualizerType.POINTS]: {
        enabled: isPoints(this.properties),
        iconPack: 'fak',
        icon: 'fa-vis-info-' + FlowVisualizerType.POINTS
      },
      [FlowVisualizerType.LINES]: {
        enabled: isLines(this.properties),
        iconPack: 'fak',
        icon: 'fa-vis-info-' + FlowVisualizerType.LINES
      },
      [FlowVisualizerType.POLYGONS]: {
        enabled: isPolygons(this.properties),
        iconPack: 'fak',
        icon: 'fa-vis-info-' + FlowVisualizerType.POLYGONS
      },
      [FlowVisualizerType.ARCS]: {
        enabled: isLines(this.properties) && this.showAs === 'button',
        iconPack: 'fak',
        icon: 'fa-vis-info-' + FlowVisualizerType.ARCS
      },
      [FlowVisualizerType.ICONS]: {
        enabled: isPoints(this.properties),
        iconPack: 'far',
        icon: 'map-marker-alt'
      },
      [FlowVisualizerType.FLOODING_GRID]: {
        enabled: isGrid(this.properties),
        iconPack: 'fad',
        icon: 'water'
      },
      [FlowVisualizerType.GRID]: {
        enabled: isGrid(this.properties),
        iconPack: 'far',
        icon: 'game-board-alt'
      }
    };

    return (this.allowedGeometries ?? (Object.keys(geometries) as FlowVisualizerType[])).reduce(
      (choices, geometry) => {
        choices.push({ geometry, ...geometries[geometry] });
        return choices;
      },
      [] as GeometryChoicesProps[]
    );
  }

  get validChoices(): FlowVisualizerType[] {
    return this.choices.filter(c => c.enabled).map(c => c.geometry);
  }

  get choice(): FlowVisualizerType | null {
    return this.value;
  }

  set choice(val: FlowVisualizerType | null) {
    this.$emit('input', val);
  }

  get firstValue(): FlowVisualizerType | null {
    return this.validChoices?.[0] ?? null;
  }

  get noChoice() {
    return this.choices.every(c => !c.enabled);
  }

  @Watch('validChoices')
  setValidValue() {
    if (!this.choice || !this.validChoices.includes(this.choice)) {
      this.choice = this.firstValue;
    }
  }

  mounted() {
    this.setValidValue();
  }
}
