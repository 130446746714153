








































import { Watch, Prop, Component, Vue } from 'vue-property-decorator';
import { Organisation } from '@/types';
import { UUID } from '@movici-flow-common/types';
import pick from 'lodash/pick';
import { organisationStore } from '@/store/store-accessor';

@Component({ name: 'OrganisationDetail' })
export default class OrganisationDetail extends Vue {
  @Prop({ type: String }) readonly uuid?: UUID;
  checkedOrganisatons: Organisation[] = [];

  get organisation() {
    return organisationStore.organisation;
  }

  get addMode() {
    return !this.uuid;
  }

  get editMode() {
    return !!this.uuid;
  }

  get title() {
    return this.addMode ? this.$t('organisation.addNew') : this.$t('organisation.edit');
  }

  onSave() {
    if (this.addMode) {
      this.addOrganisation();
    } else {
      this.editOrganisation();
    }
  }

  async editOrganisation() {
    await organisationStore.updateOrganisation(this.getEditPayload());
    this.$flow.snackbar.successMessage('Organisation successfully updated');
    this.initilialize();
  }

  async addOrganisation() {
    const resp = await organisationStore.addOrganisation(this.getAddPayload());
    if (resp?.organisation_uuid) {
      this.$flow.snackbar.successMessage(
        `Organisation ${this.organisation.name} successfully created`
      );
      this.goToEdit(resp.organisation_uuid);
    }
  }

  goBack() {
    this.$router.push({ name: 'Organisations' });
  }

  @Watch('uuid')
  initilialize() {
    if (this.editMode && this.uuid) {
      organisationStore.getOrganisation(this.uuid);
    } else {
      organisationStore.clearOrganisation();
    }
  }

  getEditPayload() {
    return {
      organisation_uuid: this.uuid,
      ...pick(this.organisation, [
        'name',
        'address',
        'zip_code',
        'city',
        'country',
        'chamber_of_commerce',
        'contact_email',
        'limits'
      ])
    } as Organisation;
  }

  getAddPayload() {
    return pick(this.organisation, [
      'name',
      'address',
      'zip_code',
      'city',
      'country',
      'chamber_of_commerce',
      'contact_email',
      'limits'
    ]) as Organisation;
  }

  goToEdit(uuid: UUID) {
    this.$router.push({
      name: 'OrganisationEdit',
      params: { uuid }
    });
  }

  mounted() {
    this.initilialize();
  }
}
