






































import { Component, Prop, Vue } from 'vue-property-decorator';
import { flowStore } from '@movici-flow-common/store/store-accessor';

@Component({ name: 'ProjectInfoBox' })
export default class ProjectInfoBox extends Vue {
  @Prop({ type: Boolean, default: false }) readonly edit!: boolean;

  get currentProject() {
    return flowStore.project;
  }

  get projects() {
    return flowStore.projects;
  }
}
