








import { Component, Prop, Vue } from 'vue-property-decorator';
import { Simulation } from '@movici-flow-common/types';
import { EventBus } from '@/eventbus';
import { scenarioStore } from '@/store/store-accessor';

const logDefaultMessage = 'No logs available (yet)... Try the refresh button :)';

@Component({ name: 'Logs' })
export default class Logs extends Vue {
  @Prop({ type: Object, required: true }) readonly simulation!: Simulation;
  minRows = 20;
  logs = logDefaultMessage;

  get numOfRows() {
    return Math.max(this.minRows, this.logs.split(/\r\n|\r|\n/).length);
  }

  get hasSimulation() {
    return this.simulation !== null;
  }

  async getLogs() {
    if (this.hasSimulation) {
      const logs = await scenarioStore.getLogs(this.simulation.scenario_uuid);
      if (logs) this.logs = logs;
    }
  }

  mounted() {
    this.logs = logDefaultMessage;
  }

  created() {
    EventBus.$on('scenarios-update-overview', this.getLogs);
  }

  beforeDestroy() {
    EventBus.$off('scenarios-update-overview', this.getLogs);
  }
}
