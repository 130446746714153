





import { Vue, Component } from 'vue-property-decorator';
import { generalStore } from './store/store-accessor';

@Component({ name: 'App' })
export default class App extends Vue {
  async mounted() {
    await generalStore.initApp();
  }
}
