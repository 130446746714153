import { Role, RoleBinding } from '@/types';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import uri, { authServerBase } from '@movici-flow-common/api/requests/uri';
import { Request } from '@movici-flow-common/api/requests';
import { UserCrudResponse, UUID } from '@movici-flow-common/types';

export class GetRoles extends Request<Role[]> {
  makeRequest(): AxiosRequestConfig {
    return {
      method: 'get',
      url: `${authServerBase}${uri.roles}`
    };
  }

  makeResponse(resp: AxiosResponse): Role[] {
    return resp.data.roles;
  }
}

export class AddUserRole extends Request<UserCrudResponse> {
  userUUID: UUID;
  userRole: RoleBinding;
  constructor(userUUID: UUID, userRole: RoleBinding) {
    super();
    this.userUUID = userUUID;
    this.userRole = userRole;
  }

  makeRequest(): AxiosRequestConfig {
    return {
      method: 'post',
      url: `${authServerBase}${uri.users}/${this.userUUID}${uri.roles}`,
      data: this.userRole
    };
  }
}

export class DeleteUserRole extends Request<UserCrudResponse> {
  userUUID: UUID;
  userRole: RoleBinding;
  constructor(userUUID: UUID, userRole: RoleBinding) {
    super();
    this.userUUID = userUUID;
    this.userRole = userRole;
  }

  makeRequest(): AxiosRequestConfig {
    return {
      method: 'delete',
      url: `${authServerBase}${uri.users}/${this.userUUID}${uri.roles}`,
      data: this.userRole
    };
  }
}
