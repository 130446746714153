



























































































import { Component, Mixins } from 'vue-property-decorator';
import { UUID, Project } from '@movici-flow-common/types';
import { generalStore, projectStore } from '@/store/store-accessor';
import ResourceListConsumer from '@/components/common/ResourceListConsumer';
import ResourceList from '@/components/common/ResourceList.vue';

@Component({
  name: 'Projects',
  components: {
    ResourceList
  }
})
export default class Projects extends Mixins<ResourceListConsumer<Project>>(ResourceListConsumer) {
  editPage = 'ProjectEdit';
  resourceTerm = 'project';
  defaultSort = 'display_name';
  filterKeys = ['display_name'];

  async getAll() {
    return await projectStore.getAllProjects();
  }

  getDeleteItemRequests(itemList: Project[]) {
    return itemList.map(element => projectStore.deleteProject(element));
  }

  async getDeleteMessage(deleteItems: Project[]) {
    const deleteOneMessage = `Are you sure you want to <b>delete</b> this ${this.resourceTerm} and all its scenarios and datasets? This action cannot be undone.`,
      deleteMultipleMessage = `Are you sure you want to <b>delete</b> ${deleteItems.length} ${this.resourceTerm}s and all their scenarios and datasets? This action cannot be undone.`,
      deleteTitle = `Delete ${this.resourceTerm}(s)`;

    return {
      message: deleteItems.length > 1 ? deleteMultipleMessage : deleteOneMessage,
      title: deleteTitle
    };
  }

  async activateProjectIdAndGo(uuid: UUID, route: string) {
    await projectStore.setActiveProjectId(uuid);
    this.$router.push(route);
  }

  setLoading(payload: { value: boolean; msg?: string }) {
    generalStore.setLoading(payload);
  }
}
