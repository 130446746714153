


















































import { Component, Mixins } from 'vue-property-decorator';
import { generalStore, modelTypeStore } from '@/store/store-accessor';
import { ModelType } from '@/types';
import ResourceListConsumer from '@/components/common/ResourceListConsumer';
import ResourceList from '@/components/common/ResourceList.vue';
@Component({
  name: 'ModelTypes',
  components: {
    ResourceList
  }
})
export default class ModelTypes extends Mixins<ResourceListConsumer<ModelType>>(
  ResourceListConsumer
) {
  editPage = 'ModelTypeEdit';
  resourceTerm = 'model type';
  defaultSort = 'name';
  filterKeys = ['name'];

  async getAll() {
    return await modelTypeStore.getModelTypes();
  }

  getDeleteItemRequests(itemList: ModelType[]) {
    return itemList
      .filter(element => element.uuid)
      .map(element => modelTypeStore.deleteModelType((element as { uuid: string }).uuid));
  }

  setLoading(payload: { value: boolean; msg?: string }) {
    generalStore.setLoading(payload);
  }
}
