









































import DialogModal from '@/components/global-alt/DialogModal.vue';
import DraggableMixin from '@movici-flow-common/mixins/DraggableMixin';
import { ActionMenuItem } from '@movici-flow-common/types';
import { ChartVisualizerInfo } from '@movici-flow-common/visualizers/VisualizerInfo';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import Draggable from 'vuedraggable';
import AttributeChartConfig from './AttributeChartConfig.vue';
@Component({
  name: 'FlowChartPicker',
  components: { Draggable, AttributeChartConfig }
})
export default class FlowChartPicker extends Mixins(DraggableMixin) {
  @Prop({ type: Array, default: () => [] }) readonly value!: ChartVisualizerInfo[];
  @Prop({ type: Number, default: -2 }) readonly open!: number;
  group = 'charts';
  actions: ActionMenuItem[] = [
    {
      icon: 'edit',
      iconPack: 'far',
      label: 'Edit',
      event: 'edit'
    },
    {
      icon: 'trash',
      iconPack: 'far',
      label: 'Delete',
      event: 'delete',
      variant: 'danger'
    }
  ];

  get selectedChart() {
    return this.open > -2 ? this.value[this.open] : null;
  }

  close() {
    this.$emit('update:open', -2);
  }

  updateDraggable(event: { moved: { oldIndex: number; newIndex: number } }) {
    if (this.open == event.moved.oldIndex) {
      this.$emit('update:open', event.moved.newIndex);
    }
    this.$emit('input', this.move(event.moved.oldIndex, event.moved.newIndex, this.value));
  }

  getAttributeFromChart(config: ChartVisualizerInfo | null) {
    return config?.attribute;
  }

  startEditingItem(index: number) {
    if (this.open === -2) {
      this.$emit('update:open', index);
    } else if (this.open !== index) {
      this.$oruga.modal.open({
        parent: this,
        component: DialogModal,
        props: {
          message: '' + this.$t('flow.visualization.dialogs.closeConfigurator'),
          cancelText: '' + this.$t('actions.cancel'),
          confirmText: '' + this.$t('misc.yes'),

          variant: 'primary',
          canCancel: true,
          onConfirm: () => {
            this.$emit('update:open', -2);
            // this makes sure a fresh componenent is built without leftovers from previous values
            this.$nextTick(() => {
              this.$emit('update:open', index);
            });
          }
        }
      });
    }
  }

  updateItem(idx: number, val: ChartVisualizerInfo) {
    this.$emit(
      'input',
      this.value.map((info, arrayIdx) => {
        return arrayIdx === idx ? val : info;
      })
    );
    this.close();
  }

  deleteItem(idx: number) {
    const name = this.value[idx].title;
    this.$oruga.modal.open({
      parent: this,
      component: DialogModal,
      props: {
        message: '' + this.$t('flow.visualization.dialogs.deleteChart', { name }),
        cancelText: '' + this.$t('actions.cancel'),
        confirmText: '' + this.$t('actions.delete'),

        variant: 'danger',
        canCancel: true,
        onConfirm: () => {
          this.$emit(
            'input',
            this.value.filter((val, arrayIdx) => idx !== arrayIdx)
          );
          // if we delete what is open, we close the window
          if (this.open === idx) {
            this.$emit('update:open', -2);
          }
        }
      }
    });
  }
}
