import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { Organisation } from '@/types';
import {
  AddOrganisation,
  DeleteOrganisation,
  GetOrganisation,
  GetOrganisations,
  UpdateOrganisation
} from '@/api/requests';
import { apiStore } from '../store-accessor';

@Module({
  name: 'organisation',
  namespaced: true
})
export default class OrganisationStore extends VuexModule {
  organisations: Organisation[] = [];
  organisation: Organisation | Record<string, unknown> = {};

  get client() {
    return apiStore.client;
  }

  @Mutation
  SET_ORGANISATIONS(organisations: Organisation[]) {
    this.organisations = organisations;
  }

  @Mutation
  SET_ORGANISATION(organisation: Organisation) {
    this.organisation = organisation;
  }

  @Action({ rawError: true, commit: 'SET_ORGANISATIONS' })
  async getOrganisations() {
    return (await this.client.request(new GetOrganisations())) ?? [];
  }

  @Action({ rawError: true, commit: 'SET_ORGANISATION' })
  async getOrganisation(uuid: string) {
    return await this.client.request(new GetOrganisation(uuid));
  }

  @Action({ rawError: true })
  async updateOrganisation(organisation: Partial<Organisation>) {
    if (organisation.organisation_uuid) {
      const uuid = organisation.organisation_uuid;
      delete organisation.organisation_uuid;
      return await this.client.request(new UpdateOrganisation(uuid, organisation));
    }
    throw new Error('Cannot determine organisation_uuid');
  }

  @Action({ rawError: true })
  async addOrganisation(organisation: Organisation) {
    return await this.client.request(new AddOrganisation(organisation));
  }

  @Action({ rawError: true })
  async deleteOrganisation(payload: { organisation_uuid: string }) {
    return await this.client.request(new DeleteOrganisation(payload.organisation_uuid));
  }

  @Action({ rawError: true, commit: 'SET_ORGANISATION' })
  clearOrganisation() {
    return {};
  }
}
