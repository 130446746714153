








































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import {
  CameraOptions,
  GeocodeSearchQuery,
  GeocodeSearchResult,
  GeocodeSuggestion
} from '@movici-flow-common/types';
import { flowUIStore, geocodeStore } from '@movici-flow-common/store/store-accessor';
import mapboxgl from 'mapbox-gl';

@Component({ name: 'SearchBar' })
export default class SearchBar extends Vue {
  @Prop({ type: Object, default: () => new Object() }) readonly viewState!: CameraOptions;
  @Prop({ type: Object, default: null }) readonly map!: mapboxgl.Map | null;
  @Prop({ type: Boolean, default: false }) readonly isRight!: boolean;
  @Ref('autocomplete') readonly autocomplete!: { focus: () => void };

  search = '';
  errors = '';
  result: GeocodeSearchResult | null = null;
  marker: mapboxgl.Marker | null = null;
  expand = false;
  typing = false;

  get query(): GeocodeSearchQuery | null {
    if (!this.search) return null;
    return {
      query: this.search,
      language: flowUIStore.lang,
      nearby_location: [this.viewState.longitude, this.viewState.latitude]
    };
  }

  get suggestions(): GeocodeSuggestion[] {
    return geocodeStore.suggestions;
  }

  expandAndFocus() {
    this.expand = true;
    this.$nextTick(() => this.autocomplete.focus());
  }

  async getFirstSearchResult() {
    this.result = null;
    if (this.query) {
      this.result = await geocodeStore.getFirstResult(this.query);
      if (this.result) {
        this.search = this.result.text;
      } else {
        this.errors = 'No result found';
      }
    }
  }

  async selectLocationFromSuggestion(suggestion?: GeocodeSuggestion) {
    if (suggestion) {
      this.result = await geocodeStore.resolveSuggestion(suggestion);
    }
  }

  updateViewState(viewState: Partial<CameraOptions>) {
    this.$emit('update:view-state', Object.assign({}, this.viewState, viewState));
  }

  updateMarker(result: GeocodeSearchResult | null, map: mapboxgl.Map) {
    if (this.marker) {
      this.marker.setPopup();
      this.marker.remove();
    }
    this.marker = null;

    if (result) {
      this.marker = new mapboxgl.Marker()
        .setLngLat(result.location)
        .setPopup(new mapboxgl.Popup().setHTML(result.text))
        .addTo(map);

      this.marker.togglePopup();
    }
  }

  @Watch('result')
  onResult(result: GeocodeSearchResult | null) {
    this.map && this.updateMarker(result, this.map);
    if (!result) return;
    this.updateViewState({
      longitude: result.location[0],
      latitude: result.location[1],
      zoom: 11,
      transitionDuration: 300
    });
  }

  @Watch('search')
  onSearch(search: string | null) {
    if (!search) {
      this.map && this.updateMarker(null, this.map);
      if (!this.typing) {
        this.expand = false;
      }
    }
    this.typing = false;
  }

  onTyping() {
    this.typing = true;
  }

  @Watch('query')
  async updateSuggestions(query: GeocodeSearchQuery | null) {
    this.errors = '';
    if (!query) {
      geocodeStore.setSuggestions([]);
      return;
    }
    await geocodeStore.updateSuggestions(query);
    if (!this.suggestions.length) {
      this.errors = 'No nearby result found';
    }
  }
}
