






















import { Component, Prop, Vue } from 'vue-property-decorator';
import { UUID } from '@movici-flow-common/types';
import { scenarioStore, simulationStore } from '@/store/store-accessor';

@Component({ name: 'ExternalModels' })
export default class ExternalModels extends Vue {
  @Prop({ type: Array, default: () => [] }) readonly models!: string[];
  updates: Record<string, string> = {};
  minRows = 5;

  get scenario_uuid() {
    return scenarioStore.currentScenario?.uuid;
  }

  isJsonString(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  sendUpdate(model_name: string, scenario_uuid: UUID) {
    simulationStore
      .sendUpdate({
        model_name,
        scenario_uuid,
        update: JSON.parse(this.updates[model_name])
      })
      .then(() => {
        this.$flow.snackbar.successMessage(`Succesfully sent update to model "${model_name}"`);
        this.updates[model_name] = '';
      });
  }

  numOfRows(model: number) {
    let updateLines = 0;
    try {
      updateLines = this.models[model].split(/\r\n|\r|\n/).length;
    } catch (e) {
      if (!(e instanceof TypeError)) {
        throw e;
      }
    }
    return Math.max(this.minRows, updateLines);
  }
}
