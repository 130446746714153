


















import { Vue, Component } from 'vue-property-decorator';

@Component({ name: 'Terms' })
class Terms extends Vue {}

export default Terms;
