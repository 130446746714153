

















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Configurator from './Configurator.vue';
import Overview from './Overview.vue';
import Timeline from './Timeline.vue';
import { EventBus } from '../../eventbus';
import { Scenario, Simulation } from '@movici-flow-common/types';
import { scenarioStore } from '@/store/store-accessor';

const TabIds = ['editor', 'config', 'overview', 'timeline'];

@Component({
  name: 'ScenarioDetail',
  components: {
    Configurator,
    Timeline,
    Overview
  }
})
export default class ScenarioDetail extends Vue {
  @Prop({ type: String }) readonly uuid?: string;
  scenario: Partial<Scenario> | null = null;
  simulation: Simulation | null = null;
  errors: string[] = [];
  activeTab = 1;

  get addMode() {
    return !this.uuid;
  }

  get editMode() {
    return !!this.uuid;
  }

  get title() {
    return this.addMode ? this.$t('scenario.addNew') : this.$t('scenario.edit');
  }

  get hasTimeline() {
    return this.scenario?.has_timeline;
  }

  get activeTabId() {
    return TabIds[this.activeTab];
  }

  @Watch('activeTab')
  afterActiveTab() {
    this.errors = [];
    EventBus.$emit('scenarios-active-tab', this.activeTabId);
  }

  async initializeThisScenario() {
    if (this.editMode && this.uuid) {
      this.scenario = await scenarioStore.getScenario(this.uuid);
      if (this.scenario && this.scenario.uuid) {
        this.simulation = await scenarioStore.getSimulation(this.scenario.uuid);
        this.scenario.has_simulation = !!this.simulation;
      }
    } else {
      this.scenario = {
        name: '',
        display_name: '',
        models: []
      };
    }
  }

  async validateBeforeSave(scenario: Scenario) {
    let resp;

    await scenarioStore.setCurrentScenario(scenario);

    if (this.editMode) {
      resp = await this.updateScenario();
    } else {
      resp = await this.addScenario();
    }

    if (resp) {
      this.goBack();
    }
  }

  async updateScenario() {
    const resp = await scenarioStore.updateScenario();
    if (resp) this.$flow.snackbar.successMessage('Scenario successfully updated');
    return resp;
  }

  async addScenario() {
    const resp = await scenarioStore.addScenario({});
    if (resp) this.$flow.snackbar.successMessage('Scenario successfully created');
    return resp;
  }

  goBack() {
    this.$router.push({ name: 'Scenarios' });
  }

  addErrors(errors: string[]) {
    if (Array.isArray(errors)) {
      this.errors = errors;
    } else if (typeof errors === 'string') {
      this.errors = [errors];
    } else {
      console.log('invalid error', errors);
    }
  }

  mounted() {
    this.initializeThisScenario();
  }
}
