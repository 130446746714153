



















































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import pick from 'lodash/pick';
import { Dataset } from '../types';
import FlowContainer from './FlowContainer.vue';
import ProjectInfoBox from './info_box/ProjectInfoBox.vue';
import DatasetViewer from './widgets/DatasetViewer.vue';
import { flowStore, flowUIStore } from '../store/store-accessor';
import { MoviciError } from '@movici-flow-common/errors';
import { sortByKeys } from '@movici-flow-common/utils';

@Component({
  name: 'FlowDataset',
  components: {
    FlowContainer,
    ProjectInfoBox,
    DatasetViewer
  }
})
export default class FlowDataset extends Vue {
  @Prop({ type: String }) readonly currentProjectName?: string;
  @Prop({ type: String }) readonly currentScenarioName?: string;
  currentDataset: Dataset | null = null;
  search = '';
  datasets: Dataset[] = [];

  get currentDatasetUUID() {
    return this.currentDataset?.uuid;
  }

  get hasProjectsCapabilities() {
    return flowStore.hasProjectsCapabilities;
  }

  /**
   * return object with details of currentDataset while also adding filters to date attributes
   */
  get details() {
    const details = pick(this.currentDataset, ['created_on', 'last_modified', 'source', 'tags']);
    details.created_on = this.$options.filters?.dateString(details.created_on, true);
    details.last_modified = this.$options.filters?.dateString(details.last_modified, true);
    return details;
  }

  /**
   * Filters out datasets by search
   */
  get filteredDatasets() {
    return this.datasets
      .filter(dataset => {
        const name = (dataset?.display_name || dataset.name).toLowerCase(),
          searchTerm = this.search.toLowerCase();
        return name.includes(searchTerm);
      })
      .sort(sortByKeys(['+display_name', '+name']));
  }

  get hasGeocodeCapabilities() {
    return flowStore.hasGeocodeCapabilities;
  }

  datasetDisplayName(dataset: Dataset) {
    if (dataset.display_name) {
      return `${dataset.display_name} <small>(${dataset.name})</small>`;
    } else {
      return dataset.name;
    }
  }

  datasetDisplayTitleText(dataset: Dataset) {
    if (dataset.display_name) {
      return `${dataset.display_name} (${dataset.name})`;
    } else {
      return dataset.name;
    }
  }

  setDataset(currentDataset: Dataset) {
    this.currentDataset = currentDataset;
  }

  async mounted() {
    flowUIStore.setLoading({ value: true, msg: 'Loading datasets...' });

    try {
      await flowStore.setupFlowStore({
        config: {
          currentProjectName: this.currentProjectName,
          needProject: true,
          currentScenarioName: this.currentScenarioName,
          needScenario: false,
          disableCollapser: true
        }
      });

      if (flowStore.hasProjectsCapabilities && !flowStore.hasProject) {
        await this.$router.push({ name: 'FlowProject' });
      }

      this.datasets = (await flowStore.getDatasets()) || [];

      flowUIStore.setLoading({ value: false });
    } catch (error) {
      flowUIStore.setLoading({ value: false });
      if (error instanceof MoviciError) {
        await error.handleError({
          $t: this.$t.bind(this),
          $router: this.$router,
          query: {
            project: this.currentProjectName
          }
        });
      }
    }
  }
}
