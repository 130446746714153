





























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { WidgetOpts } from '@/types';
import BaseWidget from './Base.vue';

@Component({
  name: 'MediaWidget',
  components: {
    BaseWidget
  }
})
export default class MediaWidget extends Vue {
  @Prop({ type: Object, default: null }) readonly opts!: WidgetOpts | null;
}
