
































































































































import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import {
  PropertySummary,
  PopupClause,
  PopupItem,
  FlowVisualizerOptions
} from '@movici-flow-common/types';
import { propertyString } from '@movici-flow-common/utils';
import Draggable from 'vuedraggable';
import DraggableMixin from '@movici-flow-common/mixins/DraggableMixin';
import ValidationProvider from '@movici-flow-common/mixins/ValidationProvider';
import FormValidator from '@movici-flow-common/utils/FormValidator';
import AttributeSuggestions from './AttributeSuggestions.vue';

@Component({
  name: 'PopupConfigurator',
  components: {
    Draggable,
    AttributeSuggestions
  }
})
export default class PopupConfigurator extends Mixins(ValidationProvider, DraggableMixin) {
  @Prop({ type: Object }) readonly value?: PopupClause;
  @Prop({ type: Array, default: () => [] }) readonly entityProps!: PropertySummary[];
  @Prop({ type: Object, required: true }) declare readonly validator: FormValidator;
  @Prop({ type: Object }) readonly settings?: FlowVisualizerOptions;
  group = 'popup-config';
  items: PopupItem[] = [];
  showPopup = false;
  dynamicTitle = false;
  onHover = false;

  propertyString = propertyString;

  get defaults() {
    return {
      title: '',
      items: [],
      show: false,
      onHover: false,
      dynamicTitle: false
    };
  }

  get currentClause(): PopupClause {
    return Object.assign({}, this.defaults, this.value);
  }
  /**
   * this filters out already set attribute and returns only the ones not selected
   */
  get entityPropsFiltered() {
    return this.entityProps.filter(
      entityProp => !this.currentClause.items.find(item => item.attribute.name === entityProp.name)
    );
  }

  get availableItems(): PopupItem[] {
    return this.entityPropsFiltered.map(p => {
      return {
        name: '',
        attribute: p
      };
    });
  }

  get suggestions() {
    // any new byValue clauses must be added here!
    return [
      this.settings?.color?.byValue?.attribute,
      this.settings?.size?.byValue?.attribute,
      this.settings?.icon?.byValue?.attribute,
      this.settings?.shape?.byValue?.attribute,
      this.settings?.visibility?.byValue.attribute
    ].filter((attr, pos, arr) => attr && arr.indexOf(attr) == pos);
  }

  // saves a last version of the clause, then emit whole settings object with new clause
  @Watch('dynamicTitle')
  updateDynamicTitle(dynamicTitle: boolean) {
    this.updateValue(Object.assign(this.currentClause, { dynamicTitle }));
  }

  @Watch('showPopup')
  updateSettings(showPopup: boolean) {
    this.updateValue(Object.assign(this.currentClause, { show: showPopup }));
  }

  @Watch('onHover')
  updateOnHover(onHover: boolean) {
    this.updateValue(Object.assign(this.currentClause, { onHover }));
  }

  addItem(prop: PropertySummary) {
    this.items = this.items.concat({ name: '', attribute: prop });
    this.updateValue({ items: this.items });
    this.validator.touch('popup-items');
  }

  removeItem(idx: number) {
    this.items = this.items.filter((item, x) => idx !== x);
    this.updateValue({ items: this.items });
    this.validator.touch('popup-items');
  }

  updateDraggable(event: { moved: { oldIndex: number; newIndex: number } }) {
    const items = this.move(event.moved.oldIndex, event.moved.newIndex, this.items);
    this.updateValue({ items });
  }

  updateName(idx: number, name: string) {
    this.$set(this.items[idx], 'name', name || this.propertyString(this.items[idx].attribute));
    this.updateValue({ items: this.items });
  }

  updateValue(props: Partial<PopupClause>) {
    Object.keys(props).forEach(key => this.validator.touch('popup-' + key));
    const clause = Object.assign({}, this.currentClause, props);
    this.$emit('input', clause);
  }

  setupValidator() {
    this.validator.configure({
      validators: {
        'popup-items': () => {
          if (this.showPopup && !this.items.length && !this.currentClause.title) {
            return 'Select at least 1 property or title';
          }
        }
      },
      onValidate: e => (this.errors = e)
    });
  }

  mounted() {
    this.items = this.currentClause?.items ?? [];
    this.showPopup = this.currentClause ? this.currentClause.show ?? true : false;
    this.onHover = this.currentClause.onHover ?? false;
    this.dynamicTitle = !!this.currentClause.dynamicTitle;
    this.setupValidator();
  }
  beforeDestroy() {
    if (this.validator) {
      this.validator.reset();
    }
  }
}
