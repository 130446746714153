


















































































































































import DialogModal from '@/components/global-alt/DialogModal.vue';
import { PickInfo } from '@deck.gl/core/lib/deck';
import { ensureProjection, transformBBox } from '@movici-flow-common/crs';
import { MoviciError } from '@movici-flow-common/errors';
import { Visualizer } from '@movici-flow-common/visualizers';
import VisualizerManager from '@movici-flow-common/visualizers/VisualizerManager';
import { isEmpty } from 'lodash';
import isEqual from 'lodash/isEqual';
import isError from 'lodash/isError';
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { flowStore, flowUIStore, flowVisualizationStore } from '../store/store-accessor';
import {
  ActionMenuItem,
  CameraOptions,
  DeckEntityObject,
  Nullable,
  TimeOrientedSimulationInfo,
  UUID,
  View
} from '../types';
import { buildFlowUrl } from '../utils';
import { successMessage } from '../utils/snackbar';
import { simplifiedCamera, validateForContentErrors } from '../visualizers/viewHelpers';
import {
  BaseVisualizerInfo,
  ChartVisualizerInfo,
  ChartVisualizerItem,
  ComposableVisualizerInfo
} from '../visualizers/VisualizerInfo';
import ChartAttributePicker from './charts/ChartAttributePicker.vue';
import ChartVis from './charts/ChartVis.vue';
import FlowChartPicker from './charts/FlowChartPicker.vue';
import FlowContainer from './FlowContainer.vue';
import ProjectInfoBox from './info_box/ProjectInfoBox.vue';
import ScenarioInfoBox from './info_box/ScenarioInfoBox.vue';
import ViewInfoBox from './info_box/ViewInfoBox.vue';
import BaseMapControl from './map/controls/BaseMapControl.vue';
import NavigationControl from './map/controls/NavigationControl.vue';
import SearchBar from './map/controls/SearchBar.vue';
import defaults from './map/defaults';
import MapVis from './map/MapVis.vue';
import FlowLegend from './map_widgets/legends/FlowLegend.vue';
import MapContextMenu from './map_widgets/MapContextMenu.vue';
import MapEntityPopup from './map_widgets/MapEntityPopup.vue';
import RightSidePopup from './map_widgets/RightSidePopup.vue';
import TimeSlider from './map_widgets/TimeSlider.vue';
import FlowLayerPicker from './widgets/FlowLayerPicker.vue';

@Component({
  name: 'FlowVisualization',
  components: {
    FlowLayerPicker,
    FlowContainer,
    MapVis,
    ProjectInfoBox,
    ScenarioInfoBox,
    ViewInfoBox,
    SearchBar,
    NavigationControl,
    BaseMapControl,
    TimeSlider,
    MapContextMenu,
    FlowLegend,
    MapEntityPopup,
    RightSidePopup,
    FlowChartPicker,
    ChartVis
  },
  beforeRouteLeave(to: unknown, from: unknown, next: () => void) {
    if ((this as FlowVisualization).isCurrentViewDirty) {
      this.$oruga.modal.open({
        parent: this,
        component: DialogModal,
        props: {
          message: '' + this.$t('flow.visualization.dialogs.unsavedView'),
          cancelText: '' + this.$t('actions.cancel'),
          confirmText: '' + this.$t('actions.leave'),
          variant: 'danger',
          canCancel: true,
          onConfirm: () => next()
        }
      });
    } else if ((this as FlowVisualization).visualizers.length) {
      this.$oruga.modal.open({
        parent: this,
        component: DialogModal,
        props: {
          message: '' + this.$t('flow.visualization.dialogs.leaveView'),
          cancelText: '' + this.$t('actions.cancel'),
          confirmText: '' + this.$t('actions.leave'),
          variant: 'warning',
          canCancel: true,
          onConfirm: () => next()
        }
      });
    } else {
      next();
    }
  }
})
export default class FlowVisualization extends Vue {
  @Prop({ type: String }) readonly currentProjectName?: string;
  @Prop({ type: String }) readonly currentScenarioName?: string;
  @Prop({ type: String }) readonly currentViewUUID?: UUID;
  @Ref('tabs') readonly tabs?: Vue;
  @Ref('mapVis') readonly mapVisEl!: MapVis;
  @Ref('layerPicker') readonly layerPickerEl!: FlowLayerPicker;
  tabHeight: Partial<CSSStyleDeclaration> = {};
  isCurrentViewDirty = false;
  viewName = 'Untitled';
  viewState: Nullable<CameraOptions> = defaults.viewState();
  visualizerTabOpen = 0;
  visualizerOpen = -2;
  chartConfigOpen = -2;

  validVisualizers: ComposableVisualizerInfo[] = [];
  charts: ChartVisualizerInfo[] = [];
  chartVisOpen = '';
  chartVisExpanded = false;

  get views(): View[] {
    return flowVisualizationStore.views;
  }

  get view(): View | null {
    return flowVisualizationStore.view;
  }

  set view(view: View | null) {
    flowVisualizationStore.updateCurrentView(view);
  }

  get visualizers() {
    return flowVisualizationStore.visualizers;
  }

  set visualizers(updatedCVIs: ComposableVisualizerInfo[]) {
    flowVisualizationStore.updateVisualizers(updatedCVIs);
  }

  get currentProject() {
    return flowStore.project;
  }

  get centerCamera() {
    return this.view?.config.camera ?? defaults.viewState();
  }

  get currentScenario() {
    return flowStore.scenario;
  }

  // Map Vis getters
  get timelineInfo(): TimeOrientedSimulationInfo | null {
    return flowStore.timelineInfo;
  }

  get timestamp() {
    return flowVisualizationStore.timestamp;
  }

  set timestamp(val: number) {
    flowVisualizationStore.setTimestamp(val);
  }

  get hasPendingChanges() {
    const currentView = { ...this.view },
      serializedView = this.serializeCurrentView();

    return !isEqual(
      {
        visualizers: currentView.config?.visualizers,
        charts: currentView.config?.charts,
        name: currentView.name
      },
      {
        visualizers: serializedView.config?.visualizers,
        charts: serializedView.config?.charts,
        name: serializedView.name
      }
    );
  }

  get hasGeocodeCapabilities() {
    return flowStore.hasGeocodeCapabilities;
  }

  get hasProjectsCapabilities() {
    return flowStore.hasProjectsCapabilities;
  }

  get isDirtyLabel() {
    return this.isCurrentViewDirty
      ? this.$t('flow.visualization.unsavedChanges')
      : this.$t('flow.visualization.viewUpToDate');
  }

  async reloadWithViewUrl(viewUUID?: string, reload?: boolean) {
    await this.$router.push(
      buildFlowUrl('FlowVisualization', {
        project: this.currentProject?.name,
        scenario: this.currentScenario?.name,
        view: viewUUID
      })
    );

    if (reload) {
      this.$router.go(0);
    }
  }

  getContextMenuActions(info: PickInfo<unknown> | undefined): ActionMenuItem[] {
    return info?.layer
      ? [
          {
            label: 'Add attribute to a chart',
            event: 'chartAttributePicker',
            icon: 'chart-line'
          }
        ]
      : [];
  }

  openChart(
    pickInfo: PickInfo<DeckEntityObject<unknown>>,
    visualizers: VisualizerManager<ComposableVisualizerInfo, Visualizer>
  ) {
    const layerId = pickInfo.layer.id.split('-')[0],
      currentVisualizer = visualizers.getVisualizers().find(v => v.baseID === layerId);

    if (currentVisualizer) {
      const entityGroup = currentVisualizer.info.entityGroup,
        datasetName = currentVisualizer.info.datasetName,
        datasetUUID = currentVisualizer.info.datasetUUID;
      this.openChartAttributePicker({
        info: pickInfo,
        entityGroup,
        datasetName,
        datasetUUID,
        scenarioUUID: this.currentScenario?.uuid
      });
    }
  }

  async openChartAttributePicker({
    info,
    entityGroup,
    datasetName,
    datasetUUID,
    scenarioUUID
  }: {
    info: PickInfo<DeckEntityObject<unknown>>;
    entityGroup: string;
    datasetName: string;
    datasetUUID?: string | null;
    scenarioUUID?: string;
  }) {
    this.$oruga.modal.open({
      parent: this,
      component: ChartAttributePicker,
      width: 'max-content',
      canCancel: ['x', 'escape'],
      override: 'overflow-visible',
      props: {
        value: this.charts,
        object: info.object,
        datasetName,
        scenarioUUID,
        datasetUUID,
        entityGroup
      },
      events: {
        openConfig: (index: number) => {
          this.changeVisualizer({ tab: 1, index });
        },
        openChart: (id: string) => {
          this.chartVisOpen = id;
          this.chartVisExpanded = true;
        },
        input: (charts: ChartVisualizerInfo[]) => {
          this.charts = charts;
        }
      }
    });
  }

  changeVisualizer({ tab, index }: { tab: number; index: number }) {
    console.log({ tab, index });

    this.visualizerTabOpen = tab;
    if (tab === 0) {
      this.visualizerOpen = index;
    } else if (tab === 1) {
      this.chartConfigOpen = index;
    }

    // this.$nextTick(() => {
    //   this.visualizerOpen = index;
    // });
  }
  /**
   * set the state of the visualization (visualizers, camera, etc) from a `FlowViewConfig` object
   * Visualizer config errors (such as invalid dataset or entity group) are logged and the
   * associated visualizer is not created
   * TODO: in a later stage, add the errors to the visualizer info and show them somewhere in the
   *   ui like we do in webviz
   */
  async loadView(view: View) {
    // just reload in case it's different
    if (view.uuid && view.uuid !== this.currentViewUUID) {
      this.reloadWithViewUrl(view.uuid, true);
      return;
    }

    const datasets =
      this.currentScenario?.datasets.reduce((obj, d) => {
        obj[d.name] = d.uuid;
        return obj;
      }, {} as Record<string, UUID>) ?? {};

    const visualizers: ComposableVisualizerInfo[] = view.config.visualizers.map(config => {
      return ComposableVisualizerInfo.fromVisualizerConfig({
        config,
        datasets,
        scenario: this.currentScenario
      });
    });

    // When we can configure from view
    const charts: ChartVisualizerInfo[] = (view.config.charts ?? []).map(conf => {
      return new ChartVisualizerInfo({
        title: conf.title,
        attribute: conf.attribute,
        scenarioUUID: this.currentScenario?.uuid,
        settings: conf.settings,
        items: conf.items.map(item => {
          return new ChartVisualizerItem(item);
        })
      });
    });

    this.viewName = view.name;
    this.visualizers = visualizers;
    this.charts = charts;

    if (view.config.camera) {
      this.viewState = { ...view.config.camera, transitionDuration: 300 };
    }

    if (typeof view.config.timestamp === 'number') {
      this.timestamp = view.config.timestamp;
    }

    this.view = view;

    this.updateIsViewDirty(this.hasPendingChanges);
  }

  saveViewAsNew() {
    delete this.view?.uuid;
    this.confirmSaveView();
  }

  async saveView(viewUUID?: string) {
    const view = this.serializeCurrentView();
    if (viewUUID) {
      await this.updateView({ view, viewUUID });
    } else if (this.currentScenario?.uuid) {
      const resp = await this.createView({ view, scenarioUUID: this.currentScenario.uuid });
      if (resp?.uuid) {
        viewUUID = resp.uuid;
        await this.reloadWithViewUrl(resp.uuid);
      }
    }

    this.view = { ...view, uuid: viewUUID, scenario_uuid: this.currentScenario?.uuid };
    this.updateIsViewDirty(this.hasPendingChanges);
  }

  async confirmSaveView() {
    const view = this.serializeCurrentView(),
      viewUUID = this.view?.uuid,
      name = view.name;

    if (viewUUID && name) {
      this.$oruga.modal.open({
        parent: this,
        component: DialogModal,
        props: {
          message:
            '' +
            this.$t('flow.visualization.dialogs.confirmOverwriteView', {
              name
            }),
          cancelText: '' + this.$t('actions.cancel'),
          confirmText: '' + this.$t('misc.yes'),

          variant: 'primary',
          canCancel: true,
          onConfirm: () => this.saveView(viewUUID).then(() => {})
        }
      });
    } else if (this.currentScenario?.uuid) {
      const resp = await this.createView({ view, scenarioUUID: this.currentScenario.uuid });

      if (resp && resp.uuid) {
        this.updateIsViewDirty(this.hasPendingChanges);
        await this.reloadWithViewUrl(resp.uuid);
      }
    }
  }

  async confirmDeleteView() {
    const viewName = this.view?.name,
      viewUUID = this.view?.uuid;

    if (viewUUID && viewName) {
      this.$oruga.modal.open({
        parent: this,
        component: DialogModal,
        props: {
          message:
            '' +
            this.$t('flow.visualization.dialogs.confirmDeleteView', {
              name: viewName
            }),
          cancelText: '' + this.$t('actions.cancel'),
          confirmText: '' + this.$t('misc.yes'),

          variant: 'danger',
          canCancel: true,
          onConfirm: () =>
            this.deleteView({ viewUUID }).then(() => {
              this.resetView();
              this.reloadWithViewUrl();
            })
        }
      });
    } else {
      this.$oruga.modal.open({
        parent: this,
        component: DialogModal,
        props: {
          message: '' + this.$t('flow.visualization.dialogs.noViewToDelete'),
          canCancel: false
        }
      });
    }
  }

  async updateView({ view, viewUUID }: { view: View; viewUUID: UUID }) {
    const resp = await flowVisualizationStore.updateView({ viewUUID, view });
    if (resp) {
      successMessage('' + this.$t('flow.visualization.dialogs.viewUpdateSuccess'));
    }
  }

  async deleteView({ viewUUID }: { viewUUID: UUID }) {
    const resp = await flowVisualizationStore.deleteView(viewUUID);
    if (resp) {
      successMessage('' + this.$t('flow.visualization.dialogs.viewDeleteSuccess'));
    }
    this.resetView();
  }

  async createView({ view, scenarioUUID }: { view: View; scenarioUUID: string }) {
    const resp = await flowVisualizationStore.createView({
      scenarioUUID,
      view
    });

    if (resp) {
      successMessage('' + this.$t('flow.visualization.dialogs.viewCreateSuccess'));
      this.view = { ...view, uuid: resp.view_uuid };
      return this.view;
    }
  }

  @Watch('viewName')
  @Watch('visualizers')
  @Watch('charts')
  onUpdateView() {
    this.updateIsViewDirty(this.hasPendingChanges);
  }

  updateIsViewDirty(newValue: boolean) {
    this.isCurrentViewDirty = newValue;
  }

  serializeCurrentView(exportCamera = true): View {
    const rv: View = {
      name: this.viewName,
      config: {
        version: 1,
        timestamp: this.timestamp,
        visualizers: this.visualizers.map(info => info.toVisualizerConfig()),
        charts: this.charts.map(info => info.toVisualizerConfig())
      }
    };

    if (exportCamera && this.viewState) {
      rv.config.camera = simplifiedCamera(this.viewState);
    }

    return rv;
  }

  async updateTabHeight() {
    await this.$nextTick();
    const top = this.tabs?.$el.getBoundingClientRect().top ?? 260;
    this.tabHeight = { height: `calc(100vh - 2rem - ${top}px)` };
  }

  async resetView() {
    this.viewName = 'Untitled';
    this.visualizers = [];
    this.view = this.serializeCurrentView();
  }

  @Watch('visualizers', { immediate: true })
  resolveMapVisualizersDataset() {
    this.doResolveDatasets(this.visualizers);
  }

  @Watch('charts', { immediate: true })
  resolveChartVisualizersDataset() {
    this.doResolveDatasets(this.charts);
  }

  @Watch('visualizers')
  handleVisualizers() {
    Promise.all(
      this.visualizers.map(async info => {
        info.unsetError('content');

        try {
          const summary = info.datasetUUID
            ? await flowStore.getDatasetSummary({
                datasetUUID: info.datasetUUID,
                scenarioUUID: info.scenarioUUID
              })
            : null;
          validateForContentErrors(info, summary);
        } catch (e) {
          info.setError('content', isError(e) ? e.message : String(e));
        }
      })
    ).then(() => {
      this.validVisualizers = this.visualizers.filter(i => !isEmpty(i));
    });
  }

  doResolveDatasets(infos: BaseVisualizerInfo[]) {
    for (const vis of infos) {
      vis.unsetError('resolve');
      try {
        vis.resolveDatasets(flowStore.datasetsByName);
      } catch (error) {
        if (isError(error)) {
          vis.setError('resolve', error.message);
        }
      }
    }
  }

  /**
   * Checks whether there are props for project and scenario.
   * If there is a project, we set in the component, which triggers the watcher
   * Else, redirect to beginning of Flow.
   *
   * If there's also a prop for scenario, set that scenario in the component
   * TODO: validate scenario status, otherwise redirect to scenario config
   */
  async mounted() {
    try {
      flowUIStore.setLoading({ value: true, msg: 'Loading visualization...' });

      if (this.currentViewUUID) {
        await this.resetView();
        const view = await flowVisualizationStore.getViewById(this.currentViewUUID);
        await flowStore.setupFlowStoreByView({
          view,
          config: {
            currentProjectName: this.currentProjectName,
            needProject: false,
            currentScenarioName: this.currentScenarioName,
            needScenario: false
          }
        });
        await this.loadView(view);
      } else {
        await flowStore.setupFlowStore({
          config: {
            currentProjectName: this.currentProjectName,
            needProject: true,
            currentScenarioName: this.currentScenarioName,
            needScenario: true
          },
          reset: false
        });

        if (this.currentScenario?.bounding_box) {
          await ensureProjection(this.currentScenario.epsg_code);
          this.mapVisEl.zoomToBBox(
            transformBBox(this.currentScenario.bounding_box, this.currentScenario.epsg_code)
          );
        }

        await this.resetView();
      }

      flowUIStore.setLoading({ value: false });
      this.updateTabHeight();
    } catch (error: unknown) {
      flowUIStore.setLoading({ value: false });
      this.updateIsViewDirty(false);
      if (error instanceof MoviciError) {
        await error.handleError({
          $t: this.$t.bind(this),
          $router: this.$router,
          query: {
            project: this.currentProjectName,
            scenario: this.currentScenarioName,
            view: this.view?.uuid
          }
        });
      }
    }
  }

  get customTimeFormat(): (val: number) => string {
    // Time format is customized in such a way that we have at least 20 distinct displayed moments
    // so starting from a total duration 20 years, we display only the year, from a duration of 2
    // year we display only the month and year, etc. These are rough estimates though, it's not
    // rocket science

    const duration = this.timelineInfo
      ? this.timelineInfo.duration * this.timelineInfo.time_scale
      : 0;
    let levelOfDetail: number;
    if (duration > 3600 * 24 * 365 * 20) {
      // longer than 20 years shows only year
      levelOfDetail = 1;
    } else if (duration > 3600 * 24 * 365 * 2) {
      // longer than 2 years shows month and year
      levelOfDetail = 2;
    } else if (duration > 3600 * 24 * 30) {
      // longer than 1 month shows day month and year
      levelOfDetail = 3;
    } else {
      // anything shorter than a month shows everything
      levelOfDetail = 10;
    }
    return (val: number) => formatDate(new Date(val * 1000), levelOfDetail);
  }
}

function formatDate(d: Date, levelOfDetail: number): string {
  if (levelOfDetail >= 10) {
    return d.toLocaleString('NL-nl');
  }
  let rv = '';
  if (levelOfDetail >= 1) {
    // YYYY
    rv = String(d.getFullYear());
  }
  if (levelOfDetail >= 2) {
    // MM-YYYY
    rv = ('0' + (d.getMonth() + 1)).slice(-2) + '-' + rv;
  }
  if (levelOfDetail >= 3) {
    // DD-MM-YYYY
    rv = ('0' + d.getDate()).slice(-2) + '-' + rv;
  }
  return rv;
}
