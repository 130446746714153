





























import { Component, Vue, Prop } from 'vue-property-decorator';
import { RenderOrderType } from '@movici-flow-common/types';

@Component({ name: 'RenderOrder' })
export default class RenderOrder extends Vue {
  @Prop({ type: String, default: RenderOrderType.DISABLED }) readonly value!: RenderOrderType;

  get labels(): RenderOrderType[] {
    return Object.values(RenderOrderType);
  }

  input(newValue: RenderOrderType) {
    this.$emit('input', newValue);
  }
  mounted() {
    if (this.labels.indexOf(this.value) === -1) {
      this.input(RenderOrderType.DISABLED);
    }
  }
}
