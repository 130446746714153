












































































































import upperFirst from 'lodash/upperFirst';
import { Component, Mixins } from 'vue-property-decorator';
import { User } from '@/types';
import { generalStore, userStore } from '@/store/store-accessor';
import ResourceListConsumer from '@/components/common/ResourceListConsumer';
import ResourceList from '@/components/common/ResourceList.vue';
import { MovActionType } from '@movici-flow-common/types';
import DialogModal from '@/components/global-alt/DialogModal.vue';

@Component({ name: 'Users', components: { ResourceList } })
export default class Users extends Mixins<ResourceListConsumer<User>>(ResourceListConsumer) {
  editPage = 'UserEdit';
  resourceTerm = 'user';
  filterKeys = ['firstname', 'lastname', 'username'];
  defaultSort = 'firstname';
  uuidKey = 'user_uuid';
  checkedRowActions = [MovActionType.INVITE, MovActionType.EDIT, MovActionType.DELETE];
  singleRowActions = [MovActionType.INVITE, MovActionType.EDIT, MovActionType.DELETE];

  getDeleteItemRequests(itemList: User[]) {
    return itemList.map(element =>
      userStore.deleteUser({
        user_uuid: element.user_uuid
      })
    );
  }

  async getAll() {
    return await userStore.getUsers();
  }

  confirmInvite(inviteItems: User[]) {
    const inviteSingle = {
        message: 'Are you sure you want to <b>send an invitation</b> to this user?',
        title: 'Invite user',
        confirmText: 'Invite user'
      },
      inviteMultiple = {
        message: 'Are you sure you want to <b>send an invitation</b> to these users?',
        title: 'Invite user(s)',
        confirmText: 'Invite user(s)'
      },
      message = inviteItems.length > 1 ? inviteMultiple : inviteSingle;
    this.$oruga.modal.open({
      parent: this,
      component: DialogModal,
      props: {
        ...message,
        canCancel: true,
        variant: 'danger',
        hasIcon: true,
        onConfirm: () => this.inviteItems(inviteItems)
      },
      trapFocus: true
    });
  }

  async inviteItems(itemList: User[]) {
    const inviteRequests = itemList.map(user => userStore.inviteUser(user));

    await Promise.all(inviteRequests);
    await this.resetList();
    this.$flow.snackbar.successMessage('Invitation has been sent out');
  }

  async toggleActive(user: User) {
    const payload = {
        user_uuid: user.user_uuid,
        active: user.active
      },
      activated = payload.active ? 'activated' : 'deactivated';

    await userStore.updateUser(payload);
    this.$flow.snackbar.successMessage(`User ${user.username} successfully ${activated}`);
  }

  confirmActivate(user: User) {
    let action = user.active ? 'activate' : 'deactivate';
    let shortText = `${upperFirst(action)} user`;
    let message = {
      message: `Are you sure you want to <b>${action}</b> user ${user.username}?`,
      title: shortText,
      confirmText: shortText
    };
    this.$oruga.modal.open({
      parent: this,
      component: DialogModal,
      props: {
        ...message,
        canCancel: true,
        variant: 'warning',
        hasIcon: true,
        onConfirm: () => this.toggleActive(user),
        onCancel: () => (user.active = !user.active)
      },
      trapFocus: true
    });
  }

  checkedRowEnabledActions(users: User[]) {
    const actions = [];
    switch (users.length) {
      case 0:
        break;
      case 1:
        actions.push(MovActionType.INVITE, MovActionType.EDIT, MovActionType.DELETE);
        break;
      default:
        actions.push(MovActionType.INVITE, MovActionType.DELETE);
        break;
    }
    return actions;
  }

  setLoading(payload: { value: boolean; msg?: string }) {
    generalStore.setLoading(payload);
  }
}
