










































import { Component, Prop, Vue } from 'vue-property-decorator';
import { IconMapping } from '@deck.gl/layers/icon-layer/icon-layer';

@Component({ name: 'IconDropdownSelector' })
export default class IconDropdownSelector extends Vue {
  @Prop({ type: String, default: null }) readonly value!: string | null;
  @Prop({ type: String, default: 'fas' }) readonly pack!: string;
  @Prop({ type: Object, default: () => Object() }) readonly iconOptions!: IconMapping;
  @Prop({ type: Boolean, default: false }) readonly allowEmpty!: boolean;
  @Prop({ type: String, default: () => '' }) readonly placeholder!: string;
  @Prop({ type: Boolean, default: false }) readonly buttons!: boolean;
  @Prop({ type: String, default: '' }) readonly label!: string;

  get icons(): string[] {
    return Object.keys(this.iconOptions);
  }
  isSelected(shape: string | null) {
    return shape === this.value;
  }
}
