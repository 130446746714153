





















import { CameraOptions, PopupContent } from '@movici-flow-common/types';
import { Component, Prop, Vue } from 'vue-property-decorator';
import DynamicDataView from './DynamicDataView.vue';
import DataViewContent from './DataViewContent.vue';
import { flowUIStore } from '@movici-flow-common/store/store-accessor';

@Component({
  name: 'MapEntityPopup',
  components: {
    DynamicDataView,
    DataViewContent
  }
})
export default class MapEntityPopup extends Vue {
  @Prop({ type: Object, default: null }) readonly value!: PopupContent;
  @Prop({ type: Object, default: null }) readonly map!: mapboxgl.Map;
  @Prop({ type: Object }) readonly viewState?: CameraOptions;
  @Prop({ type: Number, default: null }) readonly timestamp!: number | null;

  get popupBorderPadding() {
    return {
      left: flowUIStore.collapse ? 0 : 300
    };
  }
}
