import { render, staticRenderFns } from "./SpeedSelector.vue?vue&type=template&id=461b0ffe&scoped=true&"
import script from "./SpeedSelector.vue?vue&type=script&lang=js&"
export * from "./SpeedSelector.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "461b0ffe",
  null
  
)

export default component.exports