








































import { Component, Vue } from 'vue-property-decorator';
import UserRoleDialog from './UserRoleDialog.vue';
import { userStore } from '@/store/store-accessor';
import { RoleBinding } from '@/types';
import DialogModal from '@/components/global-alt/DialogModal.vue';

@Component({
  name: 'UserRoles',
  components: {
    UserRoleDialog
  },
  filters: {
    scopeType: (value: number | string) => {
      if (!value) return '';
      value = value.toString();
      return value.split(':')[0];
    },
    scopeValue: (value: number | string) => {
      if (!value) return '';
      value = value.toString();
      return value.split(':')[1];
    }
  }
})
export default class UserRoles extends Vue {
  isAddUserRoleActive = false;

  get user() {
    return userStore.user;
  }

  confirmDelete(deleteItems: RoleBinding[]) {
    const deleteSingle = {
        message: 'Are you sure you want to <b>delete</b> this role?',
        title: 'Delete role',
        confirmText: 'Delete role'
      },
      deleteMultiple = {
        message: 'Are you sure you want to <b>delete</b> these roles?',
        title: 'Delete role(s)',
        confirmText: 'Delete role(s)'
      },
      message = deleteItems.length > 1 ? deleteMultiple : deleteSingle;

    this.$oruga.modal.open({
      parent: this,
      component: DialogModal,
      props: {
        canCancel: true,
        ...message,
        variant: 'danger',
        hasIcon: true,
        onConfirm: () => this.deleteItems(deleteItems)
      },
      trapFocus: true
    });
  }

  deleteItems(itemList: RoleBinding[]) {
    const deleteRequests = itemList.map(element => {
      if (this.user?.user_uuid) {
        return userStore.deleteUserRole({
          uuid: this.user.user_uuid,
          userRole: element
        });
      }
    });

    Promise.all(deleteRequests).then(() => {
      this.getThisUser();
    });
  }

  async addUserRole(role: RoleBinding) {
    if (this.user?.user_uuid) {
      await userStore.addUserRole({
        uuid: this.user.user_uuid,
        userRole: role
      });
      await this.getThisUser();
    }
  }

  getThisUser() {
    if (this.user?.user_uuid) {
      userStore.getUser(this.user.user_uuid);
    }
  }
}
