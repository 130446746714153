








































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import {
  AdvColorMapping,
  AdvancedColorSettings,
  FlowVisualizerType,
  RenderOrderType
} from '@movici-flow-common/types';
import { RGBAColor } from '@deck.gl/core';
import RenderOrder from './RenderOrder.vue';
import AdvColorList from './AdvColorList.vue';
import {
  DEFAULT_SPECIAL_COLOR_TRIPLE,
  DEFAULT_UNDEFINED_COLOR_TRIPLE,
  DEFAULT_POLYGON_FILL_OPACITY
} from '@movici-flow-common/utils/colorUtils';
import { MoviciColors } from '@movici-flow-common/visualizers/maps/colorMaps';

@Component({
  name: 'ColorAdvSettingsConfigurator',
  components: {
    RenderOrder,
    AdvColorList
  }
})
export default class ColorAdvSettingsConfigurator extends Vue {
  @Prop({ type: Object, default: null }) readonly value!: AdvancedColorSettings | null;
  @Prop({ type: String, default: FlowVisualizerType.POINTS })
  readonly geometry!: FlowVisualizerType;
  @Prop({ type: String, default: 'buckets' }) readonly fillType!: 'buckets' | 'gradient';
  @Prop({ type: String, default: null }) readonly clauseType!: 'static' | 'byValue' | null;
  fillOpacity = DEFAULT_POLYGON_FILL_OPACITY;
  renderOrder: RenderOrderType = RenderOrderType.DISABLED;
  isOpen = false;
  colorPickerPresets = Object.values(MoviciColors);
  specialColor = DEFAULT_SPECIAL_COLOR_TRIPLE;
  undefinedColor = DEFAULT_UNDEFINED_COLOR_TRIPLE;

  get hasRenderOrder() {
    return this.fillType === 'buckets';
  }

  get hasFillOpacity() {
    return this.geometry === 'polygons';
  }

  get advColors(): AdvColorMapping {
    return [
      [-9999, this.calculatedValue.specialColor],
      ['null', this.calculatedValue.undefinedColor]
    ];
  }

  get calculatedValue() {
    const rv: AdvancedColorSettings &
      Required<Pick<AdvancedColorSettings, 'specialColor' | 'undefinedColor'>> = {
      specialColor: this.specialColor,
      undefinedColor: this.undefinedColor
    };
    if (this.hasRenderOrder) {
      rv.renderOrder = this.renderOrder;
    }
    if (this.hasFillOpacity) {
      rv.fillOpacity = Number(this.fillOpacity);
    }
    return rv;
  }

  updateColor({ id, newValue }: { id: number; newValue: RGBAColor }) {
    if (id === 0) {
      this.specialColor = newValue;
    } else {
      this.undefinedColor = newValue;
    }
  }

  @Watch('calculatedValue')
  emitAdvancedSettings() {
    this.$emit('input', this.calculatedValue);
  }

  @Watch('value', { immediate: true })
  onValueChange(value: AdvancedColorSettings | null) {
    this.renderOrder = value?.renderOrder ?? RenderOrderType.DISABLED;
    this.fillOpacity = value?.fillOpacity ?? DEFAULT_POLYGON_FILL_OPACITY;
    this.specialColor = value?.specialColor ?? DEFAULT_SPECIAL_COLOR_TRIPLE;
    this.undefinedColor = value?.undefinedColor ?? DEFAULT_UNDEFINED_COLOR_TRIPLE;
  }

  mounted() {
    if (this.value) {
      this.isOpen = true;
    }
    this.emitAdvancedSettings();
  }
}
