














import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator';
import { Sketch } from 'vue-color';
import { RGBAColor } from '@movici-flow-common/types';
import { DEFAULT_UNDEFINED_COLOR_TRIPLE } from '@movici-flow-common/utils/colorUtils';

interface RGBAObject {
  rgba: { r: number; b: number; g: number; a: number };
}

@Component({
  name: 'FlowColorPicker',
  components: {
    Sketch
  }
})
export default class FlowColorPicker extends Vue {
  @Prop({ type: Array, default: () => DEFAULT_UNDEFINED_COLOR_TRIPLE }) readonly value!: RGBAColor;
  @Prop({ type: Array, default: () => [] }) readonly presets!: (RGBAColor | string)[];
  @Prop({ type: Boolean, default: false }) readonly open!: boolean;
  @Prop({ type: Number, default: 0 }) readonly translateX!: number;
  @Prop({ type: Number, default: 0 }) readonly translateY!: number;
  @Prop({ type: String, default: 'top' }) readonly position!: string;
  @Ref('colorPickerContainer') readonly colorPickerContainer!: HTMLElement;

  isOpen = false;
  coolDown = false;

  get preparedColorPresets(): (string | RGBAObject)[] {
    return this.presets.map(color => (Array.isArray(color) ? colorTripleToRGBA(color) : color));
  }

  // looking into positioning this in diferent ways
  get style() {
    let transform = {
      top: 'translateY(-100%) translateY(-10px)',
      right: 'translateY(-50%)',
      'top-right': 'translateY(-48px) translateX(12px)'
    }[this.position];

    return {
      transform: transform + `translateY(${this.translateY}px) translateX(${this.translateX}px)`
    };
  }

  get rgba(): RGBAObject {
    return colorTripleToRGBA(this.value);
  }

  set rgba({ rgba }: RGBAObject) {
    const color = [rgba.r, rgba.g, rgba.b];
    if (rgba.a < 1) {
      color[3] = Math.floor(rgba.a * 255);
    }
    this.$emit('input', color);
  }

  @Watch('open', { immediate: true })
  handleOpen(open: boolean) {
    /* We need to handle the blur event correctly. This means that we first have to focus the
    colorPickerContainer when it appears, so that clicking outside it's area, actually triggers
    the blur event. Because of Vue reasons, this needs to be done in a $nextTick callback.
    Now that we're properly detecting the blur event, we need to deal with a false positive, namely
    when we use an (outside this component) toggle button to close it. Clicking the toggle button
    first fires the blur event (thereby closing this component) and then it is activated again in
    the toggle action, which is undesirable. We therefore introduce a cooldown. If the component
    has been closed, it cannot be opened again within x(=100) ms.
    */
    const COOLDOWN_MS = 100;
    if (open && this.coolDown) {
      this.$emit('close');
    }

    this.isOpen = open;

    if (open) {
      this.$nextTick(() => {
        this.colorPickerContainer?.focus();
      });
    } else if (!this.coolDown) {
      this.coolDown = true;
      setTimeout(() => {
        this.coolDown = false;
      }, COOLDOWN_MS);
    }
  }
  handleFocusOut(event: FocusEvent) {
    // when clicking outside the color picker we want to close it. We need to capture the focusout
    // and then emit the close event. However, there are multiple children inside the color picker
    // that may trigger the focusout event, so we need to check whether we've actually focused
    // outside the picker, or merely on a child.
    // Also, we choose to use the focusout event and not the blur event since the focusout event
    // bubbles up so that we can also capture it after we've focused on a child

    const currentTarget = event.currentTarget as
      | (EventTarget & {
          contains: (e: Element | null) => boolean;
        })
      | null;
    // Give browser time to focus the next element
    requestAnimationFrame(() => {
      // Check if the new focused element is a child of the original container
      if (!currentTarget?.contains(document.activeElement)) {
        this.$emit('close');
      }
    });
  }
}

function colorTripleToRGBA(color: RGBAColor): RGBAObject {
  return {
    rgba: {
      r: color[0],
      g: color[1],
      b: color[2],
      a: color[3] !== undefined ? color[3] / 255 : 1
    }
  };
}
