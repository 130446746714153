























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ComposableVisualizerInfo } from '@movici-flow-common/visualizers/VisualizerInfo';
import { ActionMenuItem } from '@movici-flow-common/types';
import StatusTracker from '@movici-flow-common/utils/StatusTracker';
import VisualizerSummary from './VisualizerSummary.vue';

@Component({
  name: 'VisualizerElement',
  components: {
    VisualizerSummary
  }
})
export default class VisualizerElement extends Vue {
  @Prop({ type: Object, default: null }) readonly value!: ComposableVisualizerInfo | null;
  @Prop({ type: Array, default: () => [] }) readonly headerButtons!: string[];
  @Prop({ type: Array, default: () => [] }) readonly actionButtons!: string[];
  @Prop({ type: String, default: 'is-bottom' }) readonly tooltipPosition!: string;
  @Prop({ type: Boolean, default: false }) readonly tooltipActive!: boolean;
  progress: number | null = null;
  isOpen = false;
  actions: ActionMenuItem[] = [
    {
      icon: 'edit',
      iconPack: 'far',
      label: 'Edit',
      event: 'edit'
    },
    {
      icon: 'file-download',
      iconPack: 'far',
      label: 'Export',
      event: 'export'
    },
    {
      icon: 'trash',
      iconPack: 'far',
      label: 'Delete',
      event: 'delete',
      variant: 'danger'
    },
    {
      icon: 'redo',
      iconPack: 'fas',
      label: 'Reload',
      event: 'reload',
      variant: 'danger'
    }
  ];

  get filteredActions() {
    return this.actions.filter(action => {
      return this.actionButtons.includes(action.event);
    });
  }

  get showLoader() {
    return !this.errors.length && typeof this.progress === 'number';
  }

  get errors() {
    return this.value ? Object.values(this.value.errors) : [];
  }

  get errorColor() {
    // should we classify error by colors?
    return 'is-warning';
  }

  toggleSummary() {
    this.isOpen = !this.isOpen;
  }

  toggleVisibility(force?: boolean) {
    if (this.value)
      this.$emit(
        'input',
        Object.assign(new ComposableVisualizerInfo(this.value), {
          visible: force ?? !this.value?.visible
        })
      );
  }

  showOnHeader(button: string) {
    return this.headerButtons.includes(button);
  }

  @Watch('value', { immediate: true })
  setupTracking() {
    if (this.value) {
      if (this.value.status) {
        this.value.status.onProgress = val => (this.progress = val);
      } else {
        this.value.status = new StatusTracker({
          tasks: {
            initData: 20,
            updates: 80
          },
          onProgress: val => (this.progress = val)
        });
      }
    }
  }
}
