













import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({ name: 'BooleanInputs' })
export default class BooleanInputs extends Vue {
  @Prop({ type: Array, required: true }) readonly value!: number[];
}
