














































import { Vue, Component, Prop } from 'vue-property-decorator';
import isEmpty from 'lodash/isEmpty';
import { currentUserStore } from '@/store/store-accessor';
import { SetAccountPasswordPayload } from '@/types';
import NewPassword from './NewPassword.vue';

@Component({ name: 'Activation', components: { NewPassword } })
class Activation extends Vue {
  @Prop({ type: String, required: true }) readonly token!: string;
  @Prop({ type: String, required: true }) readonly username!: string;
  @Prop({ type: String, required: true }) readonly firstname!: string;
  @Prop({ type: String, default: 'en' }) readonly lang!: string;
  password = '';
  agreement = '';
  errors: Record<string, string[]> = {};
  statusMessage: Record<string, string | null> = {
    logs: null,
    variant: null
  };
  validators: Record<string, Array<(value: string) => string | undefined>> = {
    agreement: [this.required]
  };
  regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,128}$/;

  get canSave() {
    return !!this.password && !!this.agreement;
  }

  async onActivate() {
    currentUserStore
      .doActivateAccount(this.getAccountActivationPayload())
      .then(() => {
        this.statusMessage = {
          logs: 'Account activated successfully!',
          variant: 'success'
        };
        this.goToLogin(4000);
      })
      .catch(() => {
        this.statusMessage = {
          logs: 'Your account could not be activated. Please contact support.',
          variant: 'danger'
        };
        this.goToLogin(3000);
      });
  }

  validate(field: string, value: string) {
    delete this.errors[field];

    const errors: string[] = [];
    this.validators[field].forEach(validator => {
      let error = validator(value);
      if (error) errors.push(error);
    });
    if (errors.length) {
      this.errors[field] = errors;
    }
  }

  hasErrors() {
    return !isEmpty(this.errors);
  }

  regexMatches(value: string) {
    if (!this.regex.exec(value)) return this.$t('messages.passwordComplexity');
  }

  required(value: string) {
    if (!value) return 'This is a required field';
  }

  getAccountActivationPayload(): SetAccountPasswordPayload {
    return {
      password: this.password,
      token: this.token
    };
  }

  goToLogin(timeout: number) {
    setTimeout(() => this.$router.push({ path: '/' }), timeout);
  }

  setPassword(password: string) {
    this.password = password;
  }

  mounted() {
    this.$i18n.locale = this.lang;
  }
}

export default Activation;
