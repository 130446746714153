







































































































import { Component, Mixins, Prop, Vue } from 'vue-property-decorator';
import BooleanInputs from './BooleanInputs.vue';
import ByValueNumberInputs from './ByValueNumberInputs.vue';
import EnumInputs from './EnumInputs.vue';
import { ActionMenuItem } from '@movici-flow-common/types';
import Draggable from 'vuedraggable';
import DraggableMixin from '@movici-flow-common/mixins/DraggableMixin';
import { EnumValueMappingHelper, MappingMode, ValueMappingHelper } from './ValueMappingHelper';

@Component({
  name: 'ByValueList',
  components: {
    BooleanInputs,
    ByValueNumberInputs,
    Draggable,
    EnumInputs
  }
})
export default class ByValueList<T> extends Mixins<DraggableMixin>(DraggableMixin) {
  @Prop({ type: Array, required: true }) readonly value!: [number, T][];
  @Prop({ required: true }) readonly component!: string | Vue;
  @Prop({ type: Object, default: () => Object() }) readonly props!: Record<string, unknown>;
  @Prop({ type: Object, required: true }) readonly mappingHelper!: ValueMappingHelper<T>;
  @Prop({ type: Number, default: 1 }) readonly maxValue!: number;
  @Prop({ type: Boolean, default: false }) readonly reversed!: boolean;
  @Prop({ type: String, default: '' }) readonly label!: string;

  get outputActions(): ActionMenuItem[] {
    return [
      {
        label: '' + this.$t('flow.visualization.invertOutput'),
        icon: 'sort',
        iconPack: 'far',
        event: 'invertOutput'
      }
    ];
  }
  get orderedValue() {
    return this.reversed ? this.value.slice().reverse() : this.value;
  }

  get output() {
    return this.orderedValue.map(val => val[1]);
  }

  get mappingValues() {
    return this.orderedValue.map(v => v[0]);
  }

  get enumLabels() {
    return (this.mappingHelper as EnumValueMappingHelper<T>).enumLabels;
  }

  get valueActions(): ActionMenuItem[] {
    return [
      {
        label: '' + this.$t('flow.visualization.resetValues'),
        icon: 'undo',
        iconPack: 'far',
        event: 'resetValues'
      },
      {
        label: '' + this.$t('flow.visualization.interpolateMinMax'),
        icon: 'sort',
        iconPack: 'far',
        event: 'interpolateMinMax',
        isDisabled: !this.isMode('number')
      }
    ];
  }

  get draggableOptions() {
    return {
      animation: 500,
      group: 'by-value-colors',
      disabled: false,
      ghostClass: 'ghost',
      handle: '.grip'
    };
  }

  get valueHeader() {
    let kind: string;
    if (this.isMode('enum')) {
      kind = 'valueEnum';
    } else if (this.isMode('buckets')) {
      kind = 'valueRange';
    } else {
      kind = 'value';
    }
    return (this.$t('flow.visualization.byValueConfig') as unknown as Record<string, string>)[kind];
  }
  emitOriginalOrder(values: [number, T][]) {
    this.$emit('input', this.reversed ? values.slice().reverse() : values);
  }

  removeRow(idx: number) {
    const data = [...this.orderedValue];
    data.splice(idx, 1);
    this.emitOriginalOrder(data);
  }

  invertOutput() {
    this.emitOriginalOrder(
      this.orderedValue.map((val, i) => {
        return [val[0], this.value[i][1]];
      })
    );
  }

  updateDraggable(event: { moved: { oldIndex: number; newIndex: number } }) {
    this.emitOriginalOrder(
      this.move(event.moved.oldIndex, event.moved.newIndex, this.output).map((d, idx) => {
        return [this.mappingValues[idx], d];
      })
    );
  }

  isMode(query: MappingMode) {
    return this.mappingHelper!.modeFlags.includes(query);
  }

  isMaxIndex(index: number) {
    return this.reversed ? index === 0 : index === this.orderedValue.length - 1;
  }

  bucketEndIndex(index: number) {
    return this.reversed ? index - 1 : index + 1;
  }

  updateOutput(idx: number, newValue: T) {
    this.emitOriginalOrder(
      this.orderedValue.map((item, arrayIdx) => {
        return arrayIdx === idx ? [item[0], newValue] : item;
      })
    );
  }

  updateMappingValue(idx: number, newValue: number) {
    this.emitOriginalOrder(
      this.orderedValue.map((item, arrayIdx) => {
        return arrayIdx === idx ? [newValue, item[1]] : item;
      })
    );
  }
}
