


























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Deck as DeckGL, Layer } from '@deck.gl/core';
import { DeckProps, PickInfo } from '@deck.gl/core/lib/deck';
import {
  CameraOptions,
  CursorCallback,
  DeckMouseEvent,
  Nullable,
  DeckEvent,
  DeckEventPayload,
  DeckEventCallback
} from '@movici-flow-common/types';
import { ControllerOptions } from '@deck.gl/core/controllers/controller';
import defaults from './defaults';
import { viewport, BoundingBox } from '@mapbox/geo-viewport';
import { failMessage } from '@movici-flow-common/utils/snackbar';

const DEFAULT_VIEWSTATE = defaults.viewState();

function getViewportFromBBOX(
  bounding_box: BoundingBox,
  dimensions: [number, number],
  ratio: number
) {
  const { center, zoom } = viewport(
      bounding_box,
      // we set the ratio 1/3 as we want the viewport to occupy 1/3 of the map screen
      dimensions.map(side => side * ratio) as [number, number],
      undefined, // min zoom, default 0
      undefined, // max zoom, default 20
      undefined, // tileSize, default 256
      true // use float on zoom
    ),
    [longitude, latitude] = center;

  return { longitude, latitude, zoom };
}

function getCanvasDimensions(map: mapboxgl.Map): [number, number] {
  const dimensions = map.getCanvas();
  return [dimensions.width, dimensions.height];
}

type DeckSlots = 'control-zero' | 'control-left' | 'control-right' | 'control-bottom';

@Component({ name: 'Deck' })
export default class Deck extends Vue {
  @Prop({ type: Object, default: null }) readonly value!: Nullable<CameraOptions>;
  @Prop({ type: String, default: 'mapbox://styles/mapbox/light-v10' }) readonly basemap!: string;
  @Prop({ type: String, default: process.env.VUE_APP_MAPBOX_TOKEN }) readonly accessToken!: string;
  @Prop({ type: Object }) readonly controller!: ControllerOptions;
  @Prop({ type: Array, default: () => [] }) readonly layers!: Layer<unknown>[];
  map: mapboxgl.Map | null = null;
  deck: DeckGL | null = null;
  eventListeners: Record<DeckEvent, Map<string, DeckEventCallback>> = {
    click: new Map<string, DeckEventCallback>(),

    error: new Map<string, DeckEventCallback>()
  };
  loaded = false;
  contextPickInfo: PickInfo<unknown> | null = null;
  getCursor: CursorCallback | null = null;

  get showMap() {
    return this.basemap.startsWith('mapbox://');
  }
  get backgroundColorStyle() {
    return this.basemap.startsWith('color://')
      ? { 'background-color': this.basemap.split('//')[1] }
      : {};
  }
  get slotProps() {
    return {
      map: this.map,
      on: this.on,
      contextPickInfo: this.contextPickInfo,
      resetContextPickInfo: this.resetContextPickInfo,
      onViewstateChange: this.updateViewState,
      setCursorCallback: this.setCursorCallback,
      zoomToBBox: this.zoomToBBox
    };
  }

  /**
   * If there are children elements inside the scopedSlots this returns true.
   *
   * @param control one of the allowed scopedSlots (DeckSlots)
   * @returns boolean
   */
  hasMapControl(control: DeckSlots) {
    return !!this.$scopedSlots[control]?.({});
  }

  on(event: DeckEvent, callbacks: Record<string, DeckEventCallback>) {
    Object.entries(callbacks).forEach(([key, callback]) => {
      this.eventListeners[event].set(key, callback);
    });
  }

  invokeCallbacks(event: DeckEvent, payload: DeckEventPayload) {
    for (const cb of this.eventListeners[event].values() ?? []) {
      cb(payload);
    }
  }

  setCursorCallback(cb: CursorCallback) {
    this.getCursor = cb || (() => null);
  }

  zoomToBBox(bounding_box: BoundingBox, ratio = 1 / 3) {
    try {
      if (this.map) {
        this.updateViewState({
          ...this.value,
          ...getViewportFromBBOX(bounding_box, getCanvasDimensions(this.map), ratio),
          transitionDuration: 300
        } as CameraOptions);
      }
    } catch (error) {
      failMessage('Error when centering to BBOX');
      console.error(error);
    }
  }

  @Watch('basemap')
  setStyle() {
    this.map?.setStyle(this.showMap ? this.basemap : (null as unknown as string));
  }

  @Watch('layers')
  renderDeck() {
    this.deck?.setProps({ layers: this.layers });
  }

  @Watch('value')
  onValue(val: CameraOptions) {
    this.updateViewState(val);
  }

  updateViewState(viewState: CameraOptions) {
    this.deck?.setProps({ viewState });
    this.map?.jumpTo({
      center: [viewState.longitude, viewState.latitude],
      zoom: viewState.zoom,
      bearing: viewState.bearing,
      pitch: viewState.pitch
    });

    this.$emit('input', viewState);
  }

  resetContextPickInfo() {
    this.contextPickInfo = null;
  }

  initDeck(val: CameraOptions) {
    return new DeckGL({
      canvas: 'deckgl-overlay',
      width: '100%',
      height: '100%',
      initialViewState: val,

      onClick: (info: PickInfo<unknown>, ev?: DeckMouseEvent) => {
        this.resetContextPickInfo();

        if (ev?.leftButton) {
          this.invokeCallbacks('click', { pickInfo: info, ev });
        } else if (ev?.rightButton) {
          this.contextPickInfo = info;
        }
        this.invokeCallbacks('click', { pickInfo: info, ev });
      },
      onError: (error: Error, layer?: Layer<unknown>) => {
        console.error(error);
        this.invokeCallbacks('error', { error, layer });
      },
      getCursor: ({ isHovering, isDragging }: { isHovering: boolean; isDragging: boolean }) => {
        const cursorOverride = this.getCursor?.({ isHovering, isDragging });
        if (cursorOverride) return cursorOverride;
        if (isHovering) return 'pointer';
        if (isDragging) return 'grabbing';
        return 'grab';
      },
      controller: this.controller ?? true,
      layers: [],
      onViewStateChange: ({ viewState }: { viewState: CameraOptions }) => {
        this.updateViewState(viewState);
      }
    } as unknown as DeckProps);
  }

  initMapBox(viewState: CameraOptions) {
    return new mapboxgl.Map({
      center: [viewState.longitude, viewState.latitude],
      zoom: viewState.zoom,
      bearing: viewState.bearing,
      pitch: viewState.pitch,
      container: 'map',
      accessToken: this.accessToken,
      maxPitch: 65,
      style: this.basemap,
      attributionControl: false,
      interactive: false
    });
  }

  mounted() {
    this.map = this.initMapBox(this.value || DEFAULT_VIEWSTATE);
    this.map.on('load', () => {
      this.map?.resize();
      this.deck = this.initDeck(this.value || DEFAULT_VIEWSTATE);
      this.loaded = true;
    });
  }

  beforeDestroy() {
    // this was triggering errors
    // need to destroy children, so that layer removal is done before the map itself is removed
    this.$children.forEach(child => child.$destroy());
    this.map?.remove();
    this.deck?.canvas.remove();
  }
}
