












import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({ name: 'WidgetContainer' })
export default class WidgetContainer extends Vue {
  @Prop({ type: Boolean, default: false }) readonly collapsable!: boolean;
  @Prop({ type: Boolean, default: false }) readonly startCollapsed!: boolean;
  @Prop({ type: Boolean, default: null }) readonly focused!: boolean;
  collapsed_: null | boolean = null;

  get collapsed() {
    return this.collapsed_ ?? this.startCollapsed;
  }
  get open() {
    return !this.collapsed;
  }

  set open(val: boolean) {
    this.collapsed_ = !val;
  }
}
