




























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Role } from '@/types';
import { authorizationStore, organisationStore, userStore } from '@/store/store-accessor';
import UserRoles from './UserRoles.vue';
import PasswordDialog from './PasswordDialog.vue';
import pick from 'lodash/pick';

@Component({
  name: 'UserDetail',
  components: {
    UserRoles,
    PasswordDialog
  }
})
export default class UserDetail extends Vue {
  @Prop({ type: String }) readonly uuid?: string;
  checkedRoles: Role[] = [];
  isPasswordChangeActive = false;
  reset = false;

  get user() {
    return userStore.user;
  }

  get scopes() {
    return authorizationStore.rawScopes;
  }

  get organisations() {
    return organisationStore.organisations;
  }

  get addMode() {
    return !this.uuid;
  }

  get editMode() {
    return !!this.uuid;
  }

  get title() {
    return this.addMode ? this.$t('user.addNew') : this.$t('user.edit');
  }

  onSave() {
    if (this.addMode) {
      this.addUser();
    } else {
      this.editUser();
    }
  }

  async editUser() {
    await userStore.updateUser(this.getEditPayload());
    this.$flow.snackbar.successMessage('User successfully updated');
    this.initilialize();
  }

  async addUser() {
    const resp = await userStore.addUser(this.getAddPayload());

    if (resp?.user_uuid) {
      this.$flow.snackbar.successMessage(`User ${this.user?.username} successfully created`);
      this.goToEdit(resp.user_uuid);
    }
  }

  goBack() {
    this.$router.push({ name: 'Users' });
  }

  async getThisUser(uuid: string) {
    await userStore.getUser(uuid);
  }

  async emptyUser() {
    await userStore.clearUser();
  }

  async getAllOrganisations() {
    await organisationStore.getOrganisations();
  }

  getEditPayload() {
    return {
      user_uuid: this.uuid,
      ...pick(this.user, [
        'organisation_uuid',
        'username',
        'password',
        'firstname',
        'middlename',
        'lastname',
        'active',
        'reset'
      ])
    };
  }

  getAddPayload() {
    return {
      ...pick(this.user, [
        'organisation_uuid',
        'username',
        'firstname',
        'middlename',
        'lastname',
        'active'
      ])
    };
  }

  goToEdit(uuid: string) {
    this.$router.push({
      name: 'UserEdit',
      params: { uuid: uuid }
    });
  }

  async updatePassword(password: string) {
    if (this.uuid) {
      const resp = await userStore.updatePassword({
        uuid: this.uuid,
        password
      });

      if (resp) {
        this.$flow.snackbar.successMessage('Password successfully updated');
        this.initilialize();
      }
    }
  }

  resetCooldown() {
    this.editUser();
  }

  @Watch('uuid')
  initilialize() {
    this.getAllOrganisations();
    if (this.editMode && this.uuid) {
      this.getThisUser(this.uuid);
    } else {
      this.emptyUser();
    }
  }

  mounted() {
    this.initilialize();
  }
}
