import { Action, Module, VuexModule } from 'vuex-module-decorators';
import { UUID, View } from '@movici-flow-common/types';
import { AddView, DeleteView, GetView, GetViews, UpdateView } from '@/api/requests';
import { apiStore } from '../store-accessor';

@Module({
  name: 'view',
  namespaced: true
})
class ViewStore extends VuexModule {
  get client() {
    return apiStore.client;
  }

  @Action({ rawError: true })
  async getViews(scenarioUUID: UUID): Promise<View[]> {
    return (await this.client.request(new GetViews(scenarioUUID))) ?? [];
  }

  @Action({ rawError: true })
  async getView(viewUUID: UUID) {
    return await this.client.request(new GetView(viewUUID));
  }

  @Action({ rawError: true })
  async createView({ scenarioUUID, view }: { scenarioUUID: UUID; view: View }) {
    return await this.client.request(new AddView(scenarioUUID, view));
  }

  @Action({ rawError: true })
  async updateView({ viewUUID, view }: { viewUUID: UUID; view: View }) {
    return await this.client.request(new UpdateView(viewUUID, view));
  }

  @Action({ rawError: true })
  async deleteView(viewUUID: UUID) {
    return await this.client.request(new DeleteView(viewUUID));
  }
}
export default ViewStore;
