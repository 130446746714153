























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { modelTypeStore } from '@/store/store-accessor';
import { ModelType } from '@/types';
import DialogModal from '@/components/global-alt/DialogModal.vue';

@Component({ name: 'ModelTypeDetail' })
export default class ModelTypeDetail extends Vue {
  @Prop({ type: String }) readonly uuid?: string;
  errors: string[] = [];
  formattedRawData = '';
  initialRawData = '';
  minRows = 20;

  get modelType() {
    return modelTypeStore.currentModelType;
  }

  get numOfRows() {
    return Math.max(this.minRows, this.formattedRawData.split(/\r\n|\r|\n/).length);
  }
  get hasChanges() {
    return this.formattedRawData !== this.initialRawData;
  }
  get addMode() {
    return !this.uuid;
  }
  get editMode() {
    return !!this.uuid;
  }
  get title() {
    return this.addMode ? this.$t('model_type.addNew') : this.$t('model_type.edit');
  }

  mounted() {
    this.initializeThisModelType().then(() => {});
    this.initializeConfigFromStore();
  }

  async getThisModelType(uuid: string) {
    await modelTypeStore.getModelType(uuid);
  }

  async initializeThisModelType() {
    if (this.editMode && this.uuid) {
      this.getThisModelType(this.uuid);
    } else {
      modelTypeStore.setCurrentModelType({
        name: '',
        strict_validation: true,
        dataset_categories: [],
        entity_categories: [],
        schema: {}
      });
    }
  }

  async updateModelType() {
    await modelTypeStore.updateModelType();
    this.$flow.snackbar.successMessage('Model type successfully updated');
  }

  async addModelType() {
    await modelTypeStore.addModelType();
    this.$flow.snackbar.successMessage('Model type successfully created');
  }

  goBack() {
    this.$router.push({ name: 'ModelTypes' });
  }

  addErrors(errors: string[]) {
    if (Array.isArray(errors)) {
      this.errors = errors;
    } else if (typeof errors === 'string') {
      this.errors = [errors];
    } else {
      console.log('invalid error', errors);
    }
  }

  isJsonString(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  initializeConfigFromStore() {
    this.formattedRawData = JSON.stringify(this.modelType, null, 2);
    this.initialRawData = this.formattedRawData;
  }

  async validateAndStoreForSave() {
    if (!this.isJsonString(this.formattedRawData)) {
      this.addErrors(['The config contains invalid JSON']);
      return;
    }

    modelTypeStore.setCurrentModelType(JSON.parse(this.formattedRawData) as ModelType);
    this.saveAndReturn();
  }

  saveAndReturn() {
    let action = this.editMode ? this.updateModelType() : this.addModelType();
    action.then(() => {
      this.goBack();
    });
  }

  onCancel() {
    if (this.hasChanges) {
      this.confirmCancel();
    } else {
      this.goBack();
    }
  }

  confirmCancel() {
    this.$oruga.modal.open({
      parent: this,
      component: DialogModal,
      props: {
        canCancel: true,
        confirmText: 'Discard Changes',
        message: 'You have unsaved changes, do you want to discard them?',
        variant: 'warning',
        hasIcon: true,
        onConfirm: () => this.goBack()
      },
      trapFocus: true
    });
  }

  @Watch('modelType')
  reinitialize() {
    this.initializeConfigFromStore();
  }
}
