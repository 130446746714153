





























































import ResourceList from '@/components/common/ResourceList.vue';
import ResourceListConsumer from '@/components/common/ResourceListConsumer';
import DialogModal from '@/components/global-alt/DialogModal.vue';
import { authorizationStore, generalStore } from '@/store/store-accessor';
import { Scope } from '@/types';
import { MovActionType } from '@movici-flow-common/types';
import { Component, Mixins } from 'vue-property-decorator';

@Component({
  name: 'Scopes',
  filters: {
    scopeType: (value: string | number) => {
      return value ? value.toString().split(':')[0] : '';
    },
    scopeName: (value: string | number) => {
      return value ? value.toString().split(':')[1] : '';
    }
  },
  components: {
    ResourceList
  }
})
export default class Scopes extends Mixins<ResourceListConsumer<Scope>>(ResourceListConsumer) {
  isAddScopeActive = false;
  resourceTerm = 'scope';
  defaultSort = 'scope_name';
  filterKeys = ['scope_name'];

  async getAll() {
    return await authorizationStore.getScopes();
  }

  getDeleteItemRequests(itemList: Scope[]) {
    return itemList.map(element => authorizationStore.deleteScope(element));
  }

  checkedRowEnabledActions(scopes: Scope[]) {
    return !scopes.length ? [] : [MovActionType.DELETE];
  }

  onEdit() {
    this.$oruga.modal.open({
      parent: this,
      component: DialogModal,
      props: {
        message: `Add a new scope`,
        type: 'prompt',
        inputAttrs: {
          type: 'text',
          placeholder: '<scope-type>:<scope-name>'
        },
        canCancel: true,
        hasIcon: true,
        onConfirm: (scope_name: string) => this.addScope({ scope_name })
      },
      trapFocus: true
    });
  }

  async addScope(scope: Partial<Scope>) {
    const resp = await authorizationStore.addScope(scope);
    if (resp) {
      this.$flow.snackbar.successMessage('Scope successfully added');
      this.resetList();
    }
  }

  setLoading(payload: { value: boolean; msg?: string }) {
    generalStore.setLoading(payload);
  }
}
