import { render, staticRenderFns } from "./LegendLabelsConfigurator.vue?vue&type=template&id=c603d7e2&scoped=true&"
import script from "./LegendLabelsConfigurator.vue?vue&type=script&lang=ts&"
export * from "./LegendLabelsConfigurator.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c603d7e2",
  null
  
)

export default component.exports