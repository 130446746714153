

























































































































import { RGBAColor } from '@deck.gl/core';
import ColorInput from '@movici-flow-common/components/widgets/ColorInput.vue';
import AttributeSelector from '@movici-flow-common/components/widgets/AttributeSelector.vue';
import { MoviciError } from '@movici-flow-common/errors';
import DraggableMixin from '@movici-flow-common/mixins/DraggableMixin';
import SummaryListing from '@movici-flow-common/mixins/SummaryListing';
import ValidationProvider from '@movici-flow-common/mixins/ValidationProvider';
import { flowStore } from '@movici-flow-common/store/store-accessor';
import { ButtonItem, PropertyType } from '@movici-flow-common/types';
import { excludeKeys } from '@movici-flow-common/utils';
import { colorTripleToHex, MoviciColors } from '@movici-flow-common/visualizers/maps/colorMaps';
import {
  ChartVisualizerInfo,
  ChartVisualizerItem
} from '@movici-flow-common/visualizers/VisualizerInfo';
import { cloneDeep, isEqual } from 'lodash';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import Draggable from 'vuedraggable';

@Component({
  name: 'AttributeChartConfig',
  components: {
    AttributeSelector,
    Draggable,
    ColorInput
  }
})
export default class AttributeChartConfig extends Mixins(
  ValidationProvider,
  SummaryListing,
  DraggableMixin
) {
  @Prop({ type: Object, default: null }) readonly value!: ChartVisualizerInfo | null;
  localValue: ChartVisualizerInfo | null = null;
  allowedPropertyTypes = ['INT', 'DOUBLE'];
  showColorPicker = false;
  colorPickerPresets = Object.values(MoviciColors);
  colorPickerIndex = -1;
  showDetails: boolean[] = [];
  currentScenarioUUID = '';

  get filteredEntityProps() {
    return this.properties.filter(this.filterProp);
  }

  get buttons(): ButtonItem[] {
    return [
      {
        variant: 'success',
        label: '' + this.$t('actions.save'),
        icon: 'save',
        iconPack: 'fas',
        event: 'saveChart',
        isDisabled: !this.hasPendingChanges
      },
      {
        label: '' + this.$t('actions.cancel'),
        icon: 'times',
        iconPack: 'fas',
        event: 'cancel'
      }
    ];
  }

  get disabledFields() {
    return ['dataset', 'entityGroup', 'attribute'];
  }

  get selectedAttributeName() {
    return this.localValue?.attribute ?? '';
  }

  get hasPendingChanges() {
    if (!this.value || !this.localValue) throw new MoviciError('Empty or invalid configuration');

    const value = excludeKeys(this.value, ['id', 'errors', 'status']),
      finalized = excludeKeys(this.localValue, ['id', 'errors', 'status']);
    return !isEqual(finalized, value);
  }

  getSuggestedColor(chart: ChartVisualizerInfo) {
    return this.colorPickerPresets[chart?.items.length ?? 0 % (this.colorPickerPresets.length - 1)];
  }

  colorTripleToHex = colorTripleToHex;

  isDisabled(field: string) {
    return this.disabledFields.includes(field);
  }

  updateColor(idx: number, color: RGBAColor) {
    if (this.localValue) {
      this.localValue.items = this.localValue.items.map((config, i) => {
        return idx == i ? new ChartVisualizerItem({ ...config, color }) : config;
      });
    }
  }

  getColor(colorPickerIndex: number) {
    return this.localValue?.items[colorPickerIndex].color ?? null;
  }

  filterProp(prop: PropertyType) {
    return this.allowedPropertyTypes.indexOf(prop.data_type) !== -1;
  }

  saveChart() {
    if (!this.localValue) {
      throw new MoviciError('Chart is invalid');
    }
    this.$emit('input', this.localValue);
  }

  openColorPicker(index: number) {
    if (this.showColorPicker && this.colorPickerIndex === index) {
      this.showColorPicker = false;
      this.colorPickerIndex = -1;
    } else {
      this.colorPickerIndex = index;
      this.showColorPicker = true;
    }
  }

  closeColorPicker() {
    this.colorPickerIndex = -1;
    this.showColorPicker = false;
  }

  updateDraggable(event: { moved: { oldIndex: number; newIndex: number } }) {
    if (this.localValue) {
      this.localValue.items = this.move<ChartVisualizerItem>(
        event.moved.oldIndex,
        event.moved.newIndex,
        this.localValue.items
      );
      this.showDetails = Array.from({ length: this.localValue.items.length }, () => false);
    }
  }

  removeItem(index: number) {
    this.localValue?.items.splice(index);
    this.showDetails.splice(index);
  }

  toggleDetails(idx: number) {
    this.showDetails = this.showDetails.map((val, i) => (idx === i ? !val : val));
  }

  setLocalValue(value: ChartVisualizerInfo | null) {
    this.localValue = cloneDeep(value);
    if (this.localValue) {
      const contentConfig = this.localValue.items[0];

      this.currentEntityName = contentConfig.entityGroup;
      this.currentDatasetName = contentConfig.datasetName;
      this.currentDataset = this.datasets.find(d => d.name === this.currentDatasetName) || null;
      this.showDetails = Array.from({ length: this.localValue.items.length }, () => false);
    }
  }

  @Watch('value', { immediate: true })
  afterValue() {
    this.setLocalValue(this.value);
  }

  async mounted() {
    this.datasets = (await flowStore.getDatasets()) ?? [];
  }
}
