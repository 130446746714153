































import { Component, Prop, Vue } from 'vue-property-decorator';
import { IconShapeLegendItem } from '@movici-flow-common/types';

@Component({ name: 'IconLegend' })
export default class IconLegend extends Vue {
  @Prop({ type: Object }) readonly value!: IconShapeLegendItem;

  get isShapeStatic() {
    return (this.value?.shape?.iconLegends.length ?? []) === 1;
  }

  get isIconStatic() {
    return (this.value?.icon?.iconLegends.length ?? []) === 1;
  }
}
