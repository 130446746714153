import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { RoleBinding, User } from '@/types';
import {
  AddUser,
  AddUserRole,
  DeleteUser,
  DeleteUserRole,
  GetUser,
  GetUsers,
  InviteUser,
  UpdatePassword,
  UpdateUser
} from '@/api/requests';
import { apiStore } from '../store-accessor';

@Module({
  name: 'user',
  namespaced: true
})
export default class UserStore extends VuexModule {
  users: User[] = [];
  user: User | null = null;

  get client() {
    return apiStore.client;
  }

  @Mutation
  SET_USERS(users: User[]) {
    this.users = users;
  }

  @Mutation
  SET_USER(user: User | null) {
    this.user = user;
  }

  @Action({ rawError: true })
  async getUsers() {
    const users = (await this.client.request(new GetUsers())) ?? [];
    this.SET_USERS(users);
    return users;
  }

  @Action({ rawError: true })
  async getUser(uuid: string) {
    this.SET_USER(await this.client.request(new GetUser(uuid)));
  }

  @Action({ rawError: true })
  async updateUser(user: Partial<User>) {
    const uuid = user.user_uuid;
    if (!uuid) {
      throw new Error('Cannot determine uuid for user');
    }
    return await this.client.request(new UpdateUser(uuid, user));
  }

  @Action({ rawError: true })
  async updatePassword(payload: { uuid: string; password: string }) {
    return await this.client.request(new UpdatePassword(payload.uuid, payload.password));
  }

  @Action({ rawError: true })
  async addUser(user: Partial<User>) {
    return await this.client.request(new AddUser(user));
  }

  @Action({ rawError: true })
  async deleteUser(user: Partial<User>) {
    if (!user.user_uuid) {
      throw new Error('Cannot determine uuid for user');
    }

    await this.client.request(new DeleteUser(user.user_uuid));
  }

  @Action({ rawError: true })
  async addUserRole(payload: { uuid: string; userRole: RoleBinding }) {
    return await this.client.request(new AddUserRole(payload.uuid, payload.userRole));
  }

  @Action({ rawError: true })
  async deleteUserRole(payload: { uuid: string; userRole: RoleBinding }) {
    return await this.client.request(new DeleteUserRole(payload.uuid, payload.userRole));
  }

  @Action({ rawError: true })
  clearUser() {
    this.SET_USER({} as User);
  }

  @Action({ rawError: true })
  async inviteUser(user: Partial<User>) {
    if (user.user_uuid) return await this.client.request(new InviteUser(user.user_uuid));
  }
}
