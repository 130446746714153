<template>
  <footer class="footer">
    <div class="content has-text-centered">
      <p>
        © {{ new Date().getFullYear() }} MoViCI {{ $t('misc.allRightsReserved') }}
        <span>
          <a class="has-padding-lr-7" @click="isPrivacyModalActive = true">Privacy</a> |
          <a class="has-padding-lr-7" @click="isTermsModalActive = true">{{
            $t('misc.termsOfUse')
          }}</a>
        </span>
      </p>
    </div>
    <o-modal
      content-class="modal-content"
      :active.sync="isPrivacyModalActive"
      width="640"
      scroll="clip"
    >
      <Privacy />
    </o-modal>
    <o-modal
      content-class="modal-content"
      :active.sync="isTermsModalActive"
      width="640"
      scroll="keep"
    >
      <Terms />
    </o-modal>
  </footer>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';

import Privacy from './Privacy.vue';
import Terms from './Terms.vue';

@Component({
  name: 'Footer',
  components: {
    Privacy,
    Terms
  }
})
export default class Footer extends Vue {
  isPrivacyModalActive = false;
  isTermsModalActive = false;
}
</script>

<style scoped></style>
