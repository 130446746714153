



















import { Component, Prop, Vue } from 'vue-property-decorator';
import upperFirst from 'lodash/upperFirst';
import { MovActionType } from '@movici-flow-common/types';

@Component({ name: 'MovAction' })
export default class MovAction extends Vue {
  @Prop({ type: String, default: '' }) readonly action!: MovActionType;
  @Prop({ type: String, default: '' }) readonly size!: string;
  @Prop({ type: Boolean, default: false }) readonly disabled!: boolean;
  disabledVariant = 'inactive';

  get currentAction() {
    const action = this.options[this.action];
    if (!action) {
      console.error('Invalid action: ', this.action);
    }
    return action;
  }

  get icon() {
    return this.currentAction.icon;
  }

  get variant() {
    return this.disabled ? this.disabledVariant : this.currentAction.variant;
  }

  get label() {
    return upperFirst(this.action);
  }

  get actionClass() {
    return `action-${this.action}`;
  }

  get options(): Record<MovActionType, { icon: string; variant: string }> {
    return {
      [MovActionType.ADD]: {
        icon: 'plus',
        variant: 'primary'
      },
      [MovActionType.VIEW]: {
        icon: 'eye',
        variant: 'primary'
      },
      [MovActionType.EDIT]: {
        icon: 'pen',
        variant: 'info'
      },
      [MovActionType.DELETE]: {
        icon: 'trash',
        variant: 'danger'
      },
      [MovActionType.DUPLICATE]: {
        icon: 'clone',
        variant: 'primary'
      },
      [MovActionType.DOWNLOAD]: {
        icon: 'download',
        variant: 'success'
      },
      [MovActionType.GENERATE]: {
        icon: 'play',
        variant: 'primary'
      },
      [MovActionType.PLAY]: {
        icon: 'play',
        variant: 'primary'
      },
      [MovActionType.RESET]: {
        icon: 'undo-alt',
        variant: 'danger'
      },
      [MovActionType.CANCEL]: {
        icon: 'stop',
        variant: 'danger'
      },
      [MovActionType.INVITE]: {
        icon: 'envelope',
        variant: 'dark'
      },
      [MovActionType.LOGS]: {
        icon: 'file-alt',
        variant: 'dark'
      }
    };
  }
}
