













































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { StaticSizeClause, SizeClause, FlowVisualizerType } from '@movici-flow-common/types';
import ValidationProvider from '@movici-flow-common/mixins/ValidationProvider';
import FormValidator, { isPositive } from '@movici-flow-common/utils/FormValidator';
import MinMaxPixels from './MinMaxPixels.vue';
@Component({
  name: 'SizeStaticConfigurator',
  components: {
    MinMaxPixels
  }
})
export default class SizeStaticConfigurator extends Mixins(ValidationProvider) {
  @Prop({ type: Object, default: () => new Object() }) readonly value!: SizeClause;
  @Prop({ type: String, default: FlowVisualizerType.POINTS })
  readonly geometry!: FlowVisualizerType;
  @Prop({ type: Object, required: true }) declare readonly validator: FormValidator;
  size = 2;
  units: 'pixels' | 'meters' = 'pixels';
  minPixels: number | null = null;
  maxPixels: number | null = null;

  get minMaxPixelsValidator() {
    return this.validator?.child('minMaxPixels');
  }

  get currentClause(): StaticSizeClause {
    const rv: StaticSizeClause = {
      size: this.size,
      units: this.units
    };

    if (this.minPixels !== null) {
      rv.minPixels = this.minPixels;
    }

    if (this.maxPixels !== null) {
      rv.maxPixels = this.maxPixels;
    }

    return rv;
  }

  get defaults() {
    switch (this.geometry) {
      case FlowVisualizerType.ICONS:
        return {
          size: 20,
          units: 'pixels',
          minPixels: 5,
          maxPixels: 100
        };
      default:
        return {
          size: 5,
          units: 'pixels',
          minPixels: 2,
          maxPixels: 5
        };
    }
  }

  @Watch('currentClause')
  prepareEmitClause() {
    if (this.currentClause) {
      this.$emit('input', { static: this.currentClause });
    }
  }

  setupValidator() {
    this.validator?.configure({
      validators: {
        size: () => isPositive(this.currentClause.size, 'Size')
      },
      onValidate: e => (this.errors = e)
    });
  }

  created() {
    const localValue: StaticSizeClause = Object.assign({}, this.defaults, this.value.static);

    this.size = localValue.size;
    this.units = localValue.units;
    this.minPixels = localValue.minPixels ?? null;
    this.maxPixels = localValue.maxPixels ?? null;

    this.setupValidator();
  }
}
