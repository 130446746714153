








import { generalStore } from '@/store/store-accessor';
import { Component, Vue } from 'vue-property-decorator';
import { flowUIStore } from '@movici-flow-common/store/store-accessor';

@Component({
  name: 'Settings'
})
class Settings extends Vue {
  get language(): string {
    return generalStore.language;
  }

  set language(newValue: string) {
    generalStore.setLanguage(newValue);
    flowUIStore.setLanguage(newValue);
  }
}

export default Settings;
