















































































import { SizeClause, PropertySummary } from '@movici-flow-common/types';
import { Component, Watch, Mixins } from 'vue-property-decorator';
import SizeStaticConfigurator from './SizeStaticConfigurator.vue';
import SizeByValueConfigurator from './SizeByValueConfigurator.vue';
import AttributeSelector from '@movici-flow-common/components/widgets/AttributeSelector.vue';
import isEqual from 'lodash/isEqual';
import ConfiguratorMixin from '../ConfiguratorMixin';
import { attributeValidator } from '../helpers';

@Component({
  name: 'SizeConfigurator',
  components: {
    AttributeSelector,
    SizeStaticConfigurator,
    SizeByValueConfigurator
  }
})
export default class SizeConfigurator extends Mixins<ConfiguratorMixin<SizeClause>>(
  ConfiguratorMixin
) {
  allowedPropertyTypes = ['BOOLEAN', 'INT', 'DOUBLE'];
  currentClause: SizeClause = {};
  clauseType: 'static' | 'byValue' | null = null;
  miscIsOpen = false;

  get staticValidator() {
    return this.validator.child('static');
  }

  get byValueValidator() {
    return this.validator.child('byValue');
  }

  get dashed() {
    return this.value.dashed;
  }

  updateSettings(updatedClause: Partial<SizeClause>) {
    this.currentClause = Object.assign({}, this.currentClause, updatedClause);
    this.emitClause();
  }

  updateAttribute(val: PropertySummary | null) {
    if (val) {
      this.ensureProp(val);
    }
  }

  emitClause() {
    const toEmit: SizeClause = this.currentClause.dashed
      ? { dashed: this.currentClause.dashed }
      : {};

    if (this.clauseType === 'static') {
      toEmit.static = this.currentClause.static;
    } else {
      toEmit.byValue = this.currentClause.byValue;
    }

    this.$emit('input', toEmit);
  }

  @Watch('entityProps')
  afterEntityProps(value: PropertySummary[], old?: PropertySummary[]) {
    if (!isEqual(value, old)) {
      delete this.currentClause.static;
      delete this.currentClause.byValue;
      this.clauseType = 'static';
    }
  }

  // Saves the old configuration if the user is changing between the kinds
  @Watch('clauseType')
  kindUpdated() {
    if (!this.clauseType) return;
    this.updateSettings({ [this.clauseType]: this.currentClause[this.clauseType] ?? {} });
    this.validator.touch('selectedAttribute');
  }

  @Watch('value', { immediate: true })
  updateLocal(value: SizeClause) {
    if (value) {
      this.clauseType = value.byValue ? 'byValue' : 'static';
      this.currentClause = Object.assign({}, this.currentClause, value);
    } else {
      this.clauseType = 'static';
    }
  }

  beforeDestroy() {
    if (this.validator) {
      this.validator.reset();
    }
  }

  setupAttributeValidator() {
    this.validator?.configure({
      validators: {
        selectedAttribute: attributeValidator(this, () => this.clauseType === 'byValue')
      },
      onValidate: e => {
        this.errors = e;
      }
    });
  }

  mounted() {
    this.setupAttributeValidator();
    if (this.value.byValue?.attribute) {
      this.pickSelectedEntityProp(this.value.byValue.attribute);
    }

    if (this.dashed != undefined) {
      this.miscIsOpen = true;
    }
  }
}
