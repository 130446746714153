import { Client } from '@movici-flow-common/api';
import { GetGeocodeResult, GetGeocodeResults, GetGeocodeSuggestions } from '@/api/requests';
import { GeocodeSearchQuery, GeocodeSuggestion } from '@movici-flow-common/types';
import GeocodeStore from '@movici-flow-common/store/GeocodeStore';
import { GeocodeService } from '@movici-flow-common/types/backend';

export default class RemoteGeocodeService implements GeocodeService {
  client: Client;
  store?: GeocodeStore;

  constructor(client: Client) {
    this.client = client;
  }

  async getSuggestions(query: GeocodeSearchQuery) {
    return (await this.client.request(new GetGeocodeSuggestions(query))) ?? null;
  }

  resolveSuggestion(suggestion: GeocodeSuggestion) {
    return this.client.request(new GetGeocodeResult(suggestion.result_uuid));
  }

  getResults(query: GeocodeSearchQuery) {
    return this.client.request(new GetGeocodeResults(query));
  }
}
