





















import { Component, Prop, Vue } from 'vue-property-decorator';
import cloneDeep from 'lodash/cloneDeep';
import { UUID, Project } from '@movici-flow-common/types';
import { projectStore } from '@/store/store-accessor';

@Component({ name: 'ProjectDetail' })
export default class ProjectDetail extends Vue {
  // "Project" as an UI term was renamed to "Workspace" in the general translation files,
  // for now we will stick with project in the files naming as this might not be the final term
  // eventually we can change all "project" mentions in code to the final term
  @Prop({ type: String }) readonly uuid?: UUID;
  project: Partial<Project> | null = null;

  get addMode() {
    return !this.uuid;
  }

  get editMode() {
    return !!this.uuid;
  }

  get title() {
    return this.addMode ? this.$t('project.addNew') : this.$t('project.edit');
  }

  async onSave() {
    if (this.project) {
      const action = this.addMode ? 'addProject' : 'updateProject';
      await projectStore[action](this.project as Project);
      this.goBack();
    }
  }

  getProjectByUUID(uuid: UUID) {
    return projectStore.getProjectByUUID(uuid);
  }

  goBack() {
    this.$router.push({ name: 'Projects' });
  }

  mounted() {
    if (this.editMode && this.uuid) {
      this.project = cloneDeep(this.getProjectByUUID(this.uuid)) || null;
    } else {
      this.project = {
        name: '',
        display_name: ''
      };
    }
  }
}
