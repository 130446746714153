import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { ProfileUpdate, SetAccountPasswordPayload, User } from '@/types';
import {
  ActivateAccount,
  ForgotPassword,
  GetProfile,
  ResetPassword,
  UpdateProfile
} from '@/api/requests';
import { apiStore } from '../store-accessor';

@Module({
  name: 'currentUser',
  namespaced: true
})
class CurrentUserStore extends VuexModule {
  user: User | null = null;

  get client() {
    return apiStore.client;
  }

  @Mutation
  SET_CURRENT_USER(user: User) {
    user.active = true;
    this.user = user;
  }

  get fullName() {
    if (!this.user) {
      return '';
    }
    const firstName = this.user.firstname ? this.user.firstname : '';
    const middleSection = this.user.middlename ? ' ' + this.user.middlename + ' ' : ' ';
    const lastName = this.user.lastname ? this.user.lastname : '';
    return firstName + middleSection + lastName;
  }

  @Action({ rawError: true })
  async doForgotPassword(payload: { username: string }) {
    return await this.client.request(new ForgotPassword(payload));
  }

  @Action({ rawError: true })
  async doResetPassword(payload: SetAccountPasswordPayload) {
    return await this.client.request(new ResetPassword(payload));
  }

  @Action({ rawError: true })
  async doActivateAccount(payload: SetAccountPasswordPayload) {
    return await this.client.request(new ActivateAccount(payload));
  }

  @Action({ rawError: true })
  async getCurrentUser() {
    const profile = await this.client.request(new GetProfile());

    if (profile) {
      this.SET_CURRENT_USER(profile);
    }

    return profile ?? null;
  }

  @Action({ rawError: true })
  async updateProfile(payload: ProfileUpdate) {
    return await this.client.request(new UpdateProfile(payload));
  }
}

export default CurrentUserStore;
