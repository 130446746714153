






















import { Component, Prop, Vue } from 'vue-property-decorator';
import { WidgetOpts } from '@/types';

@Component({ name: 'BaseWidget' })
export default class ImageWidget extends Vue {
  @Prop({ type: Object, default: null }) readonly opts!: WidgetOpts | null;
}
