

















































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { Dataset, ScenarioDataset, TimeOrientedSimulationInfo } from '@movici-flow-common/types';
import ValidationProvider from '@movici-flow-common/mixins/ValidationProvider';
import SummaryListing from '@movici-flow-common/mixins/SummaryListing';
import TimeSlider from '../map_widgets/TimeSlider.vue';
import { ComposableVisualizerInfo } from '@movici-flow-common/visualizers/VisualizerInfo';
import { flowStore } from '@movici-flow-common/store/store-accessor';
import FormValidator from '@movici-flow-common/utils/FormValidator';

@Component({
  name: 'ExportForm',
  components: { TimeSlider }
})
export default class ExportForm extends Mixins(SummaryListing, ValidationProvider) {
  @Prop({ type: Object, default: null }) readonly value!: ComposableVisualizerInfo | null;
  @Prop({ type: Object, required: true }) readonly timelineInfo!: TimeOrientedSimulationInfo;
  @Prop({ type: String, default: null }) readonly scenarioUuid!: string | null;
  @Prop({ type: Number, default: 0 }) readonly timestamp!: number;
  @Prop({ type: Object, required: true }) declare readonly validator: FormValidator;

  currentTimestamp = 0;
  exportFormats = ['csv'];
  selectedFormat = 'csv';

  get currentProject() {
    return flowStore.project;
  }

  get currentScenario() {
    return flowStore.scenario;
  }

  datasetDisplayName(dataset: Dataset) {
    if (dataset.display_name) {
      return `${dataset.display_name} (${dataset.name})`;
    } else {
      return dataset.name;
    }
  }

  @Watch('validator', { immediate: true })
  setupValidator() {
    this.validator?.configure({
      validators: {
        currentDatasetName: () => {
          if (!this.currentDatasetName) {
            return 'Please select a dataset';
          }
        },
        currentEntityName: () => {
          if (!this.currentEntityName) {
            return 'Please select an entity group';
          }
        }
      },
      onValidate: e => {
        this.errors = e;
      }
    });
  }

  @Watch('currentEntityName')
  @Watch('currentTimestamp')
  async emitConfig() {
    this.$emit('exportConfig', {
      datasetName: this.currentDatasetName ?? '',
      entityGroup: this.currentEntityName,
      timestamp: this.currentTimestamp
    } as { datasetName: string; entityGroup: string; timestamp: number });
  }

  getDataset(currentDatasetName?: string): Dataset | ScenarioDataset | null {
    return currentDatasetName ? this.datasetsByName[currentDatasetName] : null;
  }

  async getDatasets() {
    if (this.currentProject) this.datasets = (await flowStore.getDatasets()) || [];
  }

  @Watch('datasetName')
  async onDatasetChange(currentDatasetName: string) {
    await this.getSummary(currentDatasetName);
  }

  @Watch('value', { immediate: true })
  async setByValue(value: Partial<ComposableVisualizerInfo> | null) {
    await this.initialize();
    this.currentDatasetName = value?.datasetName ?? null;
    this.currentEntityName = value?.entityGroup ?? null;
  }

  resetTimestamp() {
    this.currentTimestamp = this.timestamp;
  }

  async initialize() {
    this.resetTimestamp();
    await this.getDatasets();
    await this.getSummary(this.currentDatasetName);
  }
}
