
































































import { Component, Mixins, Watch } from 'vue-property-decorator';
import { Project, View, UUID, ShortScenario, MovActionType } from '@movici-flow-common/types';
import cloneDeep from 'lodash/cloneDeep';
import { generalStore, projectStore, scenarioStore, viewStore } from '@/store/store-accessor';
import ResourceListConsumer from '@/components/common/ResourceListConsumer';
import ResourceList from '@/components/common/ResourceList.vue';

@Component({
  name: 'Views',
  components: {
    ResourceList
  }
})
export default class Views extends Mixins<ResourceListConsumer<View>>(ResourceListConsumer) {
  editPage = 'ViewEdit';
  resourceTerm = 'view';
  defaultSort = 'name';
  filterKeys = ['name'];
  singleRowActions = [MovActionType.DUPLICATE, MovActionType.EDIT, MovActionType.DELETE];

  get activeProject(): Project | null {
    return projectStore.activeProject;
  }

  get scenarios(): ShortScenario[] {
    return scenarioStore.scenarios;
  }

  get currentScenario(): ShortScenario | null {
    return (scenarioStore.currentScenario as ShortScenario) ?? null;
  }

  set currentScenario(value) {
    scenarioStore.setCurrentScenario(value);
  }

  get currentScenarioUUID(): UUID | null {
    return this.currentScenario?.uuid ?? null;
  }

  get warningMessage() {
    const msg = this.currentScenario
      ? 'view.nonAvailable'
      : this.scenarios.length
      ? 'view.selectScenario'
      : 'view.noScenarios';
    return this.$t(msg);
  }

  async getAll() {
    return this.currentScenarioUUID ? await viewStore.getViews(this.currentScenarioUUID) : [];
  }

  async onDuplicate(view: View) {
    if (this.currentScenarioUUID) {
      const views = this.items as View[],
        clonedView = cloneDeep(view);

      delete clonedView.uuid;
      let i = 0,
        name = '';

      do {
        i++;
        name = `${clonedView.name} (${i})`;
      } while (views.filter(v => v.name === name).length);

      clonedView.name = name;

      await viewStore.createView({ scenarioUUID: this.currentScenarioUUID, view: clonedView });
      await this.resetList();
      this.$flow.snackbar.successMessage('' + this.$t('view.viewDuplicated'));
    }
  }

  getDeleteItemRequests(itemList: View[]) {
    return itemList
      .filter(element => element.uuid)
      .map(element => viewStore.deleteView((element as { uuid: string }).uuid));
  }

  @Watch('activeProject', { immediate: true })
  async afterActiveProject() {
    if (this.activeProject) {
      const scenarios = await scenarioStore.getScenariosWithSimulationInfo(this.activeProject.uuid);
      if (
        this.currentScenario !== null &&
        scenarios.map(s => s.uuid).indexOf(this.currentScenario.uuid) === -1
      ) {
        this.currentScenario = null;
      }
      if (this.currentScenario === null) {
        this.currentScenario = scenarios[0] ?? null;
      }
    }
  }

  @Watch('currentScenarioUUID')
  async afterCurrentScenario() {
    await this.resetList();
  }

  setLoading(payload: { value: boolean; msg?: string }) {
    generalStore.setLoading(payload);
  }
}
