






























import { Component, Mixins, Prop, Ref } from 'vue-property-decorator';
import FixedPosition from '@movici-flow-common/mixins/FixedPosition';
import { ActionMenuItem } from '@movici-flow-common/types';
import MovActionMenuItem from './ActionMenuItem.vue';

@Component({
  name: 'MovKebabMenu',
  components: {
    MovActionMenuItem
  }
})
export default class MovKebabMenu extends Mixins(FixedPosition) {
  @Prop({ type: Array, default: () => [] }) readonly value!: ActionMenuItem[];
  @Ref('anchorRef') declare readonly anchorRef: HTMLElement;
  @Ref('popupRef') declare readonly popupRef_: Vue;
  adjust = {
    top: -12,
    left: 4
  };

  emitAndClose(event: string, value: unknown) {
    this.$emit(event, value);
    this.visible = false;
  }
}
