














import { Component, Prop, Vue } from 'vue-property-decorator';
import WidgetContainer from './WidgetContainer.vue';
import DataViewContent from './DataViewContent.vue';
import { PopupContent } from '@movici-flow-common/types';

@Component({
  name: 'RightSidePopup',
  components: {
    WidgetContainer,
    DataViewContent
  }
})
export default class RightSidePopup extends Vue {
  @Prop({ type: Object, default: null }) readonly value!: PopupContent;
  @Prop({ type: Number, default: null }) readonly timestamp!: number | null;
}
