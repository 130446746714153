












































































import { Component, Mixins, Watch } from 'vue-property-decorator';
import { Dataset, MovActionType } from '@movici-flow-common/types';
import { datasetStore, generalStore, projectStore } from '@/store/store-accessor';
import { getVariantFromStatus } from '@movici-flow-common/utils';
import ResourceListConsumer from '@/components/common/ResourceListConsumer';
import ResourceList from '@/components/common/ResourceList.vue';

@Component({ name: 'Datasets', components: { ResourceList } })
export default class Datasets extends Mixins<ResourceListConsumer<Dataset>>(ResourceListConsumer) {
  editPage = 'DatasetEdit';
  resourceTerm = 'dataset';
  defaultSort = 'name';
  filterKeys = ['name', 'status'];
  isDownloading = false;

  get activeProject() {
    return projectStore.activeProject;
  }

  getVariantFromStatus = getVariantFromStatus;

  async getDeleteMessage(deleteItems: Dataset[]) {
    const deleteOneMessage = `Are you sure you want to <b>delete</b> this ${this.resourceTerm} and its init data? This action cannot be undone.`,
      deleteMultipleMessage = `Are you sure you want to <b>delete</b> ${deleteItems.length} ${this.resourceTerm}s and all their init data? This action cannot be undone.`,
      deleteTitle = `Delete ${this.resourceTerm}(s)`;

    return {
      message: deleteItems.length > 1 ? deleteMultipleMessage : deleteOneMessage,
      title: deleteTitle
    };
  }

  async getAll() {
    return this.activeProject ? await datasetStore.getDatasets(this.activeProject.uuid) : [];
  }

  getDeleteItemRequests(itemList: Dataset[]) {
    return itemList.map(element => datasetStore.deleteDataset(element));
  }

  getInitData(dataset: Dataset) {
    this.isDownloading = true;
    datasetStore
      .downloadInitData(dataset)
      .catch(err => console.error(err))
      .finally(() => {
        this.isDownloading = false;
      });
  }

  checkedRowEnabledActionsRow(datasetRow: Dataset) {
    const rv = [MovActionType.EDIT, MovActionType.DELETE];
    if (datasetRow.has_data) {
      rv.push(MovActionType.DOWNLOAD);
    }
    return rv;
  }

  @Watch('activeProject')
  afterActiveProject() {
    if (this.activeProject) {
      this.resetList();
    }
  }

  setLoading(payload: { value: boolean; msg?: string }) {
    generalStore.setLoading(payload);
  }
}
