




















import { RGBAColor } from '@deck.gl/core';
import { AdvColorMapping } from '@movici-flow-common/types';
import { Component, Vue, Prop } from 'vue-property-decorator';
import ColorInput from '../../widgets/ColorInput.vue';
@Component({
  name: 'AdvColorList',
  components: {
    ColorInput
  }
})
export default class AdvColorList extends Vue {
  @Prop({ type: Array, default: () => [] }) readonly value!: AdvColorMapping;
  @Prop({ type: Array, default: () => [] }) readonly presets!: (RGBAColor | string)[];
  selectedIndex = -1;
  showColorPicker = false;

  get colors(): RGBAColor[] {
    return this.value.map(val => val[1]);
  }

  get labels(): string[] {
    return this.value.map(val => String(val[0]));
  }

  updateColor(id: number, newValue: RGBAColor) {
    this.$emit('input', { id, newValue });
  }

  getColorTitle(i: number) {
    return i === 0 ? 'Special' : 'Undefined';
  }
}
