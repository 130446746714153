









































import { Component, Vue } from 'vue-property-decorator';
import PasswordDialog from './PasswordDialog.vue';
import { apiStore, currentUserStore } from '@/store/store-accessor';
import DialogModal from '@/components/global-alt/DialogModal.vue';

@Component({
  name: 'Profile',
  components: {
    PasswordDialog
  }
})
export default class Profile extends Vue {
  isPasswordChangeActive = false;

  get user() {
    return currentUserStore.user;
  }

  get editPayload() {
    return {
      active: this.user?.active
    };
  }

  async applyChanges() {
    await currentUserStore.updateProfile(this.editPayload);
    this.$flow.snackbar.successMessage('User successfully updated');
    this.initialize();
  }

  goBack() {
    this.$router.push({ name: 'Dashboard' });
  }

  getThisUser() {
    currentUserStore.getCurrentUser();
  }

  initialize() {
    this.getThisUser();
  }

  async updatePassword(passwordChangeRequest: { current: string; new: string }) {
    const resp = await currentUserStore.updateProfile({
      old_password: passwordChangeRequest.current,
      new_password: passwordChangeRequest.new
    });

    if (resp) {
      this.$flow.snackbar.successMessage('Password successfully updated');
      this.forceLogout();
    }
  }

  confirmDeactivate() {
    this.$oruga.modal.open({
      parent: this,
      component: DialogModal,
      props: {
        message:
          'Are you sure you want to <b>deactivate</o> your account? This action can only be undone by an administrator.',
        title: 'Deactivate account',
        confirmText: 'Deactivate account',
        variant: 'danger',
        canCancel: true,
        hasIcon: true,
        onConfirm: () => this.deactivateAccount()
      },
      trapFocus: true
    });
  }

  async forceLogout() {
    await apiStore.forceLogout();
    this.$router.push({ name: 'Home' });
  }

  async deactivateAccount() {
    await currentUserStore.updateProfile({ active: false });
    this.$flow.snackbar.successMessage('User successfully deactivated');
    this.forceLogout();
  }

  mounted() {
    this.initialize();
  }
}
