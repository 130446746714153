













import { Component, Prop, Vue } from 'vue-property-decorator';
import { ShortScenario } from '@movici-flow-common/types';

@Component({ name: 'ScenarioPicker' })
export default class ScenarioPicker extends Vue {
  @Prop({ type: Object, default: null }) readonly value!: ShortScenario | null;
  @Prop({ type: Boolean, default: false }) readonly disabled!: boolean;
  @Prop({ type: String, default: 'default' }) readonly size!: string;
  @Prop({ type: Array, default: () => [] }) readonly scenarios!: ShortScenario[];
  optionLabel = (s: ShortScenario) => s?.display_name ?? '';

  get errorMessage() {
    return this.value ? '' : 'Please select a value';
  }

  get variant() {
    return this.value ? '' : 'danger';
  }
}
