import {
  AddDatasetType,
  AddEntityType,
  AddPropertyType,
  DeleteDatasetType,
  DeleteEntityType,
  DeletePropertyType,
  GetDatasetTypes,
  GetEntityTypes,
  GetPropertyTypes,
  UpdateDatasetType,
  UpdateEntityType,
  UpdatePropertyType
} from '@/api/requests';
import { DatasetType, EntityType, PropertyType } from '@movici-flow-common/types';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { apiStore } from '../store-accessor';

@Module({
  name: 'datasetSchema',
  namespaced: true
})
class DatasetSchemaStore extends VuexModule {
  datasetTypes: DatasetType[] = [];
  entityTypes: EntityType[] = [];
  propertyTypes: PropertyType[] = [];

  get client() {
    return apiStore.client;
  }

  @Mutation
  SET_DATASET_TYPES(datasetTypes: DatasetType[]) {
    this.datasetTypes = datasetTypes;
  }

  @Mutation
  SET_ENTITY_TYPES(entityTypes: EntityType[]) {
    this.entityTypes = entityTypes;
  }

  @Mutation
  SET_PROPERTY_TYPES(propertyTypes: PropertyType[]) {
    this.propertyTypes = propertyTypes;
  }

  @Action({ rawError: true, commit: 'SET_DATASET_TYPES' })
  async getDatasetTypes() {
    return (await this.client.request(new GetDatasetTypes())) ?? [];
  }

  @Action({ rawError: true, commit: 'SET_ENTITY_TYPES' })
  async getEntityTypes() {
    return (await this.client.request(new GetEntityTypes())) ?? [];
  }

  @Action({ rawError: true, commit: 'SET_PROPERTY_TYPES' })
  async getPropertyTypes() {
    return (await this.client.request(new GetPropertyTypes())) ?? [];
  }

  @Action({ rawError: true })
  async addDatasetType(datasetType: DatasetType) {
    return await this.client.request(new AddDatasetType(datasetType));
  }

  @Action({ rawError: true })
  async deleteDatasetType(datasetType: Required<DatasetType>) {
    return await this.client.request(new DeleteDatasetType(datasetType.uuid));
  }

  @Action({ rawError: true })
  async updateDatasetType(datasetType: Required<DatasetType>) {
    return await this.client.request(new UpdateDatasetType(datasetType, datasetType.uuid));
  }

  @Action({ rawError: true })
  async addEntityType(entityType: EntityType) {
    return await this.client.request(new AddEntityType(entityType));
  }

  @Action({ rawError: true })
  async deleteEntityType(entityType: Required<EntityType>) {
    return await this.client.request(new DeleteEntityType(entityType.uuid));
  }

  @Action({ rawError: true })
  async updateEntityType(entityType: Required<EntityType>) {
    return await this.client.request(new UpdateEntityType(entityType, entityType.uuid));
  }

  @Action({ rawError: true })
  async addPropertyType(propertyType: PropertyType) {
    return await this.client.request(new AddPropertyType(propertyType));
  }

  @Action({ rawError: true })
  async deletePropertyType(propertyType: Required<PropertyType>) {
    return await this.client.request(new DeletePropertyType(propertyType.uuid));
  }

  @Action({ rawError: true })
  async updatePropertyType(propertyType: Required<PropertyType>) {
    return await this.client.request(new UpdatePropertyType(propertyType, propertyType.uuid));
  }
}

export default DatasetSchemaStore;
