




























import { Component, Prop, Vue } from 'vue-property-decorator';
import { ComposableVisualizerInfo } from '@movici-flow-common/visualizers/VisualizerInfo';
import VisualizerElement from '../widgets/VisualizerElement.vue';

@Component({
  name: 'ExportLayerPicker',
  components: { VisualizerElement }
})
export default class ExportLayerPicker extends Vue {
  @Prop({ type: Array, default: () => [] })
  readonly layers!: ComposableVisualizerInfo[];
}
