// @ts-nocheck
import Vue_, { VueConstructor } from 'vue';
import { registerComponent } from '@movici-flow-common/plugins';
import MovProjectPicker from './ProjectPicker.vue';
import MovScenarioPicker from './ScenarioPicker.vue';
import MovMessage from './Message.vue';

let components = {
  MovProjectPicker,
  MovScenarioPicker,
  MovMessage
};

type GlobalOpts = {
  additionalComponents?: Record<string, VueConstructor<Vue_>>;
};

export default (() => {
  const Globals_ = {
    install(Vue: typeof Vue_, opts?: GlobalOpts) {
      if (opts?.additionalComponents) {
        components = { ...components, ...opts.additionalComponents };
      }

      Object.entries(components).forEach(([name, component]) => {
        registerComponent(Vue, component, name);
      });
    }
  };

  return Globals_;
})();
