import { Client } from '@movici-flow-common/api';
import CurrentUserStore from '@/store/modules/CurrentUserStore';
import { GetProfile } from '../requests';
import { UserService } from '@movici-flow-common/types/backend';

export default class RemoteUserService implements UserService {
  client: Client;
  store?: CurrentUserStore;

  constructor(client: Client, store?: CurrentUserStore) {
    this.client = client;
    this.store = store;
  }

  async get() {
    return (
      (await this.store?.getCurrentUser()) ?? (await this.client.request(new GetProfile())) ?? null
    );
  }
}
