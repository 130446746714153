import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { getExtension } from '@/api/contentType';
import { Dataset, UUID } from '@movici-flow-common/types';
import {
  AddDataset,
  AddDatasetData,
  DeleteDataset,
  DeleteDatasetData,
  GetDataset,
  GetDatasetDataAsBlob,
  GetDatasets,
  UpdateDataset
} from '@/api/requests';
import { downloadAsFile } from '@/store/requests';
import { apiStore, projectStore } from '../store-accessor';

@Module({
  name: 'dataset',
  namespaced: true
})
class DatasetStore extends VuexModule {
  progress = 0;

  get client() {
    return apiStore.client;
  }

  @Mutation
  SET_UPLOAD_PROGRESS(progress: { current: number; target: number }) {
    this.progress = Math.round((progress.current / progress.target) * 100);
  }

  @Action({ rawError: true })
  async getDatasets(projectUUID?: UUID) {
    projectUUID ??= projectStore.activeProjectUUID;

    if (projectUUID) {
      const datasets = (await this.client.request(new GetDatasets(projectUUID))) ?? [];
      return datasets;
    }

    return [];
  }

  @Action({ rawError: true })
  async getDataset(uuid: UUID) {
    return await this.client.request(new GetDataset(uuid));
  }

  @Action({ rawError: true })
  async addDataset(dataset: Dataset) {
    const projectUUID = projectStore.activeProjectUUID;

    if (projectUUID) {
      return await this.client.request(new AddDataset(projectUUID, dataset));
    }
    return null;
  }

  @Action({ rawError: true })
  async deleteDataset(dataset: Dataset) {
    return await this.client.request(new DeleteDataset(dataset.uuid));
  }

  @Action({ rawError: true })
  async updateDataset(dataset: Dataset) {
    return await this.client.request(new UpdateDataset(dataset.uuid, dataset));
  }

  @Action({ rawError: true })
  async downloadInitData(dataset: Dataset) {
    const resp = await this.client.request(new GetDatasetDataAsBlob(dataset.uuid));

    if (resp) {
      downloadAsFile(new Blob([resp.data]), `${dataset.name}${getExtension(resp.contentType)}`);
    }
  }

  @Action({ rawError: true })
  async uploadInitData(payload: { uuid: string; file: File }) {
    const resp = await this.client.request(
      new AddDatasetData(payload.uuid, payload.file, (progressEvent: ProgressEvent) => {
        if (progressEvent.lengthComputable) {
          this.SET_UPLOAD_PROGRESS({
            current: progressEvent.loaded,
            target: progressEvent.total
          });
        }
      })
    );

    this.SET_UPLOAD_PROGRESS({ current: 0, target: 1 });
    return resp;
  }

  @Action({ rawError: true })
  async deleteInitData(uuid: string) {
    return await this.client.request(new DeleteDatasetData(uuid));
  }
}

export default DatasetStore;
