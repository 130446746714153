

































import View from '@deck.gl/core/views/view';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({ name: 'ViewLoaderModal' })
export default class ViewLoaderModal extends Vue {
  @Prop({ type: Array, default: () => [] }) readonly views!: View[];

  selectedView: View | null = null;

  @Watch('views', { immediate: true })
  selectFirstView() {
    if (this.views.length) {
      this.selectedView = this.views[0];
    }
  }

  emitAndClose() {
    this.$emit('input', this.selectedView);
    this.$emit('close');
  }
}
