
















































import { currentUserStore } from '@/store/store-accessor';
import { Component, Prop, Vue } from 'vue-property-decorator';
import NewPassword from './NewPassword.vue';
@Component({ name: 'ResetPassword', components: { NewPassword } })
export default class ResetPassword extends Vue {
  @Prop({ type: String, required: true }) readonly token!: string;
  @Prop({ type: String, required: true }) readonly username!: string;
  password = '';

  get canSubmit() {
    return !!this.password;
  }

  onResetPassword() {
    currentUserStore
      .doResetPassword(this.getResetPasswordPayload())
      .then(() => {
        this.$flow.snackbar.successMessage(
          'Congratulations, your password has been reset! Please login to continue.'
        );
        this.goToLogin(3000);
      })
      .catch(() => {
        this.$flow.snackbar.failMessage('Something went wrong while resetting your password.');
        this.goToLogin(4000);
      });
  }

  getResetPasswordPayload() {
    return {
      password: this.password,
      token: this.token
    };
  }

  goToLogin(timeout: number) {
    setTimeout(() => this.$router.push({ path: '/' }), timeout);
  }
}
