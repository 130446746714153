
































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ActionMenuItem } from '@movici-flow-common/types';
import { flowStore, flowVisualizationStore } from '@movici-flow-common/store/store-accessor';
import ViewLoaderModal from '.././widgets/ViewLoaderModal.vue';

@Component({
  name: 'ViewInfoBox',
  components: { ViewLoaderModal }
})
export default class ViewInfoBox extends Vue {
  @Prop({ type: String })
  name!: string;
  openMenu = false;
  loadModal = false;
  actions: ActionMenuItem[] = [
    {
      label: this.$t('actions.addNew') + ' ' + this.$t('resources.view'),
      icon: 'file',
      iconPack: 'far',
      event: 'resetView'
    },
    {
      label: this.$t('actions.load') + ' ' + this.$t('resources.views') + '...',
      icon: 'map',
      iconPack: 'far',
      event: 'loadViewDialog'
    },
    {
      label: this.$t('actions.save') + ' ' + this.$t('resources.view'),
      icon: 'fa-mov-save',
      iconPack: 'fak',
      event: 'saveView'
    },
    {
      label: this.$t('actions.saveAsNew') + '...',
      icon: 'fa-mov-save',
      iconPack: 'fak',
      event: 'saveViewAsNew'
    },
    {
      label: '' + this.$t('actions.delete') + ' ' + this.$t('resources.view'),
      icon: 'trash',
      iconPack: 'far',
      event: 'deleteView',
      variant: 'danger'
    }
  ];

  get views() {
    return flowVisualizationStore.views;
  }

  async loadViewDialog() {
    await flowVisualizationStore.getViewsByScenario(flowStore.scenario?.uuid);
    this.loadModal = true;
  }

  handleEvent(name: string) {
    this.$emit(name);
  }

  updateVisualizationName(updatedName: string) {
    this.$emit('update:name', updatedName);
  }
}
