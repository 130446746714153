














import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({ name: 'EnumInputs' })
export default class EnumInputs extends Vue {
  @Prop({ type: Array, required: true }) readonly value!: number[];
  @Prop({ type: Array, default: () => [] }) readonly enumLabels!: string[];
}
