
























































import isEmpty from 'lodash/isEmpty';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Nullable } from '@movici-flow-common/types';

type ValidatorFunc = (field: string) => string | null;

interface Validators {
  password: Array<ValidatorFunc>;
  confirmPassword: Array<ValidatorFunc>;
}

@Component({ name: 'PasswordDialog' })
export default class PasswordDialog extends Vue {
  currentPassword: Nullable<string> = null;
  password: Nullable<string> = null;
  confirmPassword: Nullable<string> = null;
  errors: Record<string, string[]> = {};
  validators: Validators = {
    password: [this.required, this.regexMatches],
    confirmPassword: [this.required, this.passwordsMatch]
  };
  regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,128}$/;

  get canSave() {
    return !!this.password && !!this.confirmPassword && !this.hasErrors();
  }

  save() {
    this.$emit('new-password', {
      current: this.currentPassword,
      new: this.password
    });
    this.$emit('close');
  }

  validate(field: keyof Validators, value: string) {
    delete this.errors[field];
    const fieldErrors: string[] = [];

    this.validators[field].forEach((validator: ValidatorFunc) => {
      const error = validator(value);
      if (error) fieldErrors.push(error);
    });

    if (fieldErrors.length) {
      this.errors[field] = fieldErrors;
    }
  }

  hasErrors() {
    return !isEmpty(this.errors);
  }

  regexMatches(value: string): string | null {
    if (!this.regex.exec(value)) return this.$t('messages.passwordComplexity') as string;
    return null;
  }

  required(value: unknown): string | null {
    if (!value) return 'This is a required field';
    return null;
  }

  passwordsMatch(): string | null {
    if (this.password !== this.confirmPassword) return 'The passwords must match';
    return null;
  }

  @Watch('password')
  passwordWatcher() {
    if (this.password) {
      this.validate('password', this.password);
    }
  }

  @Watch('password')
  @Watch('confirmPassword')
  confirmPasswordWatcher() {
    if (this.confirmPassword) {
      this.validate('confirmPassword', this.confirmPassword);
    }
  }
}
