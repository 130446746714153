import i18n from '../../i18n';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { CheckToken, GetGlobalSettings } from '@/api/requests';
import { apiStore } from '@/store/store-accessor';
import { defaultClient } from '@movici-flow-common/api/client';
import { setClient, setProjections } from '@movici-flow-common/crs';
import { RemoteApplicationSettings } from '@movici-flow-common/types';

function assign<T, K extends keyof T>(obj: T, key: keyof T, value: unknown) {
  obj[key] = value as T[K];
}

class ApplicationSettings implements RemoteApplicationSettings {
  Language: string;
  ApiAddress: string;
  features: string[];
  projections: Record<string, string>;
  localSettingKeys: Array<keyof ApplicationSettings> = ['Language'];

  constructor(settings: Partial<ApplicationSettings>) {
    this.Language = settings.Language ?? 'en';
    this.ApiAddress = settings.ApiAddress ?? '/';
    this.features = settings.features ?? [];
    this.projections = settings.projections ?? {};
    this.loadLocal();
  }

  storeLocal() {
    localStorage.settings = JSON.stringify(
      this.localSettingKeys.reduce((curr, key) => {
        assign(curr, key, this[key]);
        return curr;
      }, {} as Partial<ApplicationSettings>)
    );
  }

  loadLocal() {
    let localSettings: Partial<ApplicationSettings>;
    try {
      localSettings = JSON.parse(localStorage.settings);
    } catch {
      localSettings = {};
    }
    for (const key of this.localSettingKeys) {
      if (localSettings[key]) {
        assign(this, key, localSettings[key]);
      }
    }
  }

  update(settings: Partial<ApplicationSettings>) {
    Object.assign(this, settings);
    this.storeLocal();
  }
}

@Module({
  name: 'general',
  namespaced: true
})
class GeneralStore extends VuexModule {
  initialized_ = false;
  settings_: ApplicationSettings = new ApplicationSettings({});
  loading = false;

  get initialized(): boolean {
    return this.initialized_;
  }

  get settings() {
    return this.settings_;
  }

  get apiBase() {
    return this.settings.ApiAddress ?? '/';
  }

  get language() {
    return this.settings.Language ?? 'en';
  }

  get featureToggle() {
    return (feature: string) => {
      if (!this.settings.features) {
        return false;
      }

      try {
        return this.settings?.features.indexOf(feature) > -1;
      } catch (e) {
        return false;
      }
    };
  }

  @Mutation
  SET_INITIALIZED() {
    this.initialized_ = true;
  }

  @Mutation
  setLoading({ value }: { value: boolean }) {
    this.loading = value;
  }

  @Mutation
  UPDATE_SETTINGS(payload: Partial<ApplicationSettings>) {
    this.settings_.update(payload);
  }

  @Action({ rawError: true })
  async initApp() {
    this.setLanguage(this.language);

    const settings = await this.loadRemoteSettings();
    const baseURL = settings.ApiAddress;
    const apiToken = await this.loadExistingToken(baseURL);
    apiStore.configureClient({ baseURL, apiToken });
    setClient(apiStore.client);
    setProjections(settings?.projections);

    this.SET_INITIALIZED();
  }

  @Action({ rawError: true })
  setLanguage(lang: string) {
    i18n.locale = lang;
    this.UPDATE_SETTINGS({ Language: lang });
  }

  @Action({ rawError: true })
  async loadRemoteSettings() {
    const settings = await apiStore.client.request(new GetGlobalSettings());
    if (settings) {
      this.UPDATE_SETTINGS(settings);
    } else {
      console.warn('could not load remote settings');
    }
    return this.settings_;
  }

  @Action({ rawError: true })
  async loadExistingToken(baseURL: string): Promise<string | null> {
    const apiToken = localStorage.apiToken;

    if (!apiToken) {
      return null;
    }
    const resp = await defaultClient({
      baseURL: baseURL
    }).request(new CheckToken(apiToken), {
      401: () => {}
    });

    if (resp === null) {
      apiStore.forceLogout();
    } else {
      apiStore.initToken(apiToken);
      return apiToken;
    }
    return null;
  }
}

export default GeneralStore;
