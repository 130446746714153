










































































































































import DialogModal from '@/components/global-alt/DialogModal.vue';
import SummaryListing from '@movici-flow-common/mixins/SummaryListing';
import ValidationProvider from '@movici-flow-common/mixins/ValidationProvider';
import { flowStore } from '@movici-flow-common/store/store-accessor';
import {
  ColorClause,
  Dataset,
  FlowVisualizerOptions,
  FlowVisualizerType,
  ScenarioDataset,
  SizeClause
} from '@movici-flow-common/types';
import { excludeKeys, propertyString } from '@movici-flow-common/utils';
import FormValidator from '@movici-flow-common/utils/FormValidator';
import { ComposableVisualizerInfo } from '@movici-flow-common/visualizers/VisualizerInfo';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { VueClass } from 'vue-class-component/lib/declarations';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import GeometrySelector from '../widgets/GeometrySelector.vue';
import ColorConfigurator from './color/ColorConfigurator.vue';
import FloodingGridConfigurator from './flooding/FloodingGridConfigurator.vue';
import GeometryConfigurator from './GeometryConfigurator.vue';
import ShapeIconConfigurator from './icon/ShapeIconConfigurator.vue';
import PopupConfigurator from './popup/PopupConfigurator.vue';
import SizeConfigurator from './size/SizeConfigurator.vue';
import VisibilityConfigurator from './VisibilityConfigurator.vue';

interface FinalizerContext {
  datasets: Record<string, ScenarioDataset | Dataset>;
}
type Finalizers = {
  popup: (
    val: FlowVisualizerOptions['popup'],
    context: FinalizerContext
  ) => FlowVisualizerOptions['popup'] | undefined;
  size: (
    val: FlowVisualizerOptions['size'],
    context: FinalizerContext
  ) => FlowVisualizerOptions['size'];
};

const FINALIZERS: Finalizers = {
  popup(val: FlowVisualizerOptions['popup']) {
    // if popup clause is provided but user doesn't set labels
    // for the attributes, we use its propertyString
    return !val
      ? undefined
      : {
          title: val.title,
          show: val.show,
          onHover: val.onHover,
          dynamicTitle: val.dynamicTitle,
          items: val.items.map(item => {
            return {
              name: item.name || propertyString(item.attribute),
              attribute: item.attribute
            };
          })
        };
  },
  size(val: FlowVisualizerOptions['size']) {
    return !val
      ? undefined
      : Object.entries(val).reduce((acc, obj) => {
          const [key, value] = obj;
          if (value.units !== 'meters') {
            delete value.minPixels;
            delete value.maxPixels;
          }

          acc[key as 'byValue' | 'static' | 'dashed'] = value;

          return acc;
        }, {} as SizeClause);
  }
};
enum FlowConfigurator {
  COLOR = 'color',
  SIZE = 'size',
  ICON_SHAPE = 'icon-shape',
  VISIBILITY = 'visibility',
  POPUP = 'popup',
  FLOODING = 'flooding',
  GEOMETRY = 'geometry'
}
const CONFIGURATORS_BY_TYPE: Record<FlowVisualizerType, FlowConfigurator[]> = {
  [FlowVisualizerType.POINTS]: [
    FlowConfigurator.COLOR,
    FlowConfigurator.SIZE,
    FlowConfigurator.VISIBILITY,
    FlowConfigurator.POPUP
  ],
  [FlowVisualizerType.LINES]: [
    FlowConfigurator.COLOR,
    FlowConfigurator.SIZE,
    FlowConfigurator.VISIBILITY,
    FlowConfigurator.POPUP
  ],
  [FlowVisualizerType.POLYGONS]: [
    FlowConfigurator.COLOR,
    FlowConfigurator.SIZE,
    FlowConfigurator.VISIBILITY,
    FlowConfigurator.POPUP
  ],
  [FlowVisualizerType.ARCS]: [
    FlowConfigurator.COLOR,
    FlowConfigurator.SIZE,
    FlowConfigurator.VISIBILITY,
    FlowConfigurator.POPUP
  ],
  [FlowVisualizerType.ICONS]: [
    FlowConfigurator.COLOR,
    FlowConfigurator.ICON_SHAPE,
    FlowConfigurator.SIZE,
    FlowConfigurator.VISIBILITY,
    FlowConfigurator.POPUP
  ],
  [FlowVisualizerType.GRID]: [
    FlowConfigurator.GEOMETRY,
    FlowConfigurator.COLOR,
    FlowConfigurator.SIZE,
    FlowConfigurator.VISIBILITY,
    FlowConfigurator.POPUP
  ],
  [FlowVisualizerType.FLOODING_GRID]: [FlowConfigurator.GEOMETRY, FlowConfigurator.FLOODING]
};
@Component({
  name: 'VisualizerConfigurator',
  components: {
    GeometrySelector
  }
})
export default class VisualizerConfigurator extends Mixins(SummaryListing, ValidationProvider) {
  @Prop({ type: Object, default: () => new ComposableVisualizerInfo() })
  readonly value!: ComposableVisualizerInfo;
  @Prop({ type: String, default: null }) readonly scenarioUUID!: string | null;
  @Prop({ type: Number, default: -1 }) readonly vGroupIndex!: number;
  @Prop({ type: Boolean, default: false }) readonly noGroups!: boolean;
  datasets: ScenarioDataset[] = [];
  geometry: FlowVisualizerType | null = null;
  settings: FlowVisualizerOptions | null = null;
  isDirty = false;
  displayName = '';
  tab = 0;
  tabsWithErrors: Record<number, boolean> = {};
  additionalEntityGroups: Record<string, string> | null = null;

  get composedVisualizer(): Partial<ComposableVisualizerInfo> {
    if (this.currentDataset && this.currentEntityName && this.settings) {
      return new ComposableVisualizerInfo({
        id: this.value.id,
        name: this.displayName,
        datasetName: this.currentDataset.name,
        datasetUUID: this.currentDataset.uuid,
        entityGroup: this.currentEntityName,
        additionalEntityGroups: this.additionalEntityGroups ?? undefined,
        settings: this.settings,
        scenarioUUID: this.value?.scenarioUUID || this.scenarioUUID,
        visible: this.value?.visible ?? true
      });
    }
    return {};
  }

  get finalizedVisualizer(): Partial<ComposableVisualizerInfo> {
    return Object.assign({}, this.composedVisualizer, { settings: this.finalizeSettings() });
  }

  get filteredConfigurators() {
    if (!this.properties || !this.validator || !this.geometry) return [];
    return CONFIGURATORS_BY_TYPE[this.geometry].map(c => this.configurators[c]);
  }

  get configurators(): Record<FlowConfigurator, ConfiguratorSettings> {
    const vBindDefaults = (key: string) => {
        return {
          entityProps: this.properties,
          validator: this.validator?.child(key),
          geometry: this.geometry,
          settings: this.settings,
          summary: this.summary,
          datasets: this.datasets
        };
      },
      vOnDefaults = <T extends keyof FlowVisualizerOptions>(clauseType: T) => {
        return {
          input: (clause: FlowVisualizerOptions[T] | null) => {
            this.updateSettings(clause, clauseType);
          }
        };
      };

    return {
      [FlowConfigurator.COLOR]: {
        id: 'color',
        name: '' + this.$t('flow.visualization.colorConfig.colors'),
        component: ColorConfigurator,
        vBind: { ...vBindDefaults('color'), value: this.settings?.color },
        vOn: { ...vOnDefaults('color') }
      },
      [FlowConfigurator.ICON_SHAPE]: {
        id: 'icon-shape',
        name: `${this.$t('flow.visualization.iconConfig.shape')} / ${this.$t(
          'flow.visualization.iconConfig.icon'
        )}`,
        component: ShapeIconConfigurator,
        vBind: {
          ...vBindDefaults('icon-shape'),
          iconClause: this.settings?.icon,
          shapeClause: this.settings?.shape
        },
        vOn: {
          input: ({
            shape,
            icon
          }: {
            shape: FlowVisualizerOptions['shape'] | null;
            icon: FlowVisualizerOptions['icon'] | null;
          }) => {
            this.updateSettings(shape, 'shape');
            this.updateSettings(icon, 'icon');
          }
        }
      },
      [FlowConfigurator.SIZE]: {
        id: 'size',
        name: (() => {
          switch (this.geometry) {
            case FlowVisualizerType.POINTS:
              return '' + this.$t('flow.visualization.sizeConfig.radius');
            case FlowVisualizerType.LINES:
            case FlowVisualizerType.POLYGONS:
            case FlowVisualizerType.ARCS:
              return '' + this.$t('flow.visualization.sizeConfig.thickness');
            case FlowVisualizerType.ICONS:
            default:
              return '' + this.$t('flow.visualization.sizeConfig.size');
          }
        })(),
        component: SizeConfigurator,
        vBind: { ...vBindDefaults('size'), value: this.settings?.size },
        vOn: { ...vOnDefaults('size') }
      },
      [FlowConfigurator.VISIBILITY]: {
        id: 'visibility',
        name: '' + this.$t('flow.visualization.visibilityConfig.visibility'),
        component: VisibilityConfigurator,
        vBind: { ...vBindDefaults('visibility'), value: this.settings?.visibility },
        vOn: { ...vOnDefaults('visibility') }
      },
      [FlowConfigurator.POPUP]: {
        id: 'popup',
        name: '' + this.$t('flow.visualization.popup.popup'),
        component: PopupConfigurator,
        vBind: { ...vBindDefaults('popup'), value: this.settings?.popup },
        vOn: { ...vOnDefaults('popup') }
      },
      [FlowConfigurator.FLOODING]: {
        id: 'floodingGrid',
        name: '' + this.$t('flow.visualization.floodingConfig.floodingGrid'),
        component: FloodingGridConfigurator,
        vBind: {
          ...vBindDefaults('floodingGrid'),
          value: this.settings?.floodingGrid,
          color: this.settings?.color
        },
        vOn: {
          ...vOnDefaults('floodingGrid'),
          setColor: (clause: ColorClause | null) => {
            this.updateSettings(clause, 'color');
          }
        }
      },
      [FlowConfigurator.GEOMETRY]: {
        id: 'geometry',
        name: '' + this.$t('flow.visualization.geometryConfig.geometry'),
        component: GeometryConfigurator,
        vBind: { ...vBindDefaults('geometry'), value: this.additionalEntityGroups },
        vOn: {
          input: (val: null | Record<string, string>) => {
            this.additionalEntityGroups = val;
          }
        }
      }
    };
  }

  get hasEmptySettings() {
    const obj = this.composedVisualizer;
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  get newIDRequired() {
    if (!this.value?.id || !this.settings) return false;
    return (
      this.value.datasetUUID !== this.currentDataset?.uuid ||
      this.value.entityGroup !== this.currentEntityName ||
      this.value?.settings?.type !== this.settings.type
    );
  }

  get hasPendingChanges() {
    const value = excludeKeys(this.value, ['status', 'summary', 'errors']),
      finalized = excludeKeys(this.finalizedVisualizer, ['status', 'summary', 'errors']);
    return !isEqual(finalized, value);
  }

  tabHasErrors(name: string) {
    return Object.keys(this.errors).some(err => err.startsWith(name));
  }

  @Watch('currentEntityName')
  afterSetCurrentEntityName(currentEntityName: string, oldName: string) {
    if (!this.displayName || this.displayName === oldName) {
      this.validated('displayName', currentEntityName);
      this.tab = 0;
    }
  }

  @Watch('geometry')
  ensureGeometry(geometry: FlowVisualizerType | null) {
    if (geometry) {
      if (!this.settings) {
        this.settings = { type: geometry };
      } else {
        this.settings = { ...this.settings, type: geometry };
      }
    } else {
      this.settings = null;
    }
    this.tab = 0;
    this.tabsWithErrors = {};
  }

  @Watch('currentDataset')
  async updateCurrentDatasetName() {
    if (this.currentDataset?.uuid) {
      this.currentDatasetUUID = this.currentDataset.uuid;
    } else if (this.currentDataset?.name) {
      this.currentDatasetName = this.currentDataset.name;
    }
  }

  @Watch('value')
  updateVisualizerMetadata(value: ComposableVisualizerInfo) {
    this.displayName = value.name;
    this.currentDataset = this.datasets.find(d => d.name === value.datasetName) || null;
    this.additionalEntityGroups = value.additionalEntityGroups ?? null;
    if (this.currentDataset) {
      this.currentEntityName = value.entityGroup;
      if (value.settings) {
        this.geometry = value.settings.type;
        this.settings = cloneDeep(value.settings);
      }
    }
  }

  headerValidated(key: keyof this, value: unknown) {
    // new configurators dont have settings, so they won't update is dirty
    if (this.value.settings) {
      this.updateIsDirty(this[key] !== value);
    }

    this.validated(key, value);
  }

  updateSettings<T extends keyof FlowVisualizerOptions>(
    updatedClause: FlowVisualizerOptions[T] | null,
    clauseType: T
  ) {
    if (this.settings) {
      if (updatedClause) {
        // This checks if there was a change between the clauses and it's update and if isDirty is ever set to true,
        // then we don't need to check it anymore, it will remain dirty until is saved.
        // Also, when other configurators are mounted, once they stabilize they emit and call this function.
        // But since it is comparing to itself, this !isEqual will remain false
        this.$set(this.settings, clauseType, updatedClause);
      } else {
        this.$delete(this.settings, clauseType);
      }

      this.updateIsDirty(this.hasPendingChanges);
    }
  }

  updateIsDirty(value: boolean) {
    this.isDirty = value;
  }

  close() {
    if (this.isDirty && !this.hasEmptySettings) {
      this.$oruga.modal.open({
        parent: this,
        component: DialogModal,
        props: {
          message: this.$t('flow.visualization.dialogs.unsavedChanges') + '!',
          cancelText: '' + this.$t('flow.visualization.dialogs.continueEditing'),
          confirmText: '' + this.$t('flow.visualization.dialogs.discardChanges'),

          variant: 'danger',
          canCancel: true,
          onConfirm: () => this.$emit('close')
        }
      });
    } else {
      this.$emit('close');
    }
  }

  submit(close?: boolean) {
    this.validator?.validate();
    if (!this.hasErrors) {
      this.$emit(
        'input',
        new ComposableVisualizerInfo(
          Object.assign(
            {},
            this.composedVisualizer,
            { id: this.newIDRequired ? undefined : this.composedVisualizer.id },
            { settings: this.finalizeSettings() }
          )
        )
      );
      this.updateIsDirty(false);
      if (close) this.$emit('close');
    }
  }

  /**
   * Function to finalize clauses
   */
  finalizeSettings(): FlowVisualizerOptions | null {
    if (!this.composedVisualizer.settings) return null;

    let rv: FlowVisualizerOptions = Object.assign({}, this.composedVisualizer.settings);
    let context: FinalizerContext = {
      datasets: this.datasetsByName
    };
    if (rv.popup) {
      rv.popup = FINALIZERS.popup(rv.popup, context);
    }

    if (rv.size) {
      rv.size = FINALIZERS.size(rv.size, context);
    }

    return rv;
  }

  setupValidator() {
    this.validator = new FormValidator({
      validators: {
        currentDataset: () => {
          if (!this.currentDataset) {
            return 'Please select a dataset';
          }
        },
        currentEntityName: () => {
          if (!this.currentEntityName) {
            return 'Please select an entity group';
          }
          if (!this.geometry) return '' + this.$t('flow.datasets.zeroEntities');
        },
        displayName: () => {
          if (!this.displayName) {
            return 'Please fill the field';
          }
        }
      },
      onValidate: errors => {
        this.errors = errors;
      }
    });
  }

  async mounted() {
    if (flowStore.scenario) {
      this.datasets = flowStore.scenario.datasets;
    }

    if (this.value) {
      this.updateVisualizerMetadata(this.value);
    }

    this.setupValidator();
  }
}

interface ConfiguratorSettings {
  id: string;
  name: string | (() => string);
  component: VueClass<Vue>;
  vBind: Record<string, unknown>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  vOn: Record<string, (val: any) => void>;
}
