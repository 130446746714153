import { CAPABILITIES, Client } from '@movici-flow-common/api';
import { Backend } from '@movici-flow-common/types/';

import CurrentUserStore from '@/store/modules/CurrentUserStore';
import DatasetStore from '@/store/modules/DatasetStore';
import GeocodeStore from '@movici-flow-common/store/GeocodeStore';
import ProjectStore from '@/store/modules/ProjectStore';
import ScenarioStore from '@/store/modules/ScenarioStore';
import ViewStore from '@/store/modules/ViewStore';
import {
  DatasetService,
  FetchRequestService,
  GeocodeService,
  ProjectService,
  ScenarioService,
  SummaryService,
  UpdatesService,
  UserService,
  ViewService
} from '@movici-flow-common/types/backend';
import {
  RemoteDatasetService,
  RemoteGeocodeService,
  RemoteProjectService,
  RemoteScenarioService,
  RemoteSummaryService,
  RemoteUpdatesService,
  RemoteUserService,
  RemoteViewService
} from './services';
import RemoteFetchRequestService from './services/RemoteFetchRequestService';

type RemoteBackendOptions = {
  datasetStore?: DatasetStore;
  geocodeStore?: GeocodeStore;
  projectStore?: ProjectStore;
  scenarioStore?: ScenarioStore;
  currentUserStore?: CurrentUserStore;
  viewStore?: ViewStore;
  client: Client;
};
export default class RemoteBackend implements Backend {
  dataset: DatasetService;
  project: ProjectService;
  geocode: GeocodeService;
  scenario: ScenarioService;
  summary: SummaryService;
  updates: UpdatesService;
  user: UserService;
  view: ViewService;
  fetch: FetchRequestService;

  constructor(opts: RemoteBackendOptions) {
    const { client } = opts;
    this.dataset = new RemoteDatasetService(client, opts?.datasetStore);
    this.geocode = new RemoteGeocodeService(client);
    this.project = new RemoteProjectService(client, opts?.projectStore);
    this.scenario = new RemoteScenarioService(client, opts?.scenarioStore);
    this.summary = new RemoteSummaryService(client);
    this.updates = new RemoteUpdatesService(client);
    this.user = new RemoteUserService(client, opts?.currentUserStore);
    this.view = new RemoteViewService(client, opts?.viewStore);
    this.fetch = new RemoteFetchRequestService(client);
  }

  getCapabilities(): CAPABILITIES[] {
    return [CAPABILITIES.PROJECTS, CAPABILITIES.USER, CAPABILITIES.GEOCODE];
  }
}
