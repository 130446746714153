








































































import { Component, Mixins } from 'vue-property-decorator';
import { DatasetFormat, DatasetType, Field } from '@movici-flow-common/types';
import { datasetSchemaStore, generalStore } from '@/store/store-accessor';
import ResourceListConsumer from '@/components/common/ResourceListConsumer';
import ResourceList from '@/components/common/ResourceList.vue';
import EditModalConsumer from '@/components/common/EditModalConsumer';
import EditModal from '@/components/common/EditModal.vue';

@Component({
  name: 'DatasetTypes',
  components: {
    ResourceList,
    EditModal
  }
})
export default class DatasetTypes extends Mixins<
  ResourceListConsumer<DatasetType>,
  EditModalConsumer<DatasetType>
>(ResourceListConsumer, EditModalConsumer) {
  resourceTerm = 'dataset type';
  defaultSort = 'name';
  filterKeys = ['name'];
  fields: Field<DatasetType>[] = [
    {
      name: this.$t('properties.name') + '',
      boundVariable: 'name',
      type: 'text',
      required: true
    },
    {
      name: this.$t('properties.dataset_format') + '',
      boundVariable: 'format',
      type: 'choice',
      required: true,
      choices: Object.values(DatasetFormat)
    }
  ];

  async getAll() {
    return await datasetSchemaStore.getDatasetTypes();
  }

  getEmptyItem(): DatasetType {
    return {
      name: '',
      format: DatasetFormat.ENTITY_BASED
    };
  }

  async addItem(item: DatasetType) {
    if (await datasetSchemaStore.addDatasetType(item)) {
      await this.resetList();
      return true;
    }
    return false;
  }

  async updateItem(item: DatasetType) {
    if (await datasetSchemaStore.updateDatasetType(item as Required<DatasetType>)) {
      await this.resetList();
      return true;
    }
    return false;
  }

  getDeleteItemRequests(itemList: DatasetType[]) {
    return itemList
      .filter(element => element.uuid)
      .map(element => datasetSchemaStore.deleteDatasetType(element as Required<DatasetType>));
  }

  setLoading(payload: { value: boolean; msg?: string }) {
    generalStore.setLoading(payload);
  }
}
