import { Role, Scope } from '@/types';
import { AddScope, DeleteScope, GetRoles, GetScopes } from '@/api/requests';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { apiStore } from '../store-accessor';

@Module({
  name: 'authorization',
  namespaced: true
})
export default class AuthorizationStore extends VuexModule {
  rawScopes: Scope[] = [];
  scopesMap: Record<string, string[]> = {};
  roles: Role[] = [];

  get client() {
    return apiStore.client;
  }

  @Mutation
  SET_SCOPES(rawScopes: Scope[]) {
    if (rawScopes) {
      this.rawScopes = rawScopes;

      const scopesMap: Record<string, string[]> = {};

      rawScopes.forEach(scope => {
        const [type, name] = scope.scope_name.split(':');
        if (!Object.prototype.hasOwnProperty.call(scopesMap, type)) {
          scopesMap[type] = [];
        }
        scopesMap[type].push(name);
      });

      for (const scopeType in scopesMap) {
        if (Object.prototype.hasOwnProperty.call(scopesMap, scopeType)) {
          scopesMap[scopeType].sort();
        }
      }

      this.scopesMap = scopesMap;
    }
  }

  @Mutation
  SET_ROLES(roles: Role[]) {
    this.roles = roles;
  }

  @Action({ rawError: true })
  async getScopes() {
    const scopes = (await this.client?.request(new GetScopes())) ?? [];
    this.SET_SCOPES(scopes);
    return scopes;
  }

  @Action({ rawError: true })
  async addScope(scope: Partial<Scope>) {
    if (scope.scope_name) {
      return await this.client?.request(new AddScope(scope.scope_name));
    }
  }

  @Action({ rawError: true })
  async deleteScope(scope: Scope) {
    const uuid = scope.scope_uuid;
    await this.client?.request(new DeleteScope(uuid));
  }

  @Action({ rawError: true, commit: 'setRoles' })
  async getRoles() {
    this.SET_ROLES((await this.client?.request(new GetRoles())) ?? []);
  }
}
