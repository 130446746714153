














































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import TimelineChart from './TimelineChart.vue';
import { EventBus } from '../../eventbus';
import { Scenario, TimelineInfo, Update } from '@movici-flow-common/types';
import { timelineStore } from '@/store/store-accessor';

function getIndex(arr: number[], val: number) {
  // Do a binary search to the the first index i where arr[i] >= val
  if (arr.length === 0) return -1;
  if (val < arr[0]) return 0;
  if (val >= arr[arr.length - 1]) return arr.length - 1;

  let start = 0,
    end = arr.length - 1;
  let mid = 0;

  while (start <= end) {
    mid = Math.floor((start + end) / 2);
    if (arr[mid] === val) return mid;
    else if (arr[mid] < val) start = mid + 1;
    else end = mid - 1;
  }
  return mid + 1;
}

@Component({
  name: 'Timeline',
  components: {
    TimelineChart
  }
})
export default class Timeline extends Vue {
  @Prop({ type: Object, required: true }) readonly value!: Scenario;
  showAll = false;
  numTimestamps = 5;
  firstTimestampInput = 0;
  timeline: readonly Update[] = [];
  timelineInfo: TimelineInfo | null = null;

  get scenarioUuid() {
    return this.value.uuid;
  }

  get firstTimestampIndex() {
    return getIndex(this.timestamps, this.firstTimestampInput);
  }

  get lastTimestampInput() {
    let firstTimestampIndex = getIndex(this.timestamps, this.firstTimestampInput);
    if (firstTimestampIndex === null) return 0;
    let lastTimestampIndex = firstTimestampIndex + this.numTimestamps - 1;
    lastTimestampIndex = Math.min(lastTimestampIndex, this.timestamps.length - 1);
    return this.timestamps[lastTimestampIndex];
  }

  get updateCount() {
    return this.timelineInfo ? this.timelineInfo.update_count : null;
  }

  get timestamps() {
    return this.timelineInfo ? this.timelineInfo.timestamps : [];
  }

  get firstTimestamp() {
    if (this.timeline.length === 0) {
      return 0;
    }
    return this.timeline[0].timestamp;
  }

  get lastTimestamp() {
    if (this.timeline.length === 0) {
      return 0;
    }
    return this.timeline[this.timeline.length - 1].timestamp;
  }

  async reload() {
    this.timelineInfo = await timelineStore.getTimelineInfo(this.scenarioUuid);
    this.timeline = await this.getThisTimeline();
  }

  async getThisTimeline() {
    let payload = { scenario_uuid: this.scenarioUuid };
    if (!this.showAll) {
      payload = {
        ...payload,
        ...{
          min_time: this.firstTimestampInput,
          max_time: this.lastTimestampInput
        }
      };
    }

    return (await timelineStore.getUpdatesList(payload)) ?? [];
  }

  downloadUpdate(update: Update) {
    timelineStore.downloadUpdate(update).catch(err => console.error(err));
  }

  refreshThisTab(tabId: string) {
    if (tabId === 'timeline') {
      if (this.showAll) {
        this.showAll = false;
      } else {
        this.reload();
      }
    }
  }

  shiftBack() {
    let newIndex = Math.max(this.firstTimestampIndex - this.numTimestamps, 0);
    this.firstTimestampInput = this.timestamps[newIndex];
  }

  shiftForward() {
    let newIndex = Math.min(
      this.firstTimestampIndex + this.numTimestamps,
      this.timestamps.length - 1
    );
    this.firstTimestampInput = this.timestamps[newIndex];
  }

  @Watch('firstTimestamp')
  afterFirstTimestamp(value: number) {
    this.firstTimestampInput = value;
  }

  @Watch('firstTimestampInput')
  afterFirstTimestampInput(value: number) {
    if (value === this.firstTimestamp) return;
    this.getThisTimeline();
  }

  @Watch('numTimestamps')
  afterNumTimestamps() {
    this.getThisTimeline();
  }

  @Watch('showAll')
  afterShowAll() {
    this.getThisTimeline();
  }

  created() {
    EventBus.$on('scenarios-active-tab', this.refreshThisTab);
  }

  beforeDestroy() {
    EventBus.$off('scenarios-active-tab', this.refreshThisTab);
  }
}
