


















import { Component, Vue } from 'vue-property-decorator';
import DatasetTypes from './DatasetTypes.vue';
import EntityTypes from './EntityTypes.vue';
import PropertyTypes from './PropertyTypes.vue';

@Component({
  name: 'DatasetSchema',
  components: {
    DatasetTypes,
    EntityTypes,
    PropertyTypes
  }
})
export default class DatasetSchema extends Vue {
  activeTab = 0;
}
