

















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({ name: 'Message' })
export default class Message extends Vue {
  @Prop({ type: String, default: 'info' }) readonly variant!: string;
  @Prop({ type: Boolean, default: false }) readonly hasIcon!: boolean;

  get computedIcon() {
    switch (this.variant) {
      case 'info':
        return 'fa-info-circle';
      case 'success':
        return 'fa-check-circle';
      case 'warning':
        return 'fa-exclamation-triangle';
      case 'danger':
        return 'fa-exclamation-circle';
      default:
        return null;
    }
  }
  get variantClass() {
    return 'is-' + this.variant;
  }
  get textClass() {
    return 'has-text-' + this.variant;
  }
}
