// @ts-nocheck
import Vue_, { VueConstructor } from 'vue';
import { registerComponent } from '@movici-flow-common/plugins';
import MovAction from './Action.vue';
import MovActionBar from './ActionBar.vue';
import MovRawConfig from './RawConfig.vue';
import MovSaveCancel from './SaveCancel.vue';
import MovPicker from './Picker.vue';
import MovResourceSearch from './list/ResourceSearch.vue';
import MovTag from './Tag.vue';

export const components = {
  MovAction,
  MovActionBar,
  MovRawConfig,
  MovSaveCancel,
  MovPicker,
  MovResourceSearch,
  MovTag
};

type GlobalOpts = {
  additionalComponents?: Record<string, VueConstructor<Vue_>>;
};

export default (() => {
  const Globals_ = {
    install(Vue: typeof Vue_, opts?: GlobalOpts) {
      if (opts?.additionalComponents) {
        components = { ...components, ...opts.additionalComponents };
      }

      Object.entries(components).forEach(([name, component]) => {
        registerComponent(Vue, component, name);
      });
    }
  };

  return Globals_;
})();
