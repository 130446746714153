














import { Component, Prop, Vue } from 'vue-property-decorator';
import { ColorLegendItem } from '@movici-flow-common/types';
import { colorTripleToHex } from '@movici-flow-common/visualizers/maps/colorMaps';

@Component({ name: 'ColorGradientLegend' })
export default class ColorGradientLegend extends Vue {
  @Prop({ type: Object }) readonly value!: ColorLegendItem;

  gradientColorStyle(item: ColorLegendItem): string {
    const colors = item.colorLegends.map(cl => cl[1]),
      gradientString = [...colors.map(color => colorTripleToHex(color))].join();
    return 'background: linear-gradient(90deg, ' + gradientString + ')';
  }

  gradientColorLabel(item: ColorLegendItem): string[] {
    return item.colorLegends.map(cl => cl[0]);
  }
}
