








































import { Component, Prop, Vue } from 'vue-property-decorator';
import { CameraOptions } from '@movici-flow-common/types';

const DEFAULT_MIN_ZOOM = 0;
const DEFAULT_MAX_ZOOM = 20;
@Component({ name: 'NavigationControl' })
export default class NavigationControl extends Vue {
  @Prop({ type: Boolean, default: false }) readonly isRight!: boolean;
  @Prop({ type: Object }) readonly value?: CameraOptions;
  @Prop({ type: Object }) readonly centerCamera?: CameraOptions;

  get bearing() {
    return this.value?.bearing ?? 0;
  }

  get zoom() {
    return this.value?.zoom ?? 0;
  }

  set zoom(val: number) {
    const minZoom = this.value?.minZoom ?? DEFAULT_MIN_ZOOM;
    const maxZoom = this.value?.maxZoom ?? DEFAULT_MAX_ZOOM;
    val = Math.min(Math.max(val, minZoom), maxZoom);

    this.updateViewState({ zoom: val });
  }

  updateViewState(viewState: Partial<CameraOptions>) {
    this.$emit('input', Object.assign({}, this.value, viewState));
  }
}
