



































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ComposableVisualizerInfo } from '@movici-flow-common/visualizers/VisualizerInfo';
import { colorTripleToHex } from '@movici-flow-common/visualizers/maps/colorMaps';
import { FlowVisualizerType, RGBAColor } from '@movici-flow-common/types';

@Component({ name: 'VisualizerSummary' })
export default class VisualizerSummary extends Vue {
  @Prop({ type: Object, required: true }) readonly value!: ComposableVisualizerInfo;
  @Prop({ type: Number, default: 0 }) readonly progress!: number;
  @Prop({ type: Boolean, default: false }) readonly show!: boolean;

  get colorByValue() {
    return this.value?.settings?.color?.byValue;
  }

  get colorStatic() {
    return this.value?.settings?.color?.static;
  }

  get typeIcon() {
    switch (this.value.settings?.type) {
      case FlowVisualizerType.POINTS:
        return ['fa-vis-info-' + FlowVisualizerType.POINTS, 'fak'];
      case FlowVisualizerType.LINES:
        return ['fa-vis-info-' + FlowVisualizerType.LINES, 'fak'];
      case FlowVisualizerType.POLYGONS:
        return ['fa-vis-info-' + FlowVisualizerType.POLYGONS, 'fak'];
      case FlowVisualizerType.ARCS:
        return ['fa-vis-info-' + FlowVisualizerType.ARCS, 'fak'];
      case FlowVisualizerType.ICONS:
        return ['map-marker-alt', 'far'];
      default:
        return [];
    }
  }

  get linearGradient() {
    const gradientString = this.value?.settings?.color?.byValue?.colors
      .map(color => colorTripleToHex(color[1]))
      .join();
    return 'linear-gradient(90deg,' + gradientString + ')';
  }

  convertColor(color: RGBAColor) {
    return colorTripleToHex(color);
  }
}
