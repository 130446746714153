import { Store } from 'vuex';
import { getModule } from 'vuex-module-decorators';
import ApiStore from './modules/ApiStore';
import AuthorizationStore from './modules/AuthorizationStore';
import CurrentUserStore from './modules/CurrentUserStore';
import DatasetGeneratorStore from './modules/DatasetGeneratorStore';
import DatasetSchemaStore from './modules/DatasetSchemaStore';
import DatasetStore from './modules/DatasetStore';
import GeneralStore from './modules/GeneralStore';
import ModelTypeStore from './modules/ModelTypeStore';
import OrganisationStore from './modules/OrganisationStore';
import ProjectStore from './modules/ProjectStore';
import ScenarioStore from './modules/ScenarioStore';
import SimulationStore from './modules/SimulationStore';
import TimelineStore from './modules/TimelineStore';
import UserStore from './modules/UserStore';
import ViewStore from './modules/ViewStore';

let apiStore: ApiStore,
  authorizationStore: AuthorizationStore,
  currentUserStore: CurrentUserStore,
  datasetSchemaStore: DatasetSchemaStore,
  datasetGeneratorStore: DatasetGeneratorStore,
  datasetStore: DatasetStore,
  generalStore: GeneralStore,
  modelTypeStore: ModelTypeStore,
  organisationStore: OrganisationStore,
  projectStore: ProjectStore,
  scenarioStore: ScenarioStore,
  simulationStore: SimulationStore,
  timelineStore: TimelineStore,
  userStore: UserStore,
  viewStore: ViewStore;

function initStores(store: Store<unknown>): void {
  apiStore = getModule(ApiStore, store);
  authorizationStore = getModule(AuthorizationStore, store);
  datasetGeneratorStore = getModule(DatasetGeneratorStore, store);
  datasetSchemaStore = getModule(DatasetSchemaStore, store);
  datasetStore = getModule(DatasetStore, store);
  currentUserStore = getModule(CurrentUserStore, store);
  generalStore = getModule(GeneralStore, store);
  modelTypeStore = getModule(ModelTypeStore, store);
  organisationStore = getModule(OrganisationStore, store);
  projectStore = getModule(ProjectStore, store);
  scenarioStore = getModule(ScenarioStore, store);
  simulationStore = getModule(SimulationStore, store);
  timelineStore = getModule(TimelineStore, store);
  userStore = getModule(UserStore, store);
  viewStore = getModule(ViewStore, store);
}

export {
  initStores,
  apiStore,
  authorizationStore,
  currentUserStore,
  datasetGeneratorStore,
  datasetSchemaStore,
  datasetStore,
  generalStore,
  modelTypeStore,
  organisationStore,
  projectStore,
  scenarioStore,
  simulationStore,
  timelineStore,
  userStore,
  viewStore
};
