






















import { ButtonItem } from '@movici-flow-common/types';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({ name: 'MovButtons' })
export default class MovButtons extends Vue {
  @Prop({ type: Boolean, default: false }) readonly isPulledRight!: boolean;
  @Prop({ type: Boolean, default: false }) readonly isSticky!: boolean;
  @Prop({ type: String, default: '' }) readonly size!: string;
  @Prop({ type: Array }) readonly value!: ButtonItem[];
}
