





















































import { Component, Vue } from 'vue-property-decorator';
import { FlowSection, FlowSectionItem, User } from '../types';
import FlowExport from './FlowExport.vue';
import { flowStore, flowUIStore } from '../store/store-accessor'; // create getters for other store it interacts with?
import { buildFlowUrl } from '@movici-flow-common/utils';
import VERSION from '@movici-flow-common/version';

@Component({ name: 'FlowMain' })
export default class FlowMain extends Vue {
  get sectionMenu() {
    return flowUIStore.flowSections ?? [];
  }

  get collapse() {
    return flowUIStore.collapse;
  }

  get disableCollapser() {
    return flowUIStore.disableCollapser;
  }

  get hasProjectsCapabilities() {
    return flowStore.hasProjectsCapabilities;
  }

  get currentUser(): User | null {
    return flowStore.currentUser;
  }

  get userInitials(): string | null {
    return this.currentUser
      ? this.currentUser.firstname.slice(0, 1) + this.currentUser.lastname.slice(0, 1)
      : null;
  }

  get homeRoute() {
    return this.$flow.settings?.homeRoute ?? '';
  }

  get flowVersion() {
    return VERSION;
  }

  click(section: FlowSection) {
    if (section.type === 'callback') {
      section.callback();
    } else if (this.$route.path !== section.to) {
      this.toggleCollapse(false);

      this.$router.push(
        buildFlowUrl(section.to, {
          project: flowStore.project?.name,
          scenario: flowStore.scenario?.name
        })
      );
    } else {
      this.toggleCollapse(!this.collapse);
    }
  }

  isActive(name: string) {
    return this.$route.name?.includes(name);
  }

  toggleCollapse(value?: boolean) {
    flowUIStore.toggleCollapse(value);
  }

  createFlowSections() {
    const sections: FlowSection[] = [],
      defaultSections: FlowSection[] = [
        {
          name: FlowSectionItem.DATASETS,
          label: 'flow.datasets.label',
          icon: 'fa-dataset',
          iconPack: 'fak',
          enabled: !this.hasProjectsCapabilities,
          to: 'FlowDataset',
          type: 'route'
        },
        {
          name: FlowSectionItem.SCENARIO,
          label: 'flow.scenarios.label',
          icon: 'fa-scenario',
          iconPack: 'fak',
          enabled: !this.hasProjectsCapabilities,
          to: 'FlowScenario',
          type: 'route'
        },
        {
          name: FlowSectionItem.VISUALIZATION,
          label: 'flow.visualization.label',
          icon: 'map',
          iconPack: 'far',
          enabled: false,
          to: 'FlowVisualization',
          type: 'route'
        },
        {
          name: FlowSectionItem.EXPORT,
          label: 'flow.export.label',
          iconPack: 'fal',
          icon: 'file-download',
          enabled: false,
          type: 'callback',
          callback: () => {
            this.$oruga.modal.open({
              parent: this,
              width: 720,
              component: FlowExport,
              canCancel: ['x', 'escape']
            });
          }
        }
      ];

    if (this.hasProjectsCapabilities) {
      sections.push({
        name: FlowSectionItem.PROJECT,
        label: 'flow.projects.label',
        icon: 'fa-workspace',
        iconPack: 'fak',
        enabled: true,
        to: 'FlowProject',
        type: 'route'
      });
    }

    sections.push(...defaultSections);

    flowUIStore.setSections(sections);
  }

  async mounted() {
    this.createFlowSections();
  }

  created() {}
}
