






import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({ name: 'ResourceCounter' })
export default class ResourceCounter extends Vue {
  @Prop({ type: Number }) readonly filteredLength!: number;
  @Prop({ type: Number }) readonly checkedLength!: number;

  get checkedCount() {
    return `${this.checkedLength} / ${this.filteredLength}`;
  }

  get allChecked() {
    return this.checkedLength === this.filteredLength && this.checkedLength > 0;
  }
}
