








import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import DialogModal from '@/components/global-alt/DialogModal.vue';

@Component({ name: 'MovRawConfig' })
export default class MovRawConfig extends Vue {
  @Prop({ type: Object, default: () => new Object() }) readonly value!: unknown;
  @Prop({ type: String }) readonly label!: string;
  errors: string[] = [];
  formattedRawData = '';
  initialRawData = '';
  minRows = 20;

  isJsonString(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  @Watch('value', { immediate: true })
  initializeRawConfigFromProp(value: string) {
    this.formattedRawData = JSON.stringify(value, null, 2);
    this.initialRawData = this.formattedRawData;
  }

  validateBeforeSave() {
    if (!this.isJsonString(this.formattedRawData)) {
      this.$emit('error', ['The config contains invalid JSON']);
      return Promise.reject();
    } else {
      this.$emit('save', JSON.parse(this.formattedRawData));
    }
  }

  onCancel() {
    if (this.hasChanges) {
      this.confirmCancel();
    } else {
      this.$emit('cancel');
    }
  }

  confirmCancel() {
    this.$oruga.modal.open({
      parent: this,
      component: DialogModal,
      props: {
        canCancel: true,
        message: 'You have unsaved changes, do you want to discard them?',
        confirmText: 'Discard Changes',
        variant: 'warning',
        hasIcon: true,
        onConfirm: () => this.$emit('cancel')
      },
      trapFocus: true
    });
  }

  get numOfRows() {
    return Math.max(this.minRows, this.formattedRawData.split(/\r\n|\r|\n/).length);
  }

  get hasChanges() {
    return this.formattedRawData !== this.initialRawData;
  }
}
