




















import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  ButtonItem,
  Scenario,
  Simulation,
  TimeOrientedSimulationInfo
} from '@movici-flow-common/types';
import { EventBus } from '../../eventbus';
import LiveMode from './LiveMode.vue';
import ExternalModels from './ExternalModels.vue';
import Logs from './Logs.vue';
import { getStatusFromScenarioAndSimulation } from '@movici-flow-common/utils';
import { scenarioStore } from '@/store/store-accessor';

const logDefaultMessage = 'No logs available (yet)... Try the refresh button :)';

function externalModelsInScenario(scenario: Scenario | null): string[] {
  const models: string[] = [];
  if (!scenario?.models) {
    return models;
  }
  for (let i = 0; i < scenario.models.length; i++) {
    let model = scenario.models[i];
    if (model.type === 'external') {
      models.push(model.name);
    }
  }
  return models;
}

@Component({
  name: 'Overview',
  components: {
    LiveMode,
    ExternalModels,
    Logs
  }
})
export default class Overview extends Vue {
  @Prop({ type: Object, required: true }) readonly value!: Scenario;
  @Prop({ type: Object, required: true }) readonly simulation!: Simulation;
  minRows = 20;
  logs = logDefaultMessage;
  speed = null;
  oldSpeed = null;

  get buttons(): ButtonItem[] {
    return [
      {
        label: '' + this.$t('actions.cancel'),
        icon: 'times',
        iconPack: 'fas',
        event: 'cancel'
      },
      {
        variant: 'primary',
        label: '' + this.$t('actions.refresh'),
        icon: 'redo',
        iconPack: 'fas',
        event: 'reload'
      }
    ];
  }

  get status() {
    if (this.simulation) {
      return getStatusFromScenarioAndSimulation(this.value, this.simulation);
    } else {
      return this.value.status || 'unknown';
    }
  }

  get numOfRows() {
    return Math.max(this.minRows, this.logs.split(/\r\n|\r|\n/).length);
  }

  get liveMode() {
    return this.simulation && this.simulation.live_mode;
  }

  get externalModels() {
    return externalModelsInScenario(this.value);
  }

  get showExternalModels() {
    return (
      this.simulation &&
      this.simulation.status.toLowerCase() === 'running' &&
      this.externalModels.length
    );
  }

  get scenarioEndTime() {
    try {
      return (this.value.simulation_info as TimeOrientedSimulationInfo).duration;
    } catch (e) {
      if (e instanceof TypeError) {
        return null;
      }
      throw e;
    }
  }

  get currentTime() {
    if (!this.simulation) return 0;

    try {
      return this.simulation.current_time;
    } catch (e) {
      if (e instanceof TypeError) {
        return 0;
      }
      throw e;
    }
  }

  async reload() {
    if (this.simulation) {
      await scenarioStore.getSimulation(this.value.uuid);
      EventBus.$emit('scenarios-update-overview');
    }
  }

  onCancel() {
    this.$emit('cancel');
  }

  refreshThisTab(tabId: string) {
    if (tabId === 'overview') {
      this.reload();
    }
  }

  mounted() {
    this.logs = logDefaultMessage;
  }

  created() {
    EventBus.$on('scenarios-active-tab', this.refreshThisTab);
  }

  beforeDestroy() {
    EventBus.$off('scenarios-active-tab', this.refreshThisTab);
  }
}
