



















import { Component, Prop, Vue } from 'vue-property-decorator';
import { IconClause } from '@movici-flow-common/types';
import IconButtonSelector from './IconButtonSelector.vue';
import { MAPPED_ICONS } from '@movici-flow-common/visualizers/visualizerModules/iconCommon';

@Component({
  name: 'ShapeStaticConfigurator',
  components: {
    IconButtonSelector
  }
})
export default class ShapeStaticConfigurator extends Vue {
  @Prop({ type: Object, default: null }) readonly value!: IconClause | null;

  get currentShape() {
    return this.value?.static?.icon ?? null;
  }
  get iconOptions() {
    return MAPPED_ICONS.shapes;
  }
  updateIcon(icon: string | null) {
    this.updateSettings(!icon ? {} : { static: { icon } });
  }

  updateSettings(updatedClause: IconClause) {
    this.$emit('input', updatedClause as IconClause);
  }
}
