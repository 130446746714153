var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MapVis',{ref:"mapVis",attrs:{"layer-infos":_vm.layers,"view-state":_vm.viewState,"scale":""},on:{"update:viewState":function($event){_vm.viewState=$event},"update:view-state":function($event){_vm.viewState=$event}},scopedSlots:_vm._u([{key:"control-zero",fn:function(ref){
var map = ref.map;
var popup = ref.popup;
return [(popup.mapPopups.length)?_vm._l((popup.mapPopups),function(p,i){return _c('MapEntityPopup',{key:i,attrs:{"value":p,"map":map,"view-state":_vm.viewState},on:{"toggle":function($event){return popup.togglePosition(p)},"close":function($event){return popup.remove(p)}}})}):_vm._e()]}},{key:"control-left",fn:function(ref){
var map = ref.map;
var onViewstateChange = ref.onViewstateChange;
var basemap = ref.basemap;
var setBasemap = ref.setBasemap;
return [(_vm.hasGeocodeCapabilities)?_c('SearchBar',{attrs:{"map":map,"view-state":_vm.viewState},on:{"update:view-state":function($event){return onViewstateChange($event)}}}):_vm._e(),_c('NavigationControl',{attrs:{"value":_vm.viewState,"center-camera":_vm.centerCamera},on:{"input":function($event){return onViewstateChange($event)}}}),_c('BaseMapControl',{attrs:{"value":basemap},on:{"input":setBasemap}})]}},{key:"control-right",fn:function(ref){
var popup = ref.popup;
return [_c('EntitySelector',{attrs:{"summary":_vm.summary,"currentDataset":_vm.value},on:{"setLayerInfos":_vm.setLayerInfos}}),(popup.rightSidePopups.length)?_vm._l((popup.rightSidePopups),function(p,i){return _c('RightSidePopup',{key:i,attrs:{"value":p},on:{"toggle":function($event){return popup.togglePosition(p)},"close":function($event){return popup.remove(p)}}})}):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }