


































import { Component, Vue } from 'vue-property-decorator';
import { authorizationStore } from '@/store/store-accessor';
import { UUID } from '@movici-flow-common/types';
import { Role } from '@/types';

@Component({ name: 'UserRoleDialog' })
export default class UserRoleDialog extends Vue {
  uuid: UUID | null = null;
  scopeName: string | null = null;
  scopeType: string | null = null;
  role: Role | null = null;

  get scopes() {
    return authorizationStore.scopesMap;
  }

  get roles() {
    return authorizationStore.roles;
  }

  get scopeTypes() {
    return Object.keys(this.scopes);
  }

  get scopeNames() {
    return this.scopeType && this.scopes[this.scopeType];
  }

  async getScopesAndRoles() {
    await authorizationStore.getScopes();
    await authorizationStore.getRoles();
  }

  getThisUserRole() {
    return {
      scope: `${this.scopeType}:${this.scopeName}`,
      role: this.role
    };
  }

  save() {
    this.$emit('new-user-role', this.getThisUserRole());
    this.$emit('close');
  }

  mounted() {
    this.getScopesAndRoles();
  }
}
