












































import { Component, Mixins, Prop, Ref, Watch } from 'vue-property-decorator';
import isEqual from 'lodash/isEqual';
import EntitySelector from './EntitySelector.vue';
import SearchBar from '@movici-flow-common/components/map/controls/SearchBar.vue';
import NavigationControl from '@movici-flow-common/components/map/controls/NavigationControl.vue';
import BaseMapControl from '@movici-flow-common/components/map/controls/BaseMapControl.vue';
import SummaryListing from '@movici-flow-common/mixins/SummaryListing';
import MapVis from '@movici-flow-common/components/map/MapVis.vue';
import { ComposableVisualizerInfo } from '@movici-flow-common/visualizers/VisualizerInfo';
import { CameraOptions, Dataset, DatasetSummary, Nullable } from '@movici-flow-common/types';
import defaults from '@movici-flow-common/components/map/defaults';
import { flowStore } from '@movici-flow-common/store/store-accessor';
import { ensureProjection, transformBBox } from '@movici-flow-common/crs';
import MapEntityPopup from '@movici-flow-common/components/map_widgets/MapEntityPopup.vue';
import RightSidePopup from '@movici-flow-common/components/map_widgets/RightSidePopup.vue';

@Component({
  name: 'DatasetViewer',
  components: {
    MapVis,
    EntitySelector,
    SearchBar,
    NavigationControl,
    BaseMapControl,
    MapEntityPopup,
    RightSidePopup
  }
})
export default class DatasetViewer extends Mixins(SummaryListing) {
  @Prop({ type: Object }) readonly value?: Dataset;
  @Ref('mapVis') readonly mapVisEl!: MapVis;
  layers: ComposableVisualizerInfo[] = [];
  viewState: Nullable<CameraOptions> = defaults.viewState();
  centerCamera: Nullable<CameraOptions> | null = null;

  get hasGeocodeCapabilities() {
    return flowStore.hasGeocodeCapabilities;
  }

  setLayerInfos(layerInfos: ComposableVisualizerInfo[]) {
    if (!isEqual(this.layers, layerInfos)) {
      this.layers = layerInfos;
    }
  }

  @Watch('value', { immediate: true })
  afterValue(curr?: Dataset, old?: Dataset) {
    if (curr) {
      this.currentDatasetUUID = curr.uuid;

      if (curr !== old) {
        this.setLayerInfos([]);
      }
    }
  }

  @Watch('summary')
  zoomToSummaryBBox(summary: DatasetSummary) {
    if (summary.bounding_box) {
      let bounding_box = summary.bounding_box;
      ensureProjection(summary.epsg_code).then(() => {
        this.mapVisEl.zoomToBBox(transformBBox(bounding_box, summary.epsg_code));
        this.centerCamera = this.viewState;
      });
    }
  }
}
