import { RemoteApplicationSettings } from '@movici-flow-common/types';
import { AxiosRequestConfig } from 'axios';
import { BaseRequest } from '@/api/requests/base';

export class GetGlobalSettings extends BaseRequest<RemoteApplicationSettings> {
  makeRequest(): AxiosRequestConfig {
    return {
      method: 'get',
      url: `/static/settings/settings.json`
    };
  }
}

export class GetPrivacyPolicy extends BaseRequest<string> {
  lang: 'nl' | 'en';
  constructor(config?: { language: 'nl' | 'en' }) {
    super();
    this.lang = config?.language ?? 'en';
  }
  makeRequest(): AxiosRequestConfig {
    return {
      method: 'get',
      url: `/static/privacy_policy/${this.lang}.html`
    };
  }
}
