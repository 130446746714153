



























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ResourceSearch from '../global-alt/list/ResourceSearch.vue';
import ResourceCounter from '../global-alt/list/ResourceCounter.vue';

@Component({ name: 'ResourceList', components: { ResourceSearch, ResourceCounter } })
export default class ResourceList<T> extends Vue {
  @Prop({ type: Array }) readonly value!: T[]; // items from the list
  @Prop({ type: Array }) readonly checkedRows!: T[];
  @Prop({ type: Array, default: () => [] }) readonly filterKeys!: string[];
  @Prop({ type: String, default: '' }) readonly defaultSort!: string;
  @Prop({ type: String, default: 'uuid' }) readonly uuidKey!: string;
  isPaginated = false;
  viewAll = false;
  filteredItems: T[] = [];

  get perPage() {
    return this.viewAll ? this.filteredLength : 50;
  }

  get checked() {
    return this.checkedRows;
  }

  set checked(val) {
    this.$emit('update:checkedRows', val);
  }

  get checkedLength() {
    return this.checked.length;
  }

  get filteredLength() {
    return this.filteredItems.length;
  }

  get allChecked() {
    return this.filteredLength === this.checkedLength && this.checkedLength > 0;
  }

  set allChecked(select: boolean) {
    this.checked = select ? this.filteredItems : [];
  }

  get checkedTooltipLabel() {
    return this.allChecked ? this.$t('misc.deselectAllPages') : this.$t('misc.selectAllPages');
  }

  get noResourcesDefault() {
    return !this.$scopedSlots.placeholder && (!this.value || !this.value.length);
  }

  @Watch('filteredItems')
  afterFilteredItems() {
    this.checked = [];

    if (this.filteredLength > 0 && !this.isPaginated) {
      this.isPaginated = this.filteredLength > this.perPage;
    }
  }
}
