






























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Project, CameraOptions, Nullable } from '../types';
import MapVis from './map/MapVis.vue';
import FlowContainer from './FlowContainer.vue';
import pick from 'lodash/pick';
import defaults from './map/defaults';
import SearchBar from './map/controls/SearchBar.vue';
import NavigationControl from './map/controls/NavigationControl.vue';
import BaseMapControl from './map/controls/BaseMapControl.vue';
import ProjectInfoBox from './info_box/ProjectInfoBox.vue';
import { flowStore, flowUIStore, flowVisualizationStore } from '../store/store-accessor';
import { buildFlowUrl } from '@movici-flow-common/utils';
import { MoviciError } from '@movici-flow-common/errors';

@Component({
  name: 'FlowProject',
  components: {
    FlowContainer,
    MapVis,
    ProjectInfoBox,
    SearchBar,
    NavigationControl,
    BaseMapControl
  }
})
export default class FlowProject extends Vue {
  @Prop({ type: String }) readonly currentProjectName?: string;
  @Prop({ type: String }) readonly currentScenarioName?: string;

  viewState: Nullable<CameraOptions> = defaults.viewState();

  get projects() {
    return flowStore.projects;
  }

  get currentProject() {
    return flowStore.project;
  }

  get toDatasets() {
    return buildFlowUrl('FlowDataset', {
      project: this.currentProjectName,
      scenario: this.currentScenarioName
    });
  }

  get toScenario() {
    return buildFlowUrl('FlowScenario', {
      project: this.currentProjectName,
      scenario: this.currentScenarioName
    });
  }

  get details() {
    if (this.currentProject) {
      const details = pick(this.currentProject, ['created_on', 'updated_on', 'tags']);
      details.created_on = this.$options.filters?.dateString(details.created_on);
      return details;
    }
    return null;
  }

  get countDetails() {
    if (this.currentProject) {
      return pick(this.currentProject, ['dataset_count', 'scenario_count']);
    }
    return null;
  }

  // Map Vis getters
  get validLayers() {
    return [];
  }

  get hasGeocodeCapabilities() {
    return flowStore.hasGeocodeCapabilities;
  }

  /**
   * Watcher for current project, receives a project object, sets in the store and updates main Flow component.
   * If necessary, updates the route with its name as the project query parameter
   */
  async setProject(project: Project) {
    await flowStore.setCurrentFlowProject(project);

    // this replaces the query string with project
    if (this.currentProjectName !== project.name) {
      await this.$router.push(buildFlowUrl('FlowProject', { project: project?.name }));
      flowVisualizationStore.updateCurrentView(null);
    }
  }

  async mounted() {
    // If we don't have projects, go directly do datasets
    if (!flowStore.hasProjectsCapabilities) {
      await this.$router.push(buildFlowUrl('FlowDataset'));
    } else {
      flowUIStore.setLoading({ value: true, msg: 'Loading workspaces...' });

      try {
        await flowStore.setupFlowStore({ config: { currentProjectName: this.currentProjectName } });

        flowUIStore.setLoading({ value: false });
      } catch (error) {
        if (error instanceof MoviciError) {
          await error.handleError({
            $t: this.$t.bind(this),
            $router: this.$router,
            query: {
              project: this.currentProject?.name
            }
          });
        }
        flowUIStore.setLoading({ value: false });
      }
    }
  }
}
