
















































import { apiStore, currentUserStore } from '@/store/store-accessor';
import { Component, Vue } from 'vue-property-decorator';

@Component({ name: 'NavBar' })
export default class NavBar extends Vue {
  isOpen = false;

  get currentUser() {
    return currentUserStore.user?.username;
  }

  get fullName() {
    return currentUserStore.fullName;
  }

  async logout() {
    await apiStore.doLogout();

    if (this.$route.name !== 'Home') {
      this.$router.push({ name: 'Home' });
    }
  }

  mounted() {
    currentUserStore.getCurrentUser();
  }
}
