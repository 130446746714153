



































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import ValidationProvider from '@movici-flow-common/mixins/ValidationProvider';
import FormValidator, { isPositive } from '@movici-flow-common/utils/FormValidator';

@Component({ name: 'MinMaxPixels' })
export default class MinMaxPixels extends Mixins(ValidationProvider) {
  @Prop({ type: Number, default: null }) readonly minPixels!: number | null;
  @Prop({ type: Number, default: null }) readonly maxPixels!: number | null;
  @Prop({ type: String }) readonly units!: string;
  @Prop({ type: Object, required: true }) declare readonly validator: FormValidator;

  get minPixels_() {
    return this.minPixels;
  }

  set minPixels_(val: number | null) {
    this.validator?.touch('minPixels');
    this.$emit('update:minPixels', val);
  }

  get maxPixels_() {
    return this.maxPixels;
  }

  set maxPixels_(val: number | null) {
    this.validator?.touch('maxPixels');
    this.$emit('update:maxPixels', val);
  }

  @Watch('units')
  afterUnits(curr: 'pixels' | 'meters', old: 'pixels' | 'meters') {
    if (curr !== old) {
      this.validator?.touch('minPixels');
      this.validator?.touch('maxPixels');
    }
  }

  setupValidator() {
    this.validator?.configure({
      validators: {
        minPixels: () => {
          if (this.units === 'pixels') return;
          return isPositive(this.minPixels, 'Min size');
        },

        maxPixels: () => {
          if (this.units === 'pixels') return;
          return isPositive(this.maxPixels, 'Max size');
        },

        minMaxPixels: {
          depends: ['minPixels', 'maxPixels'],
          validator: () => {
            if (this.units === 'pixels') return;
            if (
              this.minPixels != undefined &&
              this.maxPixels != undefined &&
              this.minPixels > this.maxPixels
            )
              return 'Max size must be at least min size.';
          }
        }
      },
      onValidate: e => (this.errors = e)
    });
  }

  created() {
    this.setupValidator();
  }

  beforeDestroy() {
    this.destroyValidator();
  }
}
