Taken and adapted from Buefy
https://github.com/buefy/buefy/blob/a92edafb1b5eaf3e01f73b18aebdd995535e68be/src/components/dialog/Dialog.vue

<template>
  <div class="modal-card animation-content">
    <header class="modal-card-head" v-if="title">
      <p class="modal-card-title">{{ title }}</p>
    </header>

    <section class="modal-card-body" :class="{ 'is-titleless': !title, 'is-flex': hasIcon }">
      <div class="media">
        <div class="media-left" v-if="hasIcon && (icon || iconByType)">
          <o-icon :icon="icon ? icon : iconByType" :variant="variant" size="large" />
        </div>
        <div class="media-content">
          <p>
            <template v-if="$slots.default">
              <slot />
            </template>
            <template v-else>
              <div v-html="message" />
            </template>
          </p>

          <div v-if="type === 'prompt'" class="field">
            <div class="control">
              <input
                v-model="prompt"
                class="input"
                ref="input"
                :class="{ 'is-danger': validationMessage }"
                v-bind="inputAttrs"
                @keydown.enter="confirm"
              />
            </div>
            <p class="help is-danger">{{ validationMessage }}</p>
          </div>
        </div>
      </div>
    </section>

    <footer class="modal-card-foot is-justify-content-flex-end">
      <o-button v-if="canCancel" ref="cancelButton" @click="cancel('button')" size="small">{{
        cancelText
      }}</o-button>
      <o-button :variant="variant" ref="confirmButton" @click="confirm" size="small"
        >{{ confirmText }}
      </o-button>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'DialogModal',
  props: {
    title: String,
    message: String,
    icon: String,
    hasIcon: Boolean,
    variant: {
      type: String,
      default: 'primary'
    },
    size: String,
    confirmText: {
      type: String,
      default: () => {
        return 'OK';
      }
    },
    canCancel: {
      type: Boolean,
      default: true
    },
    cancelText: {
      type: String,
      default: () => {
        return 'Cancel';
      }
    },
    type: {
      type: String,
      default: 'confirm'
    },
    inputAttrs: {
      type: Object,
      default: () => ({})
    },
    onConfirm: {
      type: Function,
      default: () => {}
    },
    onCancel: {
      type: Function,
      default: () => {}
    },
    closeOnConfirm: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      prompt: this.type === 'prompt' ? this.inputAttrs.value || '' : '',
      isActive: false,
      validationMessage: ''
    };
  },
  computed: {
    /**
     * Icon name (MDI) based on the type.
     */
    iconByType() {
      switch (this.variant) {
        case 'info':
          return 'info-circle';
        case 'success':
          return 'check-circle';
        case 'warning':
          return 'exclamation-triangle';
        case 'danger':
          return 'exclamation-circle';
        default:
          return null;
      }
    }
  },
  methods: {
    /**
     * If it's a prompt Dialog, validate the input.
     * Call the onConfirm prop (function) and close the Dialog.
     */
    confirm() {
      if (this.$refs.input !== undefined) {
        if (!this.$refs.input.checkValidity()) {
          this.validationMessage = this.$refs.input.validationMessage;
          this.$nextTick(() => this.$refs.input.select());
          return;
        }
      }
      this.$emit('confirm', this.prompt);
      this.onConfirm(this.prompt, this);
      if (this.closeOnConfirm) this.close();
    },
    cancel() {
      if (this.canCancel) {
        this.onCancel(this.prompt, this);
        this.close();
      }
    },
    close() {
      this.$emit('close');
    }
  },
  mounted() {
    if (typeof this.inputAttrs.required === 'undefined') {
      this.$set(this.inputAttrs, 'required', true);
    }
  }
};
</script>
<style lang="scss" scoped>
.modal-card {
  max-width: 460px;
  width: auto;
  .modal-card-body {
    color: $black;
    &.is-titleless {
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
    }
  }

  .modal-card-foot {
    border: 0;
    padding-top: 0;
    .button {
      display: inline;
      min-width: 5em;
      font-weight: 600;
    }
  }
  .modal-card-head {
    border: 0;
    padding-bottom: 0;
  }
}
</style>
