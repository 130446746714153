













































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Project, Scenario } from '@movici-flow-common/types';
import { modelTypeStore, projectStore } from '@/store/store-accessor';

@Component({ name: 'Configurator' })
export default class Configurator extends Vue {
  @Prop({ type: Object, default: () => new Object() }) readonly value!: Scenario;
  impactindicatorGroup: string[] = [];
  activeTab = 0;
  errors: string[] = ["The editor functionality doesn't work yet"];

  get project(): Project | null {
    return projectStore.currentProject;
  }

  get model_types() {
    return modelTypeStore.modelTypes;
  }

  getModelTypes() {
    modelTypeStore.getModelTypes().catch(err => console.error(err));
  }

  mounted() {
    this.getModelTypes();
  }
}
