import { render, staticRenderFns } from "./FlowProject.vue?vue&type=template&id=70747aec&scoped=true&"
import script from "./FlowProject.vue?vue&type=script&lang=ts&"
export * from "./FlowProject.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70747aec",
  null
  
)

export default component.exports