


























































import { Component, Mixins } from 'vue-property-decorator';
import { EntityType, Field } from '@movici-flow-common/types';
import { datasetSchemaStore, generalStore } from '@/store/store-accessor';
import EditModal from '@/components/common/EditModal.vue';
import ResourceListConsumer from '@/components/common/ResourceListConsumer';
import ResourceList from '@/components/common/ResourceList.vue';
import EditModalConsumer from '@/components/common/EditModalConsumer';

@Component({
  name: 'EntityTypes',
  components: { EditModal, ResourceList }
})
export default class EntityTypes extends Mixins<
  ResourceListConsumer<EntityType>,
  EditModalConsumer<EntityType>
>(ResourceListConsumer, EditModalConsumer) {
  resourceTerm = 'entity type';
  defaultSort = 'name';
  filterKeys = ['name'];
  fields: Field<EntityType>[] = [
    {
      name: '' + this.$t('properties.name'),
      boundVariable: 'name',
      type: 'text',
      required: true
    }
  ];

  async getAll() {
    return await datasetSchemaStore.getEntityTypes();
  }

  getEmptyItem(): EntityType {
    return {
      name: ''
    };
  }

  async addItem(item: EntityType) {
    if (await datasetSchemaStore.addEntityType(item)) {
      await this.resetList();
      return true;
    }
    return false;
  }

  async updateItem(item: EntityType) {
    if (await datasetSchemaStore.updateEntityType(item as Required<EntityType>)) {
      await this.resetList();
      return true;
    }
    return false;
  }

  getDeleteItemRequests(itemList: EntityType[]) {
    return itemList.map(element => {
      return datasetSchemaStore.deleteEntityType(element as Required<EntityType>);
    });
  }

  setLoading(payload: { value: boolean; msg?: string }) {
    generalStore.setLoading(payload);
  }
}
