






















import { Component, Vue, Watch } from 'vue-property-decorator';
import {
  requireAnyOf,
  anyProjectRole,
  anyOrganisation,
  serviceRole,
  globalRole,
  globalOwner
} from '@/utils/roleHelpers';
import { RoleBinding, RoleType } from '@/types';
import { currentUserStore, generalStore } from '@/store/store-accessor';

interface SideBarItem {
  name: string;
  routeName: string;
  enabled?: boolean;
  show: (roles: RoleBinding[]) => boolean;
}

interface SideBarCategory {
  categoryName: string;
  items: SideBarItem[];
}

@Component({ name: 'SideBar' })
export default class SideBar extends Vue {
  navBarItems: SideBarCategory[] = [];
  activeCategories: SideBarCategory[] = [];

  get featureToggle() {
    return generalStore.featureToggle;
  }

  get roles(): RoleBinding[] {
    return currentUserStore.user?.roles ?? [];
  }

  @Watch('roles', { immediate: true })
  updateActiveCategories() {
    let active = [];

    for (let i = 0; i < this.navBarItems.length; i++) {
      let category = this.navBarItems[i];
      if (!category.items) {
        continue;
      }
      category.items = this.activeRoutes(category.items);
      if (category.items.length > 0) {
        active.push(category);
      }
    }

    this.activeCategories = active;
  }

  activeRoutes(sideBarItems: SideBarItem[]) {
    return sideBarItems.filter((sideBarItem: SideBarItem) => {
      let doShow = false;
      if (sideBarItem.show) {
        doShow = sideBarItem.show(this.roles);
      }

      return doShow && (sideBarItem.enabled ?? true);
    });
  }

  createSideBar() {
    this.navBarItems = [
      {
        categoryName: 'sidebar.general',
        items: [
          {
            name: 'sidebar.dashboard',
            routeName: 'Dashboard',
            show() {
              return true;
            }
          },
          {
            name: 'sidebar.moviciFlow',
            routeName: 'FlowMain',
            show: () => {
              return this.featureToggle('flow');
            }
          }
        ]
      },
      {
        categoryName: 'sidebar.simulationManagement',
        items: [
          {
            name: 'resources.projects',
            routeName: 'Projects',
            show(roles: RoleBinding[]) {
              return requireAnyOf(
                [
                  anyProjectRole(RoleType.viewer),
                  serviceRole('data_engine', RoleType.viewer),
                  anyOrganisation(RoleType.viewer),
                  globalRole(RoleType.viewer)
                ],
                roles
              );
            }
          },
          {
            name: 'resources.datasets',
            routeName: 'Datasets',
            show(roles: RoleBinding[]) {
              return requireAnyOf(
                [
                  anyProjectRole(RoleType.viewer),
                  serviceRole('data_engine', RoleType.viewer),
                  anyOrganisation(RoleType.viewer),
                  globalRole(RoleType.viewer)
                ],
                roles
              );
            }
          },
          {
            name: 'resources.scenarios',
            routeName: 'Scenarios',
            show(roles: RoleBinding[]) {
              return requireAnyOf(
                [
                  anyProjectRole(RoleType.viewer),
                  serviceRole('data_engine', RoleType.viewer),
                  anyOrganisation(RoleType.viewer),
                  globalRole(RoleType.viewer)
                ],
                roles
              );
            }
          },
          {
            name: 'resources.views',
            routeName: 'Views',
            show(roles: RoleBinding[]) {
              return requireAnyOf(
                [
                  anyProjectRole(RoleType.viewer),
                  serviceRole('data_engine', RoleType.viewer),
                  anyOrganisation(RoleType.viewer),
                  globalRole(RoleType.viewer)
                ],
                roles
              );
            }
          },
          {
            name: 'resources.dataset_generators',
            routeName: 'DatasetGenerators',
            show(roles: RoleBinding[]) {
              return requireAnyOf(
                [
                  anyProjectRole(RoleType.user),
                  serviceRole('dataset_generator', RoleType.viewer),
                  anyOrganisation(RoleType.admin),
                  globalRole(RoleType.user)
                ],
                roles
              );
            }
          }
        ]
      },
      {
        categoryName: 'sidebar.administration',
        items: [
          {
            name: 'resources.organisations',
            routeName: 'Organisations',
            show(roles: RoleBinding[]) {
              return requireAnyOf(
                [
                  serviceRole('auth_server', RoleType.admin),
                  anyOrganisation(RoleType.admin),
                  globalRole(RoleType.admin),
                  globalOwner()
                ],
                roles
              );
            }
          },
          {
            name: 'resources.users',
            routeName: 'Users',
            show(roles: RoleBinding[]) {
              return requireAnyOf(
                [
                  serviceRole('auth_server', RoleType.admin),
                  anyOrganisation(RoleType.admin),
                  globalRole(RoleType.admin),
                  globalOwner()
                ],
                roles
              );
            }
          },
          {
            name: 'resources.scopes',
            routeName: 'Scopes',
            show(roles: RoleBinding[]) {
              return requireAnyOf(
                [
                  serviceRole('auth_server', RoleType.admin),
                  globalRole(RoleType.admin),
                  globalOwner()
                ],
                roles
              );
            }
          },
          {
            name: 'resources.model_types',
            routeName: 'ModelTypes',
            show(roles: RoleBinding[]) {
              return requireAnyOf(
                [serviceRole('data_engine', RoleType.admin), globalRole(RoleType.admin)],
                roles
              );
            }
          },
          {
            name: 'resources.schema',
            routeName: 'Schema',
            show(roles: RoleBinding[]) {
              return requireAnyOf(
                [serviceRole('data_engine', RoleType.admin), globalRole(RoleType.admin)],
                roles
              );
            }
          }
        ]
      }
    ];
  }

  mounted() {
    this.createSideBar();
  }
}
