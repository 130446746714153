











































import { Component, Vue, Watch } from 'vue-property-decorator';
import isEmpty from 'lodash/isEmpty';

@Component({ name: 'PasswordDialog' })
export default class PasswordDialog extends Vue {
  password = '';
  confirmPassword = '';
  errors: Record<string, string[]> = {};
  validators: Record<string, Array<(value: string) => string | void>> = {
    password: [this.required, this.regexMatches],
    confirmPassword: [this.required, this.passwordsMatch]
  };
  regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,128}$/;

  get canSave() {
    return !!this.password && !!this.confirmPassword && !this.hasErrors;
  }

  save() {
    this.$emit('new-password', this.password);
    this.$emit('close');
  }

  validate(field: string, value: string) {
    const allErrors = { ...this.errors };
    delete allErrors[field];
    const errors: string[] = [];
    this.validators[field].forEach(validator => {
      let error = validator(value);
      if (error) errors.push(error);
    });

    if (errors.length) {
      allErrors[field] = errors;
    }
    this.errors = allErrors;
  }

  get hasErrors() {
    return !isEmpty(this.errors);
  }

  regexMatches(value: string) {
    if (!this.regex.exec(value)) return '' + this.$t('messages.passwordComplexity');
  }

  required(value: string) {
    if (!value) return 'This is a required field';
  }

  passwordsMatch() {
    if (this.password !== this.confirmPassword) return 'The passwords must match';
  }
  @Watch('password')
  passwordWatcher() {
    if (this.password) {
      this.validate('password', this.password);
    }
  }

  @Watch('password')
  @Watch('confirmPassword')
  confirmPasswordWatcher() {
    if (this.confirmPassword) {
      this.validate('confirmPassword', this.confirmPassword);
    }
  }
}
