


















import { flowUIStore } from '../store/store-accessor';
import { Component, Vue } from 'vue-property-decorator';

@Component({ name: 'FlowContainer' })
export default class FlowContainer extends Vue {
  get disableCollapser() {
    return flowUIStore.disableCollapser;
  }

  get collapse() {
    return flowUIStore.collapse;
  }

  get collapsedClass() {
    if (!this.disableCollapser) {
      if (this.collapse) return 'collapsed';
      else return 'expanded';
    }
    return '';
  }

  get loading() {
    return flowUIStore.loading;
  }

  mounted() {
    flowUIStore.toggleCollapse(false);
  }
}
