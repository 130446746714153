



















































import { Vue, Component, Watch } from 'vue-property-decorator';
import { currentUserStore } from '@/store/store-accessor';
import isEmpty from 'lodash/isEmpty';

@Component({ name: 'ForgotPassword' })
export default class ForgotPassword extends Vue {
  email: string | null = null;
  errors: Record<string, string[]> = {};
  validators: Record<string, Array<(value: string) => string | undefined>> = {
    email: [this.required, this.regexMatches]
  };
  regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  statusMessage: Record<string, string | null> = {
    logs: null,
    type: null
  };

  get canSubmit() {
    return !!this.email && !this.hasErrors();
  }

  onForgotPassword() {
    if (this.email) {
      currentUserStore
        .doForgotPassword({
          username: this.email
        })
        .then(() => {
          this.statusMessage = {
            logs: 'A reset link has been sent to your email. Please check your junk folder, if necessary.',
            variant: 'success'
          };
        })
        .catch(() => {
          this.statusMessage = {
            logs: 'Something went wrong while requesting forgot password.',
            variant: 'danger'
          };
        });
    }
  }

  validate(field: string, value: string) {
    delete this.errors[field];

    const errors: string[] = [];

    this.validators[field].forEach(validator => {
      const validated = validator(value);

      if (validated) errors.push(validated);
    });

    if (errors.length) {
      this.errors[field] = errors;
    }
  }

  hasErrors() {
    return !isEmpty(this.errors);
  }

  regexMatches(value: string) {
    if (!this.regex.exec(value)) return '' + this.$t('messages.emailComplexity');
  }

  required(value: string | null) {
    if (!value) return 'This is a required field';
  }

  @Watch('email')
  afterEmail() {
    if (this.email) {
      this.validate('email', this.email);
    }
  }
}
