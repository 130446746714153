

















































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { TimeOrientedSimulationInfo } from '../types';
import FlowContainer from './FlowContainer.vue';
import FlowLayerPicker from './widgets/FlowLayerPicker.vue';
import ScenarioInfoBox from './info_box/ScenarioInfoBox.vue';
import ExportForm from './export/ExportForm.vue';
import ExportLayerPicker from './export/ExportLayerPicker.vue';
import { ComposableVisualizerInfo } from '../visualizers/VisualizerInfo';
import ValidationProvider from '../mixins/ValidationProvider';
import FormValidator from '../utils/FormValidator';
import { flowStore, flowVisualizationStore } from '../store/store-accessor';

@Component({
  name: 'FlowExport',
  components: {
    FlowLayerPicker,
    FlowContainer,
    ScenarioInfoBox,
    ExportForm,
    ExportLayerPicker
  }
})
export default class FlowExport extends Mixins(ValidationProvider) {
  @Prop({ type: Object, default: null }) readonly value!: ComposableVisualizerInfo | null;
  selectedCVI: ComposableVisualizerInfo | null = null;
  exportConfig: { datasetName: string; entityGroup: string; timestamp: number } | null = null;
  validator: FormValidator | null = null;

  // mapVis vars
  get visualizers() {
    return flowVisualizationStore.visualizers;
  }

  get currentScenario() {
    return flowStore.scenario;
  }

  get timestamp() {
    return flowVisualizationStore.timestamp;
  }

  // Map Vis getters
  get timelineInfo(): TimeOrientedSimulationInfo | null {
    return flowStore.timelineInfo;
  }

  async exportData() {
    this.validator?.validate();

    if (this.hasErrors) {
      return;
    }

    if (this.exportConfig) {
      await flowStore.exportFromConfig(this.exportConfig);
    }
  }

  @Watch('value', { immediate: true })
  setValueAsSelected(value: ComposableVisualizerInfo | null) {
    this.selectedCVI = value;
  }

  setupValidator() {
    this.validator = new FormValidator();
  }

  created() {
    this.setupValidator();
  }
}
