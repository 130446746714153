import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import { initStores } from './store-accessor';
import { initFlowStores } from '@movici-flow-common/store/store-accessor';
import FlowStore from '@movici-flow-common/store/FlowStore';
import FlowUIStore from '@movici-flow-common/store/FlowUserInterfaceStore';
import GeocodeStore from '@movici-flow-common/store/GeocodeStore';
import ApiStore from './modules/ApiStore';
import AuthorizationStore from './modules/AuthorizationStore';
import CurrentUserStore from './modules/CurrentUserStore';
import DatasetGeneratorsStore from './modules/DatasetGeneratorStore';
import DatasetSchemaStore from './modules/DatasetSchemaStore';
import DatasetStore from './modules/DatasetStore';
import GeneralStore from './modules/GeneralStore';
import ModelTypeStore from './modules/ModelTypeStore';
import OrganisationStore from './modules/OrganisationStore';
import ProjectStore from './modules/ProjectStore';
import ScenarioStore from './modules/ScenarioStore';
import UserStore from './modules/UserStore';
import SimulationStore from './modules/SimulationStore';
import TimelineStore from './modules/TimelineStore';
import ViewStore from './modules/ViewStore';
import FlowVisualizationStore from '@movici-flow-common/store/FlowVisualizationStore';
Vue.use(Vuex);

const store = new Store({
  plugins: [initStores, initFlowStores],
  modules: {
    // local
    api: ApiStore,
    authorization: AuthorizationStore,
    currentUser: CurrentUserStore,
    datasetGenerator: DatasetGeneratorsStore,
    datasetSchema: DatasetSchemaStore,
    dataset: DatasetStore,
    general: GeneralStore,
    modelType: ModelTypeStore,
    organisation: OrganisationStore,
    project: ProjectStore,
    scenario: ScenarioStore,
    simulation: SimulationStore,
    timeline: TimelineStore,
    user: UserStore,
    view: ViewStore,
    // flow
    flow: FlowStore,
    flowVisualization: FlowVisualizationStore,
    flowUI: FlowUIStore,
    geocode: GeocodeStore
  }
});

export default store;
