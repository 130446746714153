import { Client } from '@movici-flow-common/api';
import {
  GetDatasets,
  GetDatasetData,
  GetScenarioState,
  GetDataset,
  GetDatasetDataAsBlob
} from '@/api/requests';
import { ComponentProperty, Dataset, DatasetWithData, UUID } from '@movici-flow-common/types';
import DatasetStore from '@/store/modules/DatasetStore';
import { DatasetService } from '@movici-flow-common/types/backend';

type getDataParams = {
  datasetUUID: UUID;
  entityGroup?: string;
  properties?: ComponentProperty[];
};

type getStateParams = {
  datasetUUID: UUID;
  scenarioUUID: UUID;
  entityGroup: string;
  timestamp: number;
  properties: ComponentProperty[];
};
export default class RemoteDatasetService implements DatasetService {
  client: Client;
  store?: DatasetStore;

  constructor(client: Client, store?: DatasetStore) {
    this.client = client;
    this.store = store;
  }

  async list(project_uuid?: string) {
    if (!project_uuid) return [];
    return (
      (await this.store?.getDatasets(project_uuid)) ??
      (await this.client.request(new GetDatasets(project_uuid))) ??
      []
    );
  }

  async getData<T>(params: getDataParams): Promise<DatasetWithData<T> | null> {
    const { datasetUUID, entityGroup, properties } = params;
    return await this.client.request(new GetDatasetData<T>(datasetUUID, entityGroup, properties));
  }

  async getState<T>(params: getStateParams): Promise<DatasetWithData<T> | null> {
    const { datasetUUID, scenarioUUID, entityGroup, properties, timestamp } = params;

    return await this.client.request(
      new GetScenarioState(datasetUUID, scenarioUUID, entityGroup, properties, timestamp)
    );
  }

  async getMetaData(datasetUUID: string): Promise<Dataset | null> {
    return await this.client.request(new GetDataset(datasetUUID));
  }

  async getDatasetAsBlob(params: { datasetUUID: string }): Promise<Blob | null> {
    return (await this.client.request(new GetDatasetDataAsBlob(params.datasetUUID)))?.data ?? null;
  }
}
