import { UUID } from './general';
import { Dataset } from './datasets';
import { Scenario, ScenarioDataset, ShortScenario } from './scenarios';
import { BoundingBox } from '@mapbox/geo-viewport';

export enum ImportantAttribute {
  DIPLAY_NAME = 'display_name',
  NAME = 'name',
  REFERENCE = 'reference'
}

export const IMPORTANT_ATTRIBUTES = Object.values(ImportantAttribute);

export interface DatasetSummary {
  count: number;
  entity_groups: EntityGroupSummary[];
  bounding_box?: BoundingBox;
  epsg_code?: number;
  general?: {
    enum?: Record<string, string[]>;
  };
}

export enum DatasetFormat {
  ENTITY_BASED = 'entity_based',
  UNSTRUCTURED = 'unstructured',
  BINARY = 'binary'
}

export interface DatasetSchema {
  dataset_types: DatasetType[];
  entity_types: EntityType[];
  property_types: PropertyType[];
}

export interface DatasetType {
  uuid?: UUID;
  name: string;
  format: DatasetFormat;
}

export interface EntityType {
  uuid?: UUID;
  name: string;
}

export interface EntityGroupSummary {
  name: string;
  count: number;
  properties: PropertySummary[];
}
export interface ComponentProperty {
  component: string | null;
  name: string;
}
export interface PropertyType extends ComponentProperty {
  uuid?: UUID;
  data_type: string;
  description?: string;
  unit?: string;
  enum_name?: string | null;
}

export interface ExportConfig {
  dataset: Dataset | ScenarioDataset | null;
  projectName: string;
  entityName: string;
  scenario?: ShortScenario | Scenario;
  timestamp?: number;
}
export interface PropertySummary extends PropertyType {
  min_val: number | null;
  max_val: number | null;
}
