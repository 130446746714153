








import { Component, Vue } from 'vue-property-decorator';

@Component({ name: 'MovActionMenu' })
export default class MovActionMenu extends Vue {}
