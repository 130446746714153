



















import { Component, Vue } from 'vue-property-decorator';
import NavBar from './home/NavBar.vue';
import SideBar from './home/SideBar.vue';
import Footer from './home/Footer.vue';

@Component({
  name: 'Console',
  components: {
    appNavbar: NavBar,
    appSidebar: SideBar,
    appFooter: Footer
  }
})
export default class Console extends Vue {}
