export default {
  tabs: {
    itemClass: 'tab-item',
    itemHeaderClass: 'tab-item-header',
    itemHeaderTextClass: 'tab-item-header-text',
    itemHeaderIconClass: 'tab-item-header-icon',
    tabItemWrapperClass: 'tab-item-wrapper'
  },
  menu: {
    itemActiveClass: 'is-active',
    itemDisabledClass: 'is-disabled'
  },
  dropdown: {
    menuActiveClass: 'is-active'
  }
};
