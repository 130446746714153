




































import { scenarioStore, simulationStore } from '@/store/store-accessor';
import { Scenario, ScenarioModel, Simulation, SimulationMode } from '@movici-flow-common/types';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { EventBus } from '../../eventbus';
import SpeedSelector from './SpeedSelector.vue';

function speedFromModels(models: ScenarioModel[]) {
  for (let i = 0; i < models.length; i++) {
    let model = models[i];
    if (model.type === 'live_mode') {
      return model.speed !== undefined ? (model.speed as number) : 1;
    }
  }
  return -1;
}

@Component({
  name: 'LiveMode',
  components: {
    SpeedSelector
  }
})
export default class LiveMode extends Vue {
  @Prop({ type: Object, required: true }) readonly value!: Scenario;
  @Prop({ type: Object, required: true }) readonly simulation!: Simulation;
  speed: number | null = null;
  oldSpeed: number | null = null;
  multipliers = {
    sec: 1,
    min: 60,
    hours: 60 * 60,
    days: 60 * 80 * 24
  };
  currentMultiplier = 60 * 60;
  forwardToValue = 1;

  get scenarioEndTime() {
    try {
      if (this.value.simulation_info?.mode === SimulationMode.TIME_ORIENTED)
        return this.value.simulation_info.duration;
      return null;
    } catch (e) {
      if (e instanceof TypeError) {
        return null;
      }
      throw e;
    }
  }

  get currentTime() {
    try {
      return this.simulation.current_time;
    } catch (e) {
      if (e instanceof TypeError) {
        return 0;
      }
      throw e;
    }
  }

  async getSpeed() {
    const data = await simulationStore.getSpeed(this.value.uuid),
      models = this.value.models;

    this.oldSpeed = this.speed;
    if (data && data.speed) {
      this.speed = data && data.speed;
    } else {
      this.speed = speedFromModels(models ?? []);
    }

    return this.getSimulation();
  }

  getSimulation() {
    return scenarioStore.getSimulation(this.value.uuid);
  }

  async updateSpeed(newSpeed: number) {
    if (this.speed !== null && newSpeed !== this.speed) {
      let oldSpeed = this.speed;
      this.speed = newSpeed;
      if (this.simulation.live_mode) {
        try {
          await simulationStore.updateSpeed({
            uuid: this.value.uuid,
            speed: newSpeed
          });
          await this.getSpeed();
        } catch {
          this.speed = oldSpeed;
        }
      }
    } else {
      this.speed = newSpeed;
    }
  }

  async forwardBy(seconds: number) {
    await this.getSimulation();
    return await this.forwardToSeconds(this.currentTime + seconds);
  }

  async forwardTo() {
    return await this.forwardToSeconds(this.currentMultiplier * this.forwardToValue);
  }

  async forwardToSeconds(seconds: number) {
    return await simulationStore.forwardTo({
      uuid: this.value.uuid,
      forward_to: seconds
    });
  }

  created() {
    EventBus.$on('scenarios-update-overview', this.getSpeed);
  }

  beforeDestroy() {
    EventBus.$off('scenarios-update-overview', this.getSpeed);
  }
}
