
















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({ name: 'MovPicker' })
export default class MovPicker extends Vue {
  @Prop({ type: Object, default: null }) readonly value!: unknown | null;
  @Prop({ type: String, default: 'default' }) readonly size!: string;
  @Prop({ type: String, default: '' }) readonly label!: string;
  @Prop({ type: String, default: '' }) readonly variant!: string;
  @Prop({ type: String, default: '' }) readonly placeholder!: string;
  @Prop({ type: String, default: '' }) readonly errorMessage!: string;
  @Prop({ type: Boolean, default: false }) readonly disabled!: boolean;
  @Prop({ type: Array, default: () => [] }) readonly items!: unknown[];
  @Prop({ type: Function, default: (obj: unknown) => String(obj) })
  readonly optionLabel!: (val: unknown) => string;

  get value_() {
    return this.value ?? null;
  }

  set value_(val: unknown) {
    this.$emit('input', val);
  }
}
