





























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ShortScenario, View } from '@movici-flow-common/types';
import { projectStore, scenarioStore, viewStore } from '@/store/store-accessor';

@Component({ name: 'ViewDetail' })
export default class ViewDetail extends Vue {
  @Prop({ type: String }) readonly uuid?: string;
  view: View | null = null;
  errors: string[] = [];

  get projects() {
    return projectStore.projects;
  }

  get activeProject() {
    return projectStore.activeProject;
  }

  get scenarios(): ShortScenario[] {
    return scenarioStore.scenarios;
  }

  get currentScenario(): ShortScenario | null {
    return scenarioStore.currentScenario;
  }

  get addMode(): boolean {
    return !this.uuid;
  }

  get editMode(): boolean {
    return !!this.uuid;
  }

  get title() {
    return this.addMode ? this.$t('view.addNew') : this.$t('view.edit');
  }

  @Watch('uuid', { immediate: true })
  async initializeThisView(uuid: string) {
    this.view = this.editMode ? await viewStore.getView(uuid) : this.getEmptyView();

    if (!this.scenarios.length && this.activeProject) {
      await scenarioStore.getScenariosWithSimulationInfo(this.activeProject.uuid);
      const scenario = this.scenarios.find(s => this.view?.scenario_uuid === s.uuid);

      if (scenario) {
        scenarioStore.setCurrentScenario(scenario);
      }
    }
  }

  getEmptyView(): View {
    return {
      name: '',
      config: {
        version: 1,
        visualizers: [],
        charts: []
      }
    };
  }

  async validateBeforeSave(view: View) {
    let resp;

    if (this.editMode && this.uuid) {
      resp = await this.updateView(view, this.uuid);
    } else if (this.currentScenario) {
      resp = await this.addView(view, this.currentScenario);
    }

    if (resp) {
      this.goBack();
    }
  }

  async updateView(view: View, viewUUID: string) {
    const resp = await viewStore.updateView({ viewUUID, view });
    if (resp) this.$flow.snackbar.successMessage('View successfully updated');
    return resp;
  }

  async addView(view: View, scenario: ShortScenario) {
    const resp = await viewStore.createView({ view, scenarioUUID: scenario.uuid });
    if (resp) this.$flow.snackbar.successMessage('View successfully added');
    return resp;
  }

  goBack() {
    this.$router.push({ name: 'Views' });
  }

  addErrors(errors: string[]) {
    if (Array.isArray(errors)) {
      this.errors = errors;
    } else if (typeof errors === 'string') {
      this.errors = [errors];
    } else {
      throw new Error('invalid error strings: ' + errors);
    }
  }
}
