






















import { RGBAColor } from '@movici-flow-common/types';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { colorTripleToHex, MoviciColors } from '@movici-flow-common/visualizers/maps/colorMaps';
import FlowColorPicker from './FlowColorPicker.vue';

@Component({
  name: 'ColorInput',
  components: {
    FlowColorPicker
  }
})
export default class ColorInput extends Vue {
  @Prop({ type: Array }) readonly value?: RGBAColor;
  @Prop({ type: Boolean, default: false }) readonly caret!: boolean;
  @Prop({ type: String, default: 'right' }) readonly colorPickerPosition!: string;
  showColorPicker = false;
  colorPickerPresets = Object.values(MoviciColors);
  active = false;

  get hexColor() {
    return this.value ? colorTripleToHex(this.value) : MoviciColors.VERY_DARK_GREY;
  }

  get translate() {
    switch (this.colorPickerPosition) {
      case 'top':
        return [-1, -30];
      case 'right':
        return [this.caret ? 64 : 44, -10];
      case 'top-right':
        return [32, -12];
      default:
        return [0, 0];
    }
  }

  closeColorPicker() {
    this.showColorPicker = false;
  }

  toggleColorPicker() {
    this.showColorPicker = !this.showColorPicker;
  }
}
