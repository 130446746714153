import { AxiosRequestConfig } from 'axios';
import uri from '@movici-flow-common/api/requests/uri';
import { Request } from '@movici-flow-common/api/requests';
import { FeedbackFormType, FeedbackRequestPayload } from '@/types';

export class SendFeedbackForm extends Request<unknown> {
  message: string;
  type: FeedbackFormType;

  constructor(payload: FeedbackRequestPayload) {
    super();

    this.message = payload.message;
    this.type = payload.type;
  }

  makeRequest(): AxiosRequestConfig {
    return {
      method: 'post',
      url: `${uri.feedback_form}/`,
      data: {
        message: this.message,
        type: this.type
      }
    };
  }
}
