




























import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({ name: 'ByValueNumberInputs' })
export default class ByValueNumberInputs extends Vue {
  @Prop({ type: Array, required: true }) readonly value!: number[];
  @Prop({ type: Boolean, default: false }) readonly hasRemoveButton!: boolean;

  get isDisabled() {
    return this.value.length <= 2;
  }
}
