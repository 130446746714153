import './assets/sass/main.scss';

import Global from '@/components/global';
import GlobalAlt from '@/components/global-alt';
import Flow from '@movici-flow-common';
import Oruga from '@oruga-ui/oruga';
import orugaConfig from '@movici-flow-common/orugaConfig';
import { bulmaConfig } from '@oruga-ui/theme-bulma';
import merge from 'lodash/merge';
import Vue from 'vue';
import App from './App.vue';
import Filters from './filters';
import i18n from './i18n';
import router from './router';
import store from './store';

Vue.config.productionTip = false;
Vue.config.performance = true;

Vue.use(
  Oruga,
  merge(
    {
      iconPack: 'fas'
    },
    bulmaConfig,
    orugaConfig
  )
);

Vue.use(Filters);
Vue.use(Global);
Vue.use(GlobalAlt);
Vue.use(Flow, { homeRoute: { name: 'Dashboard' } });

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
