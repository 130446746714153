

















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({ name: 'ModalContent' })
export default class ModalContent extends Vue {
  @Prop({ type: String }) readonly title?: string;
  @Prop({ type: Boolean, default: false }) readonly hasCancelX!: boolean;
  get hasFooter() {
    return !!this.$slots.footer;
  }
}
