














































import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  ColorByValueLegendItem,
  FlowVisualizerType,
  ColorStaticLegendItem,
  ColorClause,
  ColorLegendItem,
  LegendItem,
  IconLegendItem,
  IconClause,
  IconStaticLegendItem,
  IconByValueLegendItem
} from '@movici-flow-common/types';
import { ComposableVisualizerInfo } from '@movici-flow-common/visualizers/VisualizerInfo';
import ColorBucketLegend from '../legends/ColorBucketLegend.vue';
import IconLegend from '../legends/IconLegend.vue';
import ColorGradientLegend from '../legends/ColorGradientLegend.vue';
import WidgetContainer from '../WidgetContainer.vue';

@Component({
  name: 'FlowLegend',
  components: { IconLegend, ColorBucketLegend, ColorGradientLegend, WidgetContainer }
})
export default class FlowLegend extends Vue {
  @Prop({ type: Array, default: () => [] }) readonly value!: ComposableVisualizerInfo[];

  isBuckets(colorLegendItem: ColorLegendItem) {
    return colorLegendItem.colorType === 'buckets';
  }

  isGradient(colorLegendItem: ColorLegendItem) {
    return colorLegendItem.colorType === 'gradient';
  }

  get legendList(): LegendItem[] {
    const rv: LegendItem[] = [];
    for (const { settings, name, visible } of this.value) {
      let color: ColorLegendItem | null = null,
        icon: { shape?: IconLegendItem; icon?: IconLegendItem } | null = null;

      if (visible) {
        if (settings?.color?.legend) {
          color = this.createColorLegendItem(settings.color, settings.type);
        }

        if (settings?.icon?.legend || settings?.shape?.legend) {
          icon = this.createIconLegendItem(settings?.shape ?? null, settings?.icon ?? null);
        }

        if (color || icon) {
          const legendItem = new LegendItem({
            title: name,
            color,
            icon
          });

          rv.push(legendItem);
        }
      }
    }
    return rv;
  }

  private createIconLegendItem(shapeClause: IconClause | null, iconClause: IconClause | null) {
    const shapeLegend = shapeClause?.legend,
      iconLegend = iconClause?.legend;

    if (!shapeLegend && !iconLegend) return null;

    const iconsLegendItems: { shape?: IconLegendItem; icon?: IconLegendItem } = {};

    if (shapeClause?.static) {
      iconsLegendItems.shape = new IconStaticLegendItem(shapeClause.static, shapeLegend);
    } else if (shapeClause?.byValue) {
      iconsLegendItems.shape = new IconByValueLegendItem(shapeClause.byValue, shapeLegend);
    }

    if (iconClause?.static) {
      iconsLegendItems.icon = new IconStaticLegendItem(iconClause.static, iconLegend);
    } else if (iconClause?.byValue) {
      iconsLegendItems.icon = new IconByValueLegendItem(iconClause.byValue, iconLegend);
    }

    return iconsLegendItems;
  }

  private createColorLegendItem(
    clause: ColorClause,
    type: FlowVisualizerType
  ): ColorLegendItem | null {
    const legend = clause.legend,
      baseConfig = {
        visualizerType: type,
        colorLegends: []
      };

    if (!legend) return null;

    if (clause.byValue) {
      return new ColorByValueLegendItem(
        {
          ...baseConfig,
          colorType: clause.byValue.type
        },
        clause.byValue,
        legend
      );
    }
    if (clause.static) {
      return new ColorStaticLegendItem(
        {
          ...baseConfig,
          colorType: 'buckets'
        },
        clause.static,
        legend
      );
    }
    throw new Error('Unknown clause type');
  }
}
