import ProjectStore from '@/store/modules/ProjectStore';
import { Client } from '@movici-flow-common/api';
import { ProjectService } from '@movici-flow-common/types/backend';
import { GetProjects } from '../requests';

export default class RemoteProjectService implements ProjectService {
  client: Client;
  store?: ProjectStore;

  constructor(client: Client, store?: ProjectStore) {
    this.client = client;
    this.store = store;
  }

  async list() {
    return (
      (await this.store?.getAllProjects()) ?? (await this.client.request(new GetProjects())) ?? []
    );
  }
}
