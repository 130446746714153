import { Client } from '@movici-flow-common/api';
import { UUID, View } from '@movici-flow-common/types';
import ViewStore from '@/store/modules/ViewStore';
import { ViewService } from '@movici-flow-common/types/backend';
import { AddView, GetViews, GetView, UpdateView, DeleteView } from '../requests';

export default class RemoteViewService implements ViewService {
  client: Client;
  store?: ViewStore;

  constructor(client: Client, store?: ViewStore) {
    this.client = client;
    this.store = store;
  }

  async create(scenarioUUID: UUID, view: View) {
    return (
      (await this.store?.createView({ scenarioUUID, view })) ??
      (await this.client.request(new AddView(scenarioUUID, view))) ??
      null
    );
  }

  async list(scenarioUUID: UUID) {
    return (await this.client.request(new GetViews(scenarioUUID))) ?? [];
  }

  async get(viewUUID: UUID) {
    return (
      (await this.store?.getView(viewUUID)) ??
      (await this.client.request(new GetView(viewUUID))) ??
      null
    );
  }

  // for local we can setup save files for views
  async update(viewUUID: UUID, view: View) {
    return (
      (await this.store?.updateView({ viewUUID, view })) ??
      (await this.client.request(new UpdateView(viewUUID, view))) ??
      null
    );
  }

  async delete(viewUUID: UUID) {
    return (
      (await this.store?.deleteView(viewUUID)) ??
      (await this.client.request(new DeleteView(viewUUID))) ??
      null
    );
  }
}
