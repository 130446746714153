





















import { FeedbackRequestPayload, WidgetOpts } from '@/types';
import { Component, Vue } from 'vue-property-decorator';
import MediaWidget from './widgets/MediaWidget.vue';
import FeedbackForm from './widgets/content/FeedbackForm.vue';
import { SendFeedbackForm } from '@/api/requests/dashboard';
import { apiStore } from '@/store/store-accessor';

@Component({
  name: 'Dashboard',
  components: {
    MediaWidget
  }
})
export default class Dashboard extends Vue {
  formFeedbackWidget: WidgetOpts<FeedbackRequestPayload> = {
    title: this.$t('feedbackFormWidget.title') as string,
    icon: 'comment-alt-lines',
    iconPack: 'far',
    buttonIcon: 'pencil-alt',
    buttonText: this.$t('feedbackFormWidget.button') as string,
    component: FeedbackForm,
    content: this.$t('feedbackFormWidget.content') as string,
    componentProps: { active: false },
    action: () => {
      if (this.formFeedbackWidget.componentProps) {
        this.formFeedbackWidget.componentProps.active = true;
      }
    },
    listeners: {
      close: () => {
        if (this.formFeedbackWidget.componentProps) {
          this.formFeedbackWidget.componentProps.active = false;
        }
      },
      input: (payload: FeedbackRequestPayload) => {
        apiStore.client.request(new SendFeedbackForm(payload)).then(() => {
          this.$flow.snackbar.successMessage(this.$t('feedbackFormWidget.success') as string);
        });
      }
    },
    style: {
      contentClass: 'has-text-centered',
      footerClass: 'has-text-centered'
    }
  };

  mounted() {}
}
