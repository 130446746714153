


























































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { UUID, Dataset } from '@movici-flow-common/types';
import { datasetSchemaStore, datasetStore } from '@/store/store-accessor';
import { getVariantFromStatus, sortByKeys } from '@movici-flow-common/utils';
import DatasetViewer from '@movici-flow-common/components/widgets/DatasetViewer.vue';
import DialogModal from '@/components/global-alt/DialogModal.vue';

@Component({
  name: 'DatasetDetail',
  components: {
    DatasetViewer
  }
})
export default class DatasetDetail extends Vue {
  @Prop({ type: String }) readonly uuid?: UUID;
  dataset: Dataset | null = null;
  file: File | null = null;
  saving = false;

  getVariantFromStatus = getVariantFromStatus;

  get uploadProgress() {
    return datasetStore.progress;
  }

  get datasetTypes() {
    return datasetSchemaStore.datasetTypes.sort(sortByKeys(['+name']));
  }

  get addMode() {
    return !this.uuid;
  }

  get editMode() {
    return !!this.uuid;
  }

  get hasFile() {
    return !!this.file;
  }

  get hasData() {
    let status = this.dataset?.status;
    return status === 'Done';
  }

  get canUpload() {
    return this.addMode || this.dataset?.status === 'Empty';
  }

  get uploading() {
    return this.saving && this.hasFile;
  }

  get title() {
    return this.addMode ? this.$t('dataset.addNew') : this.$t('dataset.edit');
  }

  goBack() {
    this.$router.push({ name: 'Datasets' });
  }

  async onSave() {
    this.saving = true;
    if (this.addMode) {
      await this.addNewDataset();
    } else {
      await this.updateDataset();
    }
    this.resetSavingStatus();
  }

  async onDownload() {
    if (this.dataset) {
      await datasetStore.downloadInitData(this.dataset);
    }
  }

  onDeleteInitData() {
    this.confirmDelete();
  }

  confirmDelete() {
    this.$oruga.modal.open({
      parent: this,
      component: DialogModal,
      props: {
        message:
          'Are you sure you want to <b>delete</b> this init data? This action cannot be undone.',
        title: 'Delete Init Data',
        confirmText: 'Delete Init Data',
        canCancel: true,
        variant: 'danger',
        hasIcon: true,
        onConfirm: () => this.deleteInitData()
      },
      trapFocus: true
    });
  }

  deleteInitData() {
    if (this.uuid) {
      datasetStore.deleteInitData(this.uuid).then(() => {
        this.getThisDatasetInEditMode();
      });
    }
  }

  goToEdit(uuid: string) {
    this.saving = false;
    this.$router.push({
      name: 'DatasetEdit',
      params: { uuid: uuid }
    });
  }

  async addNewDataset() {
    if (this.dataset) {
      const response = await datasetStore.addDataset(this.dataset);
      const uuid = response?.dataset_uuid;
      if (uuid) {
        const success = await this.maybeUploadInitData(uuid);
        if (success) {
          this.$flow.snackbar.successMessage('Successfully created Dataset');
          this.goToEdit(response.dataset_uuid);
        }
      }
    }
  }

  async updateDataset() {
    if (this.dataset && this.uuid) {
      let success = (await datasetStore.updateDataset(this.dataset)) != null;

      if (success) {
        success = await this.maybeUploadInitData(this.uuid);
      }

      if (success) {
        this.$flow.snackbar.successMessage('Successfully updated Dataset');
        this.getThisDatasetInEditMode();
      }
    }
  }
  async maybeUploadInitData(uuid: UUID): Promise<boolean> {
    let success = true;
    if (this.file) {
      success =
        (await datasetStore.uploadInitData({
          uuid: uuid,
          file: this.file
        })) !== null;
    }
    this.resetSavingStatus();

    return success;
  }

  async getThisDataset() {
    if (this.uuid) this.dataset = await datasetStore.getDataset(this.uuid);
  }

  @Watch('uuid', { immediate: true })
  getThisDatasetInEditMode() {
    if (this.editMode) {
      this.getThisDataset();
    }
  }

  async getDatasetTypes() {
    await datasetSchemaStore.getDatasetTypes();
  }

  resetSavingStatus() {
    this.saving = false;
    this.file = null;
  }

  async mounted() {
    await this.getDatasetTypes();
    if (this.addMode) {
      this.dataset = {
        uuid: '',
        name: '',
        display_name: null,
        type: '',
        status: '',
        has_data: false
      };
    }
  }
}
