import { Client } from '@movici-flow-common/api';

import { UUID } from '@movici-flow-common/types';
import { SummaryService } from '@movici-flow-common/types/backend';
import { GetDatasetSummary, GetScenarioSummary } from '../requests';

export default class RemoteSummaryService implements SummaryService {
  client: Client;

  constructor(client: Client) {
    this.client = client;
  }

  getScenario(scenario_uuid: UUID, dataset_uuid: UUID) {
    return this.client.request(new GetScenarioSummary(scenario_uuid, dataset_uuid));
  }

  getDataset(dataset_uuid: UUID) {
    return this.client.request(new GetDatasetSummary(dataset_uuid));
  }
}
