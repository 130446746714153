










































import { Component, Prop, Vue } from 'vue-property-decorator';
import ColorPalette from './colorPalettes';

@Component({ name: 'ColorPaletteDropdown' })
export default class ColorPaletteDropdown extends Vue {
  @Prop({ type: Number, default: null }) value!: number | null;
  @Prop({ type: Number, default: 2 }) nSteps!: number;
  @Prop({ type: Array, default: () => [] }) colorPalettes!: ColorPalette[];
  @Prop({ type: Function, default: () => true }) filter!: (palette: ColorPalette) => boolean;

  get currentName() {
    return this.value != null ? this.colorPalettes[this.value].name : 'Select a palette';
  }
  get currentColors() {
    return this.value != null
      ? this.colorPalettes[this.value].getHexColorsForSize(this.nSteps)
      : [];
  }
  get validPalettes(): [number, ColorPalette][] {
    return Array.from(this.colorPalettes.entries()).filter(entry => {
      return this.filter(entry[1]);
    });
  }
}
