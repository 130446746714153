import { Client } from '@movici-flow-common/api';
import { UUID } from '@movici-flow-common/types';
import ScenarioStore from '@/store/modules/ScenarioStore';
import { ScenarioService } from '@movici-flow-common/types/backend';
import { GetScenario, GetScenarios } from '../requests';

export default class RemoteScenarioService implements ScenarioService {
  client: Client;
  store?: ScenarioStore;

  constructor(client: Client, store?: ScenarioStore) {
    this.client = client;
    this.store = store;
  }

  async get(scenario_uuid: UUID) {
    return (
      (await this.store?.getScenario(scenario_uuid)) ??
      (await this.client?.request(new GetScenario(scenario_uuid))) ??
      null
    );
  }

  async list(project_uuid?: UUID) {
    if (!project_uuid) return [];
    return (await this.client?.request(new GetScenarios(project_uuid))) ?? [];
  }
}
