



















import { Component, Prop, Vue } from 'vue-property-decorator';
import { PropertySummary, PopupItem } from '@movici-flow-common/types';
import { sortByKeys } from '@movici-flow-common/utils';

@Component({ name: 'AttributeSuggestions' })
export default class AttributeSuggestions extends Vue {
  @Prop({ type: Array, default: () => [] }) readonly value!: PropertySummary[];
  @Prop({ type: Array, default: () => [] }) readonly items!: PopupItem[];
  @Prop({ type: Array, default: () => [] }) readonly entityProps!: PropertySummary[];
  @Prop({ type: Array, default: () => ['id', 'display_name', 'name', 'reference'] })
  defaultSuggestionNames!: string[];

  get defaultSuggestionNamesSet() {
    return new Set(this.defaultSuggestionNames);
  }

  get itemSet() {
    return new Set(this.items.map(i => i.attribute.name));
  }

  get defaultSuggestions() {
    return this.entityProps.filter(attr => {
      return this.defaultSuggestionNamesSet.has(attr.name);
    });
  }

  get filteredSuggestions() {
    return [
      ...this.defaultSuggestions,
      ...this.value
        .filter(attr => {
          return !this.itemSet.has(attr.name);
        })
        .sort(sortByKeys(['+name']))
    ];
  }
}
