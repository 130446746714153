
























































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import ValidationProvider from '@movici-flow-common/mixins/ValidationProvider';
import FormValidator from '@movici-flow-common/utils/FormValidator';
import { FeedbackFormType } from '@/types';

@Component({ name: 'FeedbackForm' })
export default class FeedbackForm extends Mixins(ValidationProvider) {
  @Prop({ type: Object, default: () => new Object() })
  readonly properties!: Record<string, unknown>;
  types: FeedbackFormType[] = Object.values(FeedbackFormType);
  type: FeedbackFormType | null = null;
  message = '';
  active = false;

  submit() {
    this.validator?.validate();

    if (this.hasErrors) return null;
    const payload = {
      message: this.message,
      type: this.type
    };

    this.$emit('input', payload);
    this.$emit('close');
  }

  setupValidator() {
    this.validator = new FormValidator({
      validators: {
        type: () => {
          if (!this.type) {
            return this.$t('feedbackFormWidget.validation.type') as string;
          }
        },
        message: () => {
          if (!this.message) {
            return this.$t('feedbackFormWidget.validation.message') as string;
          }

          if (this.message.length < 10) {
            return this.$t('feedbackFormWidget.validation.length') as string;
          }
        }
      },
      onValidate: e => {
        this.errors = e;
      }
    });
  }

  @Watch('properties', { deep: true })
  reset() {
    this.type = null;
    this.message = '';
    this.setupValidator();
  }

  mounted() {
    this.reset();
  }
}
