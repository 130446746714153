import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { failMessage } from '@movici-flow-common/utils/snackbar';
import { Client } from '@movici-flow-common/api';
import { defaultClient } from '@movici-flow-common/api/client';
import RemoteBackend from '@/api/RemoteBackend';
import { bindAPI } from '@movici-flow-common/store/store-accessor';
import {
  currentUserStore,
  datasetStore,
  projectStore,
  scenarioStore
} from '@/store/store-accessor';
import { LoginRequest, LogoutRequest } from '@/api/requests';

@Module({
  name: 'api',
  namespaced: true
})
class ApiStore extends VuexModule {
  client_: Client | null = null;
  baseURL = '/';
  apiToken: string | null = null;
  isLoggedIn = false;
  logoutMessage = '';
  logoutMessageVariant = 'warning';

  get client() {
    return this.client_ || defaultClient({ baseURL: this.baseURL, apiToken: this.apiToken });
  }

  @Mutation
  SET_CLIENT(client: Client) {
    this.client_ = client;
  }

  @Mutation
  SET_LOGIN_STATUS(payload: { isLoggedIn: boolean; apiToken?: string }) {
    this.isLoggedIn = payload.isLoggedIn;
    if (payload.apiToken) {
      this.apiToken = payload.apiToken;
      localStorage.apiToken = payload.apiToken;
    }
  }

  @Mutation
  CLEAR_LOGIN_STATUS() {
    this.isLoggedIn = false;
    this.apiToken = null;
    localStorage.removeItem('apiToken');
  }

  @Mutation
  SET_LOGOUT_MESSAGE(payload: { variant: string; message: string }) {
    this.logoutMessage = payload.message;
    this.logoutMessageVariant = payload.variant || 'warning';
  }

  @Action({ rawError: true })
  initToken(apiToken: string) {
    this.SET_LOGIN_STATUS({
      apiToken,
      isLoggedIn: true
    });
  }

  @Action({ rawError: true })
  async doLogin(payload: { username: string; password: string }) {
    const resp = await this.client
      .request(new LoginRequest(payload.username, payload.password))
      .catch((e: Error) => {
        this.SET_LOGOUT_MESSAGE({
          message: 'Unable to log in',
          variant: 'danger'
        });
        console.error(e);
      });

    if (resp) {
      this.configureClient({ apiToken: resp.session });
      this.SET_LOGIN_STATUS({
        isLoggedIn: true,
        apiToken: resp.session
      });
    }
  }

  @Action({ rawError: true })
  async doLogout() {
    await this.client.request(new LogoutRequest()).catch((e: Error) => {
      console.error(e);
    });
    this.configureClient({ apiToken: null });

    this.CLEAR_LOGIN_STATUS();
    this.SET_LOGOUT_MESSAGE({
      message: 'You have been successfully logged out.',
      variant: 'success'
    });
  }

  @Action({ rawError: true })
  forceLogout() {
    this.configureClient({ apiToken: null });
    this.SET_LOGOUT_MESSAGE({
      message: 'You have been automatically logged out for security reasons.',
      variant: 'warning'
    });
    this.CLEAR_LOGIN_STATUS();
  }

  @Action({ rawError: true })
  configureClient(settings: { baseURL?: string; apiToken?: string | null }) {
    const client = this.client_;
    if (client === null) {
      this.initializeClient(settings);
    } else {
      client.baseURL = settings.baseURL ?? client.baseURL;
      client.apiToken = settings.apiToken !== undefined ? settings.apiToken : client.apiToken;
    }
  }

  @Action({ rawError: true })
  initializeClient(settings: { baseURL?: string; apiToken?: string | null }) {
    const client = defaultClient({
      baseURL: settings.baseURL ?? this.baseURL,
      apiToken: settings.apiToken ?? this.apiToken,
      callbacks: {
        401: () => {
          this.forceLogout();
        },
        http(e) {
          failMessage(e.message);
        }
      }
    });
    this.SET_CLIENT(client);
    bindAPI(
      new RemoteBackend({
        client,
        datasetStore,
        projectStore,
        scenarioStore,
        currentUserStore
      })
    );
    return client;
  }
}

export default ApiStore;
