













import { Vue, Component, Watch } from 'vue-property-decorator';
import Login from './account/Login.vue';
import { apiStore, currentUserStore, generalStore, projectStore } from '@/store/store-accessor';

@Component({
  name: 'Main',
  components: {
    Login
  }
})
export default class Main extends Vue {
  get initialized() {
    return generalStore.initialized;
  }

  get loading() {
    return generalStore.loading;
  }

  get userLoggedIn() {
    return apiStore.isLoggedIn;
  }

  get initializedAndLoggedIn() {
    return this.initialized && this.userLoggedIn;
  }

  @Watch('initializedAndLoggedIn', { immediate: true })
  async onLoggedIn() {
    if (this.initializedAndLoggedIn) {
      await currentUserStore.getCurrentUser().catch(err => console.error(err));
      await projectStore.initProjects().catch(err => console.error(err));
    }

    generalStore.setLoading({ value: false });
  }
}
