import { ModelType } from '@/types';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import {
  AddModelType,
  DeleteModelType,
  GetModelType,
  GetModelTypes,
  UpdateModelType
} from '@/api/requests';
import { apiStore } from '../store-accessor';
import { UUID } from '@movici-flow-common/types';

@Module({
  name: 'modelType',
  namespaced: true
})
export default class ModelTypeStore extends VuexModule {
  modelTypes: ModelType[] = [];
  currentModelType: ModelType | null = null;
  currentModelTypeUUID: string | null = null;

  get client() {
    return apiStore.client;
  }

  @Mutation
  SET_MODEL_TYPES(modelTypes: ModelType[]) {
    this.modelTypes = modelTypes;
  }

  @Mutation
  SET_CURRENT_MODEL_TYPE(modelType: ModelType | null) {
    this.currentModelType = modelType;
  }

  @Mutation
  SET_CURRENT_MODEL_TYPE_UUID(uuid: string | null) {
    this.currentModelTypeUUID = uuid;
  }

  @Action({ rawError: true })
  async getModelTypes() {
    const types = (await this.client.request(new GetModelTypes())) ?? [];
    this.SET_MODEL_TYPES(types);
    return types;
  }

  @Action({ rawError: true })
  async getModelType(uuid: string) {
    this.SET_CURRENT_MODEL_TYPE(null);
    this.SET_CURRENT_MODEL_TYPE_UUID(null);

    const modelType = await this.client.request(new GetModelType(uuid));

    this.SET_CURRENT_MODEL_TYPE(modelType);
    this.SET_CURRENT_MODEL_TYPE_UUID(uuid);
  }

  @Action({ rawError: true })
  async updateModelType() {
    if (this.currentModelTypeUUID && this.currentModelType) {
      return await this.client.request(
        new UpdateModelType(this.currentModelTypeUUID, this.currentModelType)
      );
    }
  }
  @Action({ rawError: true })
  async addModelType() {
    if (this.currentModelType) {
      return await this.client.request(new AddModelType(this.currentModelType));
    }
  }
  @Action({ rawError: true })
  async deleteModelType(uuid: UUID) {
    return await this.client.request(new DeleteModelType(uuid));
  }

  @Action({ rawError: true })
  setCurrentModelType(modelType: ModelType | null) {
    this.SET_CURRENT_MODEL_TYPE(modelType);
  }
}
