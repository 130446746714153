var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FlowContainer',{staticClass:"flow-visualization",scopedSlots:_vm._u([{key:"leftPanel",fn:function(){return [(_vm.hasProjectsCapabilities)?_c('ProjectInfoBox',{staticClass:"mb-2"}):_vm._e(),_c('ScenarioInfoBox',{staticClass:"mb-2"}),_c('ViewInfoBox',{staticClass:"mb-3",attrs:{"name":_vm.viewName},on:{"update:name":function($event){_vm.viewName=$event},"load-view":_vm.loadView,"delete-view":_vm.confirmDeleteView,"save-view":_vm.confirmSaveView,"save-view-as-new":_vm.saveViewAsNew},scopedSlots:_vm._u([{key:"quickSave",fn:function(){return [_c('span',{staticClass:"is-relative quick-save-container"},[_c('o-button',{staticClass:"py-0 is-transparent is-borderless quick-save has-text-primary has-hover-bg",attrs:{"icon-pack":"fak","icon-left":"fa-mov-save","size":"small","title":_vm.isDirtyLabel},on:{"click":function($event){return _vm.saveView(_vm.view.uuid)}}}),(_vm.isCurrentViewDirty)?_c('div',{staticClass:"notification-marker"}):_vm._e()],1)]},proxy:true}])}),_c('o-tabs',{ref:"tabs",staticClass:"flow-tabs uppercase field is-flex-grow-0 is-flex-shrink-2",style:(_vm.tabHeight),attrs:{"value":_vm.visualizerTabOpen},on:{"input":function($event){return _vm.changeVisualizer({
          tab: $event,
          index: -2
        })}}},[_c('o-tab-item',{attrs:{"label":_vm.$t('flow.visualization.tabs.visualizers'),"value":0}},[_c('FlowLayerPicker',{ref:"layerPicker",attrs:{"open":_vm.visualizerOpen,"scenario":_vm.currentScenario,"timestamp":_vm.timestamp},on:{"update:open":function($event){_vm.visualizerOpen=$event}},model:{value:(_vm.visualizers),callback:function ($$v) {_vm.visualizers=$$v},expression:"visualizers"}})],1),_c('o-tab-item',{attrs:{"label":_vm.$t('flow.visualization.tabs.charts'),"value":1}},[_c('FlowChartPicker',{attrs:{"open":_vm.chartConfigOpen},on:{"update:open":function($event){_vm.chartConfigOpen=$event}},model:{value:(_vm.charts),callback:function ($$v) {_vm.charts=$$v},expression:"charts"}})],1)],1)]},proxy:true},{key:"mainView",fn:function(){return [(_vm.viewState)?_c('MapVis',{ref:"mapVis",attrs:{"layer-infos":_vm.validVisualizers,"view-state":_vm.viewState,"timestamp":_vm.timestamp,"buildings":"","scale":""},on:{"update:viewState":function($event){_vm.viewState=$event},"update:view-state":function($event){_vm.viewState=$event},"update:timestamp":function($event){_vm.timestamp=$event}},scopedSlots:_vm._u([{key:"control-zero",fn:function(ref){
        var map = ref.map;
        var popup = ref.popup;
        var contextPickInfo = ref.contextPickInfo;
        var resetContextPickInfo = ref.resetContextPickInfo;
        var visualizers = ref.visualizers;
return [(_vm.getContextMenuActions(contextPickInfo).length)?_c('MapContextMenu',{attrs:{"value":contextPickInfo,"map":map,"view-state":_vm.viewState,"actions":_vm.getContextMenuActions(contextPickInfo)},on:{"chartAttributePicker":function($event){return _vm.openChart(contextPickInfo, visualizers)},"close":resetContextPickInfo}}):_vm._e(),(popup.mapPopups.length)?_vm._l((popup.mapPopups),function(p,i){return _c('MapEntityPopup',{key:i,attrs:{"value":p,"map":map,"timestamp":_vm.timestamp,"view-state":_vm.viewState},on:{"toggle":function($event){return popup.togglePosition(p)},"close":function($event){return popup.remove(p)},"click":function($event){return popup.moveToTop(p)}}})}):_vm._e()]}},{key:"control-left",fn:function(ref){
        var map = ref.map;
        var onViewstateChange = ref.onViewstateChange;
        var basemap = ref.basemap;
        var setBasemap = ref.setBasemap;
return [(_vm.hasGeocodeCapabilities)?_c('SearchBar',{attrs:{"map":map,"view-state":_vm.viewState},on:{"update:view-state":function($event){return onViewstateChange($event)}}}):_vm._e(),_c('NavigationControl',{attrs:{"value":_vm.viewState,"center-camera":_vm.centerCamera},on:{"input":function($event){return onViewstateChange($event)}}}),_c('BaseMapControl',{attrs:{"value":basemap},on:{"input":setBasemap}})]}},{key:"control-right",fn:function(ref){
        var popup = ref.popup;
return [(_vm.visualizers.length)?_c('FlowLegend',{attrs:{"value":_vm.visualizers}}):_vm._e(),(popup.rightSidePopups.length)?_vm._l((popup.rightSidePopups),function(p,i){return _c('RightSidePopup',{key:i,attrs:{"value":p,"timestamp":_vm.timestamp},on:{"toggle":function($event){return popup.togglePosition(p)},"close":function($event){return popup.remove(p)}}})}):_vm._e()]}},(_vm.timelineInfo)?{key:"control-bottom",fn:function(ref){
        var updateTimestamp = ref.updateTimestamp;
        var maxTimeAvailable = ref.maxTimeAvailable;
        var tapefileStores = ref.tapefileStores;
return [_c('div',{staticClass:"box box-width-100",class:{ 'pt-2': _vm.chartVisExpanded }},[_c('ChartVis',{attrs:{"currentChartId":_vm.chartVisOpen,"expanded":_vm.chartVisExpanded,"tapefileStores":tapefileStores,"timelineInfo":_vm.timelineInfo,"timestamp":_vm.timestamp,"customTimeFormat":_vm.customTimeFormat},on:{"update:currentChartId":function($event){_vm.chartVisOpen=$event},"update:current-chart-id":function($event){_vm.chartVisOpen=$event},"update:expanded":function($event){_vm.chartVisExpanded=$event},"openConfig":function($event){return _vm.changeVisualizer({ tab: 1, index: $event })}},model:{value:(_vm.charts),callback:function ($$v) {_vm.charts=$$v},expression:"charts"}}),_c('TimeSlider',{attrs:{"value":_vm.timestamp,"customTimeFormat":_vm.customTimeFormat,"type":_vm.timestamp < maxTimeAvailable ? 'is-primary' : 'is-dark',"timeline-info":_vm.timelineInfo},on:{"input":function($event){return updateTimestamp($event)}}})],1)]}}:null],null,true)}):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }