






























import { Component, Prop, Vue } from 'vue-property-decorator';
import { ColorLegendItem, FlowVisualizerType, RGBAColor } from '@movici-flow-common/types';
import { colorTripleToHex } from '@movici-flow-common/visualizers/maps/colorMaps';

@Component({ name: 'ColorBucketLegend' })
export default class ColorBucketLegend extends Vue {
  @Prop({ type: Object }) readonly value!: ColorLegendItem;
  @Prop({ type: Boolean, default: false }) readonly isSimple!: boolean;

  get reversedColors() {
    const colors = [...this.value.colorLegends];
    return colors.reverse();
  }

  get isStatic() {
    return this.reversedColors.length === 1;
  }

  bucketColorStyle(color: RGBAColor, alpha = false) {
    const hex = colorTripleToHex(color),
      hexAlpha = colorTripleToHex(color) + 'CC';

    return {
      background: !alpha ? hex : hexAlpha,
      'border-color': hex
    };
  }

  isPolygon(type: FlowVisualizerType) {
    return type === FlowVisualizerType.POLYGONS;
  }

  isLine(type: FlowVisualizerType) {
    return type === FlowVisualizerType.LINES;
  }

  isPoint(type: FlowVisualizerType) {
    return type === FlowVisualizerType.POINTS;
  }

  isIcon(type: FlowVisualizerType) {
    return type === FlowVisualizerType.ICONS;
  }

  isGrid(type: FlowVisualizerType) {
    return type === FlowVisualizerType.FLOODING_GRID || type === FlowVisualizerType.GRID;
  }
}
