














import { Project } from '@movici-flow-common/types';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { projectStore } from '@/store/store-accessor';

@Component({ name: 'ProjectPicker' })
export default class ProjectPicker extends Vue {
  @Prop({ type: Boolean, default: false }) readonly disabled!: boolean;
  @Prop({ type: String, default: 'default' }) readonly size!: string;
  optionLabel = (s: Project) => s?.display_name ?? '';

  get projects(): Project[] {
    return projectStore.projects;
  }

  get project(): Project | null {
    return projectStore.activeProject;
  }

  set project(newValue: Project | null) {
    if (newValue) {
      projectStore.setActiveProjectId(newValue.uuid);
    }
  }

  get errorMessage() {
    return this.project ? '' : 'Please select a project';
  }

  get variant() {
    return this.project ? '' : 'danger';
  }
}
