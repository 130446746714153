

















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({ name: 'BaseMapControl' })
export default class BaseMapControl extends Vue {
  @Prop({ type: Boolean, default: false }) readonly isRight!: boolean;
  @Prop({ type: String, default: 'mapbox://styles/mapbox/light-v10' })
  value!: string;
  value_ = '';
  toggle = false;
  basemapsDefs = [
    { type: 'image', name: 'light', value: 'mapbox://styles/mapbox/light-v10' },
    { type: 'image', name: 'dark', value: 'mapbox://styles/mapbox/dark-v10' },
    { type: 'image', name: 'streets', value: 'mapbox://styles/mapbox/streets-v11' },
    { type: 'image', name: 'satellite', value: 'mapbox://styles/mapbox/satellite-v9' },
    { type: 'solid', name: 'black', value: 'color://black' },
    { type: 'solid', name: 'white', value: 'color://white' }
  ];

  @Watch('value_')
  emitBaseMap(value: string) {
    this.$emit('input', value);
  }

  mounted() {
    this.value_ = this.value;
  }
}
