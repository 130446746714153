








import { Component, Prop, Vue } from 'vue-property-decorator';
import upperFirst from 'lodash/upperFirst';

@Component({ name: 'MovLanguagePicker' })
export default class MovLanguagePicker extends Vue {
  @Prop({ type: String, default: 'en' }) readonly value!: string;
  @Prop({ type: Boolean, default: false }) readonly hasLabel!: boolean;

  languages = ['en', 'nl'];

  get label() {
    if (this.hasLabel) {
      return upperFirst('' + this.$t('settings.selectLanguage'));
    }
    return '';
  }
}
