import { Action, Module, VuexModule } from 'vuex-module-decorators';
import {
  GetSimulationControlMessage,
  SendSimulationControlMessage,
  SendSimulationUpdateRequest
} from '@/api/requests';
import { apiStore } from '../store-accessor';

@Module({
  name: 'simulation',
  namespaced: true
})
class SimulationStore extends VuexModule {
  get client() {
    return apiStore.client;
  }

  @Action({ rawError: true })
  async getSpeed(scenario_uuid: string) {
    return await this.client.request(new GetSimulationControlMessage(scenario_uuid));
  }

  @Action({ rawError: true })
  async updateSpeed(payload: { uuid: string; speed: number }) {
    const uuid = payload.uuid;

    const speed = payload.speed;
    return await this.client.request(new SendSimulationControlMessage(uuid, { speed }));
  }

  @Action({ rawError: true })
  async forwardTo(payload: { uuid: string; forward_to: number }) {
    const uuid = payload.uuid,
      forward_to = payload.forward_to;

    return await this.client.request(new SendSimulationControlMessage(uuid, { forward_to }));
  }

  @Action({ rawError: true })
  async sendUpdate(payload: { scenario_uuid: string; model_name: string; update: unknown }) {
    const uuid = payload.scenario_uuid,
      model_name = payload.model_name,
      update = payload.update;

    return await this.client.request(new SendSimulationUpdateRequest(uuid, model_name, update));
  }
}

export default SimulationStore;
