



















import { Component, Prop, Vue } from 'vue-property-decorator';
import { ActionMenuItem } from '@movici-flow-common/types';

@Component({ name: 'MovActionMenuItem' })
export default class MovActionMenuItem extends Vue {
  @Prop({ type: Object, default: () => new Object() }) readonly value!: ActionMenuItem | null;

  itemClass(item: ActionMenuItem) {
    const rv = [item.variant];
    if (item.isDisabled) rv.push('is-disabled');
    return rv;
  }

  emit(event: string, value: unknown) {
    this.$emit('emitAndClose', { event, value });
  }
}
