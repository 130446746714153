



















































































import { Component, Vue } from 'vue-property-decorator';
import { apiStore, generalStore } from '@/store/store-accessor';

import Privacy from '../home/Privacy.vue';
import Terms from '../home/Terms.vue';
import flowVersion from '@movici-flow-common/version';
@Component({
  name: 'Login',
  components: {
    Privacy,
    Terms
  }
})
export default class Login extends Vue {
  username = '';
  password = '';
  isPrivacyModalActive = false;
  isTermsModalActive = false;

  get language() {
    return generalStore.language;
  }
  set language(lang: string) {
    generalStore.setLanguage(lang);
  }
  get logoutMessage() {
    return apiStore.logoutMessage;
  }

  get logoutMessageVariant() {
    return apiStore.logoutMessageVariant;
  }

  get versionString() {
    const appVersion = process.env.VUE_APP_VERSION;

    return `v${appVersion} (flow v${flowVersion})`;
  }
  async onLogin() {
    await apiStore.doLogin({
      username: this.username,
      password: this.password
    });
  }
}
